import { Icon, Tooltip } from "@tm/controls"
import { Link, matchPath, useHistory, useLocation } from "react-router-dom"
import { encodeUniqueId, renderRoute, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { GlobalNavigationItem } from "@tm/components"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    icon: string
    url: string
    tooltip?: string
}

function Component(props: Props) {
    const { workTaskId } = useWorkTask() || {}
    const { search, pathname } = useLocation()
    const { translateText, isTranslationId } = useLocalization()
    const history = useHistory()
    let tooltip = ""
    if (props.tooltip) {
        tooltip = isTranslationId(props.tooltip) ? translateText(props.tooltip) : props.tooltip
    }

    const url = renderRoute(props.url, {
        ...props.match.params,
        workTaskId: encodeUniqueId(workTaskId || uniqueId()),
    })

    const isActive = matchPath(pathname + search, url)
    function onIconClick() {
        history.push(url)
    }

    return <GlobalNavigationItem active={!!isActive} componentType="icon" name={props.icon} onIconClick={onIconClick} tooltip={tooltip} />
}

export default withRouter(Component)
