import { AsyncAction } from "@tm/morpheus"
import { Article, HasRepairTimesRequest, HasRepairTimesResponse, RegisteredModels, CategoryType, Vehicle } from "@tm/models"
import { Container } from "@tm/nexus"
import { getRepairTimeProviders, getRepairTimeProvidersByNames } from "@tm/utils"
import { Models, Repositories } from "../../../data"
import { DetailsHeadState } from "./model"
import { ComponentActionType } from "."
import { BundleActions } from "../../../business"
import { createListRequests } from "./createListRequests"

function setDetailsUrl(categoryType: CategoryType, url: string): ComponentActionType {
    return { type: "SET_SECTION_URL", payload: { categoryType, sectionType: "details", url } }
}

function resetDetailsHead(): ComponentActionType {
    return { type: "DETAILSHEAD_RESET" }
}

type LoadDetailsRequest = Omit<Models.DetailsRequest, "vehicleType" | "modelId"> & {
    vehicle?: Vehicle
    foundBySearchTerm?: string
    initialQuantity?: number
    overwriteInitialQuantity: boolean
    disableAddToBasket: boolean
}

function loadDetailsHead(request: LoadDetailsRequest, hasArticleListV2?: boolean): AsyncAction<ComponentActionType, DetailsHeadState> {
    return (dispatch) => {
        dispatch(resetDetailsHead())

        const detailsRequest: Models.DetailsRequest = {
            productGroupId: request.productGroupId,
            supplierId: request.supplierId,
            supplierArticleNo: request.supplierArticleNo,
            vehicleLinkageId: request.vehicleLinkageId,
            vehicleType: request.vehicle?.vehicleType,
            modelId: request.vehicle?.tecDocTypeId,
            engineCode: request.vehicle?.engineCode,
        }

        dispatch({
            type: "DETAILSHEAD_LOADING",
            payload: {
                request: detailsRequest,
                foundBySearchTerm: request.foundBySearchTerm,
            },
        })

        Repositories.getArticleDetails(detailsRequest).then(
            (response) => {
                if (request.initialQuantity && response.article) {
                    response.article.quantity = request.initialQuantity
                    if (request.overwriteInitialQuantity) {
                        response.article.initialQuantity = request.initialQuantity
                    }
                }

                if (response.article && request.disableAddToBasket) {
                    response.article.showAddToBasket = false
                }

                dispatch({ type: "DETAILSHEAD_LOADED", payload: response })
                dispatch(showArticleLists(response, detailsRequest, request.foundBySearchTerm, hasArticleListV2))

                if (response.article && request.vehicle) {
                    dispatch(loadProductGroupRepairTimes(response.article, request.vehicle))
                }
            },
            () => dispatch(resetDetailsHead())
        )
    }
}

function loadProductGroupRepairTimes(article: Article, vehicle: Vehicle): AsyncAction<ComponentActionType, DetailsHeadState> {
    return (dispatch) => {
        const productGroupId: number | undefined = article.productGroup?.id

        if (!productGroupId) {
            return
        }

        const { repairTimeProviderIds } = getRepairTimeProviders()
        const providers = getRepairTimeProvidersByNames(repairTimeProviderIds)

        if (!providers.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            vehicleType: vehicle.vehicleType,
            modelId: vehicle.tecDocTypeId,
            repairTimeProvider: providers,
            productGroupIds: [productGroupId],
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response?.productGroups[productGroupId]) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response.productGroups[productGroupId] })
                }
            })
    }
}

function showArticleLists(
    response: Models.ArticleDetailsResponse,
    request: Models.DetailsRequest,
    foundBySearchTerm?: string,
    hasArticleListV2?: boolean
): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        const listRequests = createListRequests(request, response, foundBySearchTerm)
        const filteredRequests = hasArticleListV2
            ? listRequests
                  // Don't load lists when they should be loaded by PureList (Article List V2)
                  .filter(([targetId]) => hasArticleListV2 && targetId !== "details-partslist" && targetId !== "details-accessories")
            : listRequests
        filteredRequests.forEach(([targetComponentId, payload]) => dispatch({ type: "LOAD_ARTICLE_LIST", payload, targetComponentId }))
    }
}

function changeQuantity(article: Article, quantity: number): ComponentActionType {
    return { type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setDetailsUrl,
    resetDetailsHead,
    loadDetailsHead,
    changeQuantity,
}
