import {
    Article,
    Customer,
    GetArticlesRequest,
    getCurrentWorkTaskId,
    ListFilter,
    EReplaceButtonBundle,
    OE,
    RequestArticleListPayload,
    SearchFilters,
    WholesalerArticle,
} from "@tm/models"
import { Dictionary, encodeUniqueId, equals, parseQueryString, isBundleWithReplaceButtonMicro } from "@tm/utils"
import { SearchType } from "../../../business"
import { Models } from "../../../data"
import { AttributeFilter } from "../../../data/model"
import * as Helpers from "../../../helper"
import { advertisementCategoryDivider, getBundleParams } from "../../../utils"
import { openPartsSearch } from "../../../utils/modalHelper"
import { InitialFilters, ListState } from "./model"
import { URL_UNICRITERIA_SEPARATOR } from "../../manager/business"

export function getFiltersFromUrlQuery(urlQuery: string, state?: Dictionary<any>): InitialFilters {
    // This function should always return the same values. Otherwise the check at the end of componentWillReceiveProps creates an infinite loop.
    // So you can't use uniqueId(), etc. here.
    let {
        treeId,
        nodeId,
        searchFilter,
        query,
        productGroupIds,
        oeReferenceNos,
        fittingPosition,
        traderArticleNos,
        showNotFoundArticles,
        supplierIds,
        partToReplaceQuantity,
        equipmentCode,
        attributes,
        attributesCacheId,
        hideDuplicatesWithDifferentProductGroups,
        hideArticleListWithoutResults,
        oePositionsCacheId,
        isFastCalculator,
        isFastService,
        fastCalculatorAlternativesCacheId,
        advertisementCategory,
        oeManufacturerIds,
        replaceButtonMicro,
        isOeReplacement,
        oeInformationWithoutCar,
        hideOePrice,
        extendedAssortment,
    } = parseQueryString(urlQuery)

    // HOTFIX
    if (query) {
        query = query.toString().replace(/\+/g, " ")
    }

    const filters: InitialFilters = {}

    if (partToReplaceQuantity) {
        filters.partToReplaceQuantity = parseInt(partToReplaceQuantity.toString()) || undefined
    }

    if (treeId && nodeId) {
        treeId = parseInt(treeId.toString())
        nodeId = parseInt(nodeId.toString())

        if (!isNaN(treeId) && !isNaN(nodeId)) {
            filters.category = { treeId, nodeId }
        }
    }

    // Dont use our uri decooding because some values are decoded differently than we want them to be
    const params = new URLSearchParams(urlQuery)
    const criteria = params.get("criteria")
    if (criteria) {
        // Add uni creterias from URL, set from "uniBestseller", to the initial filters
        // we need a special decoding for spaces and "+" symbols, to all spaces will be set to "$_$" and replaced with a space " "
        filters.uniCriteriaQuery = criteria.split(URL_UNICRITERIA_SEPARATOR).map((v) => v.replace("$_$", " "))
    }

    if (query) {
        searchFilter = searchFilter ? parseInt(searchFilter.toString()) : NaN

        filters.query = {
            searchFilter: !isNaN(searchFilter) ? searchFilter : SearchFilters.All,
            text: query.toString(),
        }
    }

    if (productGroupIds) {
        const ids = productGroupIds
            .toString()
            .split(",")
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))

        if (ids.length) {
            filters.productGroupIds = ids
        }
    }

    if (supplierIds) {
        const ids = supplierIds
            .toString()
            .split(",")
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))

        if (ids.length) {
            filters.supplierIds = ids
        }
    }

    if (oeReferenceNos) {
        const nos = oeReferenceNos
            .toString()
            .split(",")
            .filter((x) => x)

        if (nos.length) {
            filters.oeReferenceNos = nos
        }
    }

    if (traderArticleNos) {
        const nos = traderArticleNos
            .toString()
            .split(",")
            .filter((x) => x)

        if (nos.length) {
            filters.traderArticleNos = {
                wholesalerParts: nos.map((x) => ({
                    wholesalerArticleNumber: x,
                    quantityValue: 1,
                })),
                showNotFoundArticles: Boolean(showNotFoundArticles),
                hideDuplicatesWithDifferentProductGroups: Boolean(hideDuplicatesWithDifferentProductGroups),
                hideArticleListWithoutResults: Boolean(hideArticleListWithoutResults),
            }
        }
    }

    if (advertisementCategory) {
        const adCategory = advertisementCategory.toString().split(advertisementCategoryDivider)
        if (adCategory.length === 2) {
            filters.offers = {
                advertisementCategory: {
                    id: adCategory[0],
                    description: adCategory[1],
                },
            }
        }
    }

    if (typeof attributesCacheId === "string") {
        let data = sessionStorage.getItem(attributesCacheId)
        if (data) {
            data = JSON.parse(data)
        }
        if (Array.isArray(data)) {
            filters.articleAttributes = data
        }
    } else if (typeof attributes === "string") {
        try {
            filters.articleAttributes = attributes.split("$").filter((x) => x)
        } catch { }
    }

    if (fittingPosition) {
        fittingPosition = parseInt(fittingPosition.toString())

        if (!isNaN(fittingPosition)) {
            filters.fittingPosition = fittingPosition
        }
    }

    if (equipmentCode) {
        filters.equipmentCode = equipmentCode.toString()
    }

    if (typeof oePositionsCacheId === "string") {
        try {
            let data = sessionStorage.getItem(oePositionsCacheId)
            if (data) {
                data = JSON.parse(data)
            }
            if (Array.isArray(data)) {
                filters.oePositions = data
            }

            // If no position is selected, select the first one
            if (filters.oePositions?.every((x) => !x.isSelected)) {
                filters.oePositions[0].isSelected = true
            }
        } catch { }
    }

    if (typeof replaceButtonMicro === "string" && isBundleWithReplaceButtonMicro(replaceButtonMicro)) {
        filters.replaceButtonBundle = replaceButtonMicro
    }

    if (isFastCalculator) {
        filters.fastCalculator = {}
        let FCData

        if (isFastService) {
            filters.fastCalculator.isFastService = true
            // Workaround to make sure the correct bundle is selected
            filters.replaceButtonBundle = EReplaceButtonBundle.FastService
        } else {
            filters.replaceButtonBundle = EReplaceButtonBundle.FastCalculatorNext
        }

        if (typeof fastCalculatorAlternativesCacheId === "string") {
            try {
                const data = sessionStorage.getItem(fastCalculatorAlternativesCacheId)
                if (data) {
                    FCData = JSON.parse(data)
                }
                if (FCData && typeof FCData === "object") {
                    filters.fastCalculator.alternatives = FCData.alternatives
                    filters.fastCalculator.oeNrs = FCData.oeNrs
                }
            } catch { }
        }
    }

    if (oeManufacturerIds) {
        const ids = oeManufacturerIds
            .toString()
            .split(",")
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))

        if (ids.length) {
            filters.oeManufacturerIds = ids
        }
    }

    if (isOeReplacement) {
        filters.isOeReplacement = true
    }

    if (oeInformationWithoutCar) {
        filters.oeInformationWithoutCar = true
    }

    if (hideOePrice) {
        filters.hideOePrice = true
    }

    if (extendedAssortment) {
        filters.extendedAssortment = true
    }

    return filters
}

export function getFiltersFromRequestPlayload(request: RequestArticleListPayload): InitialFilters {
    const filters: InitialFilters = {}

    if (request.node) {
        const { treeId, nodeId } = request.node
        filters.category = { treeId, nodeId }
    } else if (request.direct) {
        const { searchFilter, query, productGroupIds, oeManufacturerIds, oeInformationWithoutCar } = request.direct
        filters.query = { searchFilter, text: query }
        filters.productGroupIds = productGroupIds
        filters.oeManufacturerIds = oeManufacturerIds
        filters.oeInformationWithoutCar = oeInformationWithoutCar
    } else if (request.synonym) {
        filters.query = { text: request.synonym.query }
    } else if (request.productGroups) {
        const { ids } = request.productGroups
        filters.productGroupIds = ids
    } else if (request.oeReferences) {
        const { nos, productGroupIds } = request.oeReferences
        filters.oeReferenceNos = nos
        filters.productGroupIds = productGroupIds
    } else if (request.traderArticleNos) {
        filters.traderArticleNos = {
            wholesalerParts: request.traderArticleNos.wholesalerParts,
            showNotFoundArticles: request.traderArticleNos.showNotFoundArticles || false,
            hideDuplicatesWithDifferentProductGroups: request.traderArticleNos.hideDuplicatesWithDifferentProductGroups || false,
            hideArticleListWithoutResults: request.traderArticleNos.hideArticleListWithoutResults || false,
        }
        filters.articleForDbAlternatives = request.articleForDbAlternatives
        filters.foundBySearchTerm = request.foundBySearchTerm
        filters.foundByVehicleId = request.foundByVehicleId
    } else if (request.offers) {
        filters.offers = {
            advertisementCategory: request.offers.advertisementCategory,
        }
    } else if (request.partsList) {
        filters.partsList = request.partsList
        filters.foundBySearchTerm = request.foundBySearchTerm
        filters.foundByVehicleId = request.foundByVehicleId
    } else if (request.accessoryList) {
        filters.accessoryList = request.accessoryList
        filters.foundBySearchTerm = request.foundBySearchTerm
        filters.foundByVehicleId = request.foundByVehicleId
    } else if (request.articles) {
        filters.articles = request.articles
        filters.foundBySearchTerm = request.foundBySearchTerm
        filters.foundByVehicleId = request.foundByVehicleId
    } else if (request.articleIdentifier) {
        filters.articleIdentifier = request.articleIdentifier
        filters.articleForDbAlternatives = request.articleForDbAlternatives
        filters.retryArticleIdentifierSearchWithoutProductGroup = request.retryArticleIdentifierSearchWithoutProductGroup
    }

    filters.fittingPosition = request.fittingPosition

    return filters
}

export function createFiltersRequest(state: ListState, resetFilters?: boolean): Models.GetProductGroupAndSupplierFiltersRequest | undefined {
    const { searchType, usedFilters, initialFilters } = state

    const selectedProductGroupIds = !resetFilters ? getUsedProductGroupFilterIds(state) : undefined

    const selectedDataSupplierIds = !resetFilters ? getUsedDataSupplierFilterIds(state) : undefined

    const request: Models.GetProductGroupAndSupplierFiltersRequest = {
        productGroupIds: state.initialFilters?.productGroupIds,
        supplierIds: state.initialFilters?.supplierIds,
        selectedProductGroupIds: selectedProductGroupIds ?? state.initialFilters?.productGroupIds,
        selectedDataSupplierIds: selectedDataSupplierIds ?? state.initialFilters?.supplierIds,
        extendedAssortment: usedFilters.extendedAssortment,
        fittingSideFilter: usedFilters.fittingPosition,
        constructionYearFilter: usedFilters.constructionYear,
        oeManufacturerIds: initialFilters?.oeManufacturerIds,
        oeInformationWithoutCar: initialFilters?.oeInformationWithoutCar,
    }

    switch (searchType) {
        case SearchType.DIRECT:
        case SearchType.ALTERNATIVES: {
            if (!usedFilters.query) {
                return
            }

            request.searchFilter = usedFilters.query.searchFilter
            request.query = usedFilters.query.text

            if (initialFilters) {
                if (initialFilters.productGroupIds && !request.selectedProductGroupIds?.length) {
                    request.selectedProductGroupIds = initialFilters.productGroupIds
                }

                if (initialFilters.supplierIds && !request.selectedDataSupplierIds?.length) {
                    request.selectedDataSupplierIds = initialFilters.supplierIds
                }
            }

            break
        }
        case SearchType.SYNONYM: {
            if (!usedFilters.vehicle || !usedFilters.query) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId
            request.query = usedFilters.query.text

            break
        }
        case SearchType.NODE: {
            if (!usedFilters.vehicle || !usedFilters.category) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId
            request.treeId = usedFilters.category.treeId
            request.nodeId = usedFilters.category.nodeId

            break
        }
        case SearchType.PRODUCTGROUPS:
        case SearchType.OE: {
            if (!usedFilters.vehicle || (!usedFilters.productGroupIds.length && !request.productGroupIds?.length)) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId
            // request.productGroupIds = getUsedProductGroupFilterIds(state)

            break
        }
        case SearchType.OE_POSITIONS: {
            const selectedPosition = state.oeResult?.positions?.find((x) => x.isSelected)
            const selectedNumber = selectedPosition?.replacements?.find((x) => x.isSelected)

            if (!selectedPosition) {
                return
            }

            request.searchFilter = SearchFilters.OeReference
            request.query = (selectedNumber ?? selectedPosition).number

            break
        }
        default:
            return
    }

    return request
}

export function createUniFiltersRequest(state: ListState): Models.UniParts.FiltersRequest | Models.UniParts.FiltersByQueryRequest | undefined {
    const { searchType, usedFilters } = state

    const request: Models.UniParts.FiltersRequest = {
        extendedAssortment: usedFilters.extendedAssortment,
        selectedProductGroups: getUsedProductGroupFilters(state).map(mapFilterToAttributeFilter) as ListFilter[],
        selectedSuppliers: getUsedDataSupplierFilters(state).map(mapFilterToAttributeFilter) as ListFilter[],
        selectedCriteria: getUsedUniCriterionFilters(state) as ListFilter[],
    }

    switch (searchType) {
        case SearchType.UNISEARCH: {
            if (!usedFilters.query?.text) {
                return
            }

            return {
                ...request,
                query: usedFilters.query.text,
            }
        }
        case SearchType.UNINODE:
        case SearchType.UNIPRODUCTGROUPS: {
            const uniNodeInitialProductGroupFilters = state.uniNodeInitialProductGroupFilters.map(mapFilterToAttributeFilter)
            if (
                !equals(request.selectedProductGroups, uniNodeInitialProductGroupFilters) &&
                request.selectedProductGroups !== undefined &&
                request.selectedProductGroups?.length > 0
            ) {
                request.selectedProductGroups = uniNodeInitialProductGroupFilters.map((filter) => {
                    const isSelected = state.usedFilters.productGroupIds.some((productGroupId) => productGroupId === filter.id)

                    return {
                        ...filter,
                        isSelected,
                    } as ListFilter
                })
            } else {
                // Set all product groups as selected
                request.selectedProductGroups = uniNodeInitialProductGroupFilters.map((filter) => {
                    return {
                        ...filter,
                        isSelected: true,
                    } as ListFilter
                })
            }

            if (request.selectedCriteria?.length) {
                request.selectedCriteria = request.selectedCriteria.map((filter) => {
                    return {
                        ...filter,
                        isSelected: true,
                    } as ListFilter
                })
            }

            if (!request.selectedProductGroups?.length) {
                return
            }

            return request
        }
        default:
            break
    }
}

export function createArticlesRequest(state: ListState): GetArticlesRequest | undefined {
    const { searchType, usedFilters, result, initialFilters } = state

    const productGroupIds = getUsedProductGroupFilterIds(state)
    const supplierIds = getUsedDataSupplierFilterIds(state)
    const oeReferences = getUsedOeReferenceNos(state)

    const request: GetArticlesRequest = {
        productGroupIds,
        supplierIds,
        oeReferences,
        attributeFilter: usedFilters.articleAttributes.join("|") || undefined,
        extendedAssortment: usedFilters.extendedAssortment,
        fittingSideFilter: usedFilters.fittingPosition,
        treeId: usedFilters.category?.treeId,
        nodeId: usedFilters.category?.nodeId,
        pageSize: result.page.size,
        pageIndex: result.page.index,
        constructionYearFilter: usedFilters.constructionYear,
        oeManufacturerIds: initialFilters?.oeManufacturerIds,
        oeInformationWithoutCar: initialFilters?.oeInformationWithoutCar,
    }

    switch (searchType) {
        case SearchType.DIRECT:
        case SearchType.ALTERNATIVES: {
            if (!usedFilters.query) {
                return
            }

            request.searchFilter = usedFilters.query.searchFilter
            request.query = usedFilters.query.text

            request.productGroupIds = usedFilters.productGroupIds
            request.supplierIds = usedFilters.supplierIds
            break
        }
        case SearchType.SYNONYM:
        case SearchType.NODE:
        case SearchType.PRODUCTGROUPS: {
            if (!request.productGroupIds) {
                request.productGroupIds = getInitialProductGroupFilters(state).map((x) => x.id)
            }

            if (!request.supplierIds) {
                request.supplierIds = getInitialDataSupplierFilters(state).map((x) => x.id)
            }

            if (!usedFilters.vehicle || !request.productGroupIds.length) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId
            break
        }
        case SearchType.OE: {
            if (!request.productGroupIds) {
                request.productGroupIds = getInitialProductGroupFilters(state).map((x) => x.id)
            }

            if (!request.supplierIds) {
                request.supplierIds = getInitialDataSupplierFilters(state).map((x) => x.id)
            }

            if (!usedFilters.vehicle || !request.productGroupIds.length || !usedFilters.oeReferenceNos.length) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId

            break
        }
        case SearchType.OE_POSITIONS: {
            const selectedPosition = state.oeResult?.positions?.find((x) => x.isSelected)
            const selectedNumber = selectedPosition?.replacements?.find((x) => x.isSelected)

            if (!selectedPosition) {
                return
            }

            const query = (selectedNumber ?? selectedPosition).number

            if (getBundleParams().oePositionSearchAsDirectSearch) {
                request.searchFilter = SearchFilters.OeReference
                request.query = query

                request.productGroupIds = usedFilters.productGroupIds
                request.supplierIds = usedFilters.supplierIds

                break
            }

            request.oeReferences = [query]

            if (!request.productGroupIds) {
                request.productGroupIds = getInitialProductGroupFilters(state).map((x) => x.id)
            }

            if (!request.supplierIds) {
                request.supplierIds = getInitialDataSupplierFilters(state).map((x) => x.id)
            }

            if (!usedFilters.vehicle || !request.productGroupIds.length) {
                return
            }

            request.vehicleType = usedFilters.vehicle.vehicleType
            request.modelId = usedFilters.vehicle.tecDocTypeId

            break
        }
        default:
            return
    }

    return request
}

export function createUniArticlesRequest(state: ListState): Models.UniParts.ArticlesRequest | Models.UniParts.ArticlesByQueryRequest | undefined {
    const { searchType, filters, usedFilters, result } = state

    let selectedProductGroups = getUsedProductGroupFilters(state).map(mapFilterToAttributeFilter)
    if (!selectedProductGroups.length) {
        selectedProductGroups = state.initialFilters?.productGroupIds?.map((x) => mapFilterToAttributeFilter({ id: x })) ?? []
    }

    let selectedSuppliers = getUsedDataSupplierFilters(state).map(mapFilterToAttributeFilter)
    if (!selectedSuppliers.length) {
        selectedSuppliers = state.initialFilters?.supplierIds?.map((x) => mapFilterToAttributeFilter({ id: x })) ?? []
    }

    const selectedCriteria = getUsedUniCriterionFilters(state)

    switch (searchType) {
        case SearchType.UNISEARCH: {
            if (!usedFilters.query) {
                return
            }

            return {
                query: usedFilters.query.text,
                productGroupOrdering: filters.productGroups.map((x) => x.id),
                extendedAssortment: usedFilters.extendedAssortment,
                selectedProductGroups: selectedProductGroups as ListFilter[],
                selectedSuppliers: selectedSuppliers as ListFilter[],
                selectedCriteria: selectedCriteria as ListFilter[],
                pageSize: result.page.size,
                pageIndex: result.page.index,
            }
        }
        case SearchType.UNINODE:
        case SearchType.UNIPRODUCTGROUPS: {
            if (!selectedProductGroups.length) {
                selectedProductGroups = state.uniNodeInitialProductGroupFilters.map(mapFilterToAttributeFilter)
            }

            if (!selectedProductGroups.length) {
                return
            }

            return {
                extendedAssortment: usedFilters.extendedAssortment,
                selectedProductGroups: selectedProductGroups as ListFilter[],
                selectedSuppliers: selectedSuppliers as ListFilter[],
                selectedCriteria: selectedCriteria as ListFilter[],
                pageSize: result.page.size,
                pageIndex: result.page.index,
            }
        }
        default:
            break
    }
}

export function getUsedProductGroupFilters(state: ListState): Array<Models.ProductGroupFilter> {
    const { usedFilters, filters } = state
    return filters.productGroups.filter((x) => usedFilters.productGroupIds.includes(x.id)).sort(orderProductGroupFilters)
}

function getUsedDataSupplierFilters(state: ListState): Array<Models.DataSupplierFilter> {
    const { usedFilters, filters } = state
    return filters.suppliers.filter((x) => usedFilters.supplierIds.includes(x.id)).sort(orderSupplierFilters)
}

function getUsedUniCriterionFilters(state: ListState): Array<Models.UniParts.CriterionFilter> {
    return state.usedFilters.uniCriteria
}

export function getUsedProductGroupFilterIds(state: ListState): Array<number> | undefined {
    const { usedFilters, filters } = state
    if (!usedFilters.productGroupIds.length) {
        return
    }
    if (!filters.productGroups.length) {
        return usedFilters.productGroupIds
    }
    return filters.productGroups
        .filter((x) => usedFilters.productGroupIds.includes(x.id))
        .sort(orderProductGroupFilters)
        .map((x) => x.id)
}

function getUsedDataSupplierFilterIds(state: ListState): Array<number> | undefined {
    const { usedFilters, filters } = state
    if (!usedFilters.supplierIds.length) {
        return
    }
    if (!filters.suppliers.length) {
        return usedFilters.supplierIds
    }
    return filters.suppliers
        .filter((x) => usedFilters.supplierIds.includes(x.id))
        .sort(orderSupplierFilters)
        .map((x) => x.id)
}

function getUsedOeReferenceNos(state: ListState): Array<string> | undefined {
    const { usedFilters } = state
    if (!usedFilters.oeReferenceNos.length) {
        return
    }
    return usedFilters.oeReferenceNos
}

export function getInitialProductGroupFilters(state: ListState): Array<Models.ProductGroupFilter> {
    return Helpers.getInitialProductGroupFilters(
        state.filters.productGroups.map((x) => x).sort(orderProductGroupFilters),
        state.usedFilters.extendedAssortment
    )
}

function getInitialDataSupplierFilters(state: ListState): Array<Models.DataSupplierFilter> {
    return Helpers.getInitialDataSupplierFilters(
        state.filters.suppliers.map((x) => x).sort(orderSupplierFilters),
        state.usedFilters.extendedAssortment
    )
}

export function orderProductGroupFilters(a: Models.ProductGroupFilter, b: Models.ProductGroupFilter) {
    if (a.priority == undefined && b.priority == undefined) {
        if (a.hasTopPrioritySuppliers != b.hasTopPrioritySuppliers) {
            return a.hasTopPrioritySuppliers ? -1 : 1
        }

        if (a.name && b.name) {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
        }

        return 0
    }

    if (a.priority == undefined) {
        return 1
    }
    if (b.priority == undefined) {
        return -1
    }

    return a.priority - b.priority
}

function orderSupplierFilters(a: Models.DataSupplierFilter, b: Models.DataSupplierFilter) {
    if (a.name && b.name) {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
    }

    return 0
}

export function getArticleUniqueId(article: Article): string {
    return `${article.internalId}-${article.productGroup.id}-${article.vehicleLinkageId ?? ""}-${article.imageCoordinates ?? ""}`
}

export function getInternalIdFromArticleUniqueId(uniqueId: string) {
    const values = uniqueId.split("-")
    return {
        internalId: parseInt(values[0]),
        imageCoordinates: values[3],
    }
}

export function getArticlesFromTraderArticleDictionary(
    responses: Dictionary<Article[]> | undefined,
    request: Array<WholesalerArticle> | undefined,
    hideWholesalerPartsWithoutMatch?: boolean
) {
    const articles: Array<Article> = []
    const wholesalerParts: Array<WholesalerArticle> = []

    if (responses) {
        Object.keys(responses).forEach((traderArticleNo) => {
            if (responses[traderArticleNo].length) {
                articles.push(...responses[traderArticleNo])
            } else if (!hideWholesalerPartsWithoutMatch) {
                const match = request
                    ?.filter((part) => part.wholesalerArticleNumber)
                    .find((part) => part.wholesalerArticleNumber.toLowerCase() === traderArticleNo.toLowerCase())

                if (match) {
                    wholesalerParts.push(match)
                }
            }
        })
    }

    return { articles, wholesalerParts }
}

export function getArticlesFromSupplierArticleDictionary(responses: Dictionary<Article[]> | undefined) {
    const articles: Array<Article> = []

    responses &&
        Object.keys(responses).forEach((traderArticleNo) => {
            if (responses[traderArticleNo].length) {
                articles.push(...responses[traderArticleNo])
            }
        })

    return articles
}

/** @param articles gets mutated */
export function mergeDbAlternatives(
    articles: Array<Article>,
    additionalArticles: Array<Article> | undefined,
    articleForDbAlternatives: Article | undefined,
    hideDuplicatesWithDifferentProductGroups: boolean
) {
    additionalArticles?.forEach((article) => {
        if (
            !articles.some(
                (a) =>
                    a.supplierArticleNo === article.supplierArticleNo &&
                    a.supplier.id === article.supplier.id &&
                    (hideDuplicatesWithDifferentProductGroups ? true : a.productGroup.id === article.productGroup.id)
            ) &&
            (article.supplierArticleNo !== articleForDbAlternatives?.supplierArticleNo ||
                article.supplier.id !== articleForDbAlternatives?.supplier.id)
        ) {
            articles.push(article)
        }
    })
    return articles
}

export function createGetOeAttributesRequest(kType: number, oeNumber: string, productGroupIds: number[]): Models.GetOeAttributesRequest {
    return {
        kType,
        oeNumbers: [oeNumber],
        productGroupIds,
    }
}

function mapFilterToAttributeFilter(filter: Models.Filter): AttributeFilter {
    return {
        ...filter,
        name: filter.name ?? "",
        group: filter.group ?? "",
        query: filter.query ?? filter.id.toString(),
        isSelected: true,
    }
}

export function getTelesalesCustomerNo(customer: Customer | undefined): string | undefined {
    const refCustomerNo = customer?.refCustomerNo
    return refCustomerNo
}

export function doNewQuerySearchFromOePosition(selectedPosition: OE.OePosition, state: ListState, selectedReplacement?: OE.OeNumber) {
    const {
        usedFilters: { extendedAssortment },
        oeResult: { positions } = {},
        initialFilters: { oeManufacturerIds, oeInformationWithoutCar, hideOePrice, productGroupIds } = {},
    } = state

    const isSame = (a: OE.OeNumber, b: OE.OeNumber) => {
        return a.number === b.number && a.description === b.description
    }

    const newOePositions = positions?.map((position) => {
        position = {
            ...position,
            isSelected: isSame(position, selectedPosition),
        }

        if (position.replacements) {
            position.replacements = position.replacements.map((replacement) => ({
                ...replacement,
                isSelected: position.isSelected && selectedReplacement ? isSame(replacement, selectedReplacement) : false,
            }))
        }

        return position
    })

    const payload: RequestArticleListPayload = {
        forceReload: true,
        inModal: true,
        oePositions: newOePositions,
        extendedAssortment,
        hideOePrice,
        direct: {
            query: selectedReplacement ? selectedReplacement.number : selectedPosition.number,
            searchFilter: SearchFilters.OeReference,
            oeManufacturerIds,
            oeInformationWithoutCar,
            productGroupIds,
        },
    }

    const { partsRoutes } = getBundleParams()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    openPartsSearch(payload, { workTaskId: encodeUniqueId(getCurrentWorkTaskId()!) }, partsRoutes)
}
