import { useState, useEffect, useMemo } from "react"
import { Box, Image } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, uniqueId, encodeUniqueId, createQueryString, RouteComponentProps } from "@tm/utils"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { useReplaceUrlTags, useUser, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { getCurrentWorkTaskId } from "@tm/models"
import { State, IActions, Actions } from "./business"

type ComponentProps = RouteComponentProps<{ workTaskId?: string }> & {
    actions: IActions
    size: string
    fullSizeImage?: boolean
    className?: string
    imageUrl?: string
    externalSystemId?: number
    title?: string
    icon?: string
    route?: string
    extModule?: {
        parameter: string
        value: string
    }
    state: State
    target: {
        url?: string
        getFromExtModule?: boolean
        externalSystemId?: number
        urlGenerator?: boolean
        openNewWindow?: boolean
        openModal?: boolean
        openInWorkTask?: boolean
        icon?: string
    }
}

const translationRegex = /\{\{(.*?)\}\}/

function WidgetImage({
    actions,
    className,
    externalSystemId,
    extModule,
    fullSizeImage,
    history,
    icon,
    imageUrl,
    location,
    match,
    route,
    size,
    state,
    target,
    title,
}: ComponentProps) {
    const { languageId, translateText } = useLocalization()
    const [activeUrl, setActiveUrl] = useState<string>()
    const [redirectTriggered, setRedirectTriggered] = useState<boolean>()
    const replacedTags = useReplaceUrlTags(activeUrl, { location, kbaNumbers: state.kbaNumbers, languageId })
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { userContext } = useUser() ?? {}

    const cssClasses = size.replace(/(\d)x(\d)/, "bundle-misc image-widget widget widget--w$1 widget--h$2")
    const itemClassName = className ? ` ${className}` : ""

    const currentImageUrl = useMemo(() => {
        const externalModule = userContext.externalModules?.find(
            (mod) =>
                mod.id === externalSystemId || // If defined in the ui config (external catalog)
                mod.parameter?.find((par) => par.key.toLowerCase() === "catalogtype")?.value?.toLowerCase() === "promotion" // If only defined in mdm (external system) based on catalogtype
        )

        const url = externalModule?.parameter?.find((par) => par.key.toLowerCase() === "url_logo")?.value

        return url || imageUrl || "" // imageurl or the fallback from the ui config under the placeholder
    }, [externalSystemId, imageUrl, userContext.externalModules])

    useEffect(() => {
        if (replacedTags && redirectTriggered) {
            if (target?.openNewWindow) {
                window.open(replacedTags)
            } else if (target?.openModal) {
                Morpheus.showView("1", `/modal^/external/${createQueryString({ replacedTags })}`)
            } else if (target?.openInWorkTask) {
                const wkId = getCurrentWorkTaskId()
                if (wkId) {
                    history.push(`/${encodeUniqueId(wkId)}/ext${createQueryString({ url: replacedTags })}`)
                }
            } else {
                history.push(`/external01${createQueryString({ url: replacedTags })}`)
            }
            setRedirectTriggered(false)
        }
    }, [redirectTriggered, replacedTags])

    useEffect(() => {
        if (!target?.url && state.url) {
            if (state.generatedUrl) {
                setActiveUrl(state.generatedUrl)
            }
            if (target?.urlGenerator) {
                if (!state.generatedUrl && !state.urlLoading) {
                    actions.getGeneratedUrl()
                }
            } else {
                setActiveUrl(state.url)
            }
        }
    }, [target?.url, state.url, state.generatedUrl])

    function proceedWithRedirect(url: string) {
        if (target?.openNewWindow) {
            window.open(target.url)
        } else {
            history.push(
                renderRoute(url, {
                    ...match.params,
                    workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()),
                })
            )
        }
    }

    function getUrlFromExternalModule(extModule: { parameter: string; value: string }) {
        const { parameter, value } = extModule
        if (!parameter || !value) {
            return
        }
        const modules = window.userContext?.externalModules
        if (!modules) {
            return
        }
        const module = modules.find((x) => x.parameter?.some((y) => y.key === parameter && y.value === value))
        const url = module?.replacedUrl
        if (url) {
            return url
        }
    }

    function getActiveUrl() {
        if (state.url) {
            if (target?.urlGenerator) {
                if (state.generatedUrl) {
                    return state.generatedUrl
                }
            } else {
                return state.url
            }
        } else if (target?.getFromExtModule && extModule) {
            const url = getUrlFromExternalModule(extModule)
            return url
        }
    }

    function handleClick() {
        setRedirectTriggered(true)

        if (route) {
            history.push(renderRoute(route, { workTaskId: encodeUniqueId(uniqueId()), ...match.params }))
        }

        if (target?.url) {
            proceedWithRedirect(target.url)
        }

        if (target?.externalSystemId) {
            actions.getUrl(target.externalSystemId, telesalesCustomerNo, userContext)
            return
        }

        const url = getActiveUrl()
        url ?? setActiveUrl(url)
    }

    let replacedTitle

    if (title) {
        replacedTitle = title.replace(translationRegex, (s, num) => translateText(num))
    }

    if (fullSizeImage) {
        return (
            <Widget
                size={size as any}
                className={`${cssClasses}${itemClassName}`}
                title={replacedTitle}
                iconName={icon}
                cover={
                    <Box
                        onClick={handleClick}
                        sx={{
                            cursor: route ? "pointer" : undefined,
                            backgroundImage: `url("${currentImageUrl}")`,
                            width: "100%",
                            backgroundSize: "cover",
                        }}
                    />
                }
            />
        )
    }

    return (
        <Widget size={size as any} active className={`${cssClasses}${itemClassName}`} title={replacedTitle} iconName={icon}>
            <Image onClick={handleClick} src={currentImageUrl} sx={{ cursor: route ? "pointer" : undefined }} />
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(WidgetImage))
