import { useQuery } from "react-query"
import { getShowVehicleLookupConfigs } from "../repositories/vehicleLookupConfigs"

const KEY = "ShowVehicleLookupConfig"

export function useShowVehicleLookupConfig(enabled?: boolean) {
    const { data, isLoading } = useQuery([KEY], () => getShowVehicleLookupConfigs(), { enabled })

    return { data, isFetching: isLoading }
}
