import { ReactNode, useEffect, useMemo, useState } from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { ERepairTimeDivision, getStyleTheme, useCountryCodeToLicensePlate, useUser } from "@tm/context-distribution"
import { Button, Checkbox, Collapsible, LicensePlateField, Loader, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"

import { DMS } from "@tm/models"
import { getDateFormat } from "../../../../business"
import { getCustomerNameAndCompany, getVehicleName } from "../../helpers"

import { PartsTable } from "./parts-table"
import { RepairTimesTable } from "./repair-times-table"
import { calculateRawAwByDivision } from "../../../../utils/format"

export function getComponentStyles(): NestedCSSProperties {
    const { margin, font } = getStyleTheme()

    return {
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&__head": {},
            "&__loader": {
                margin: `-${margin.s} 0`, // prevent loader changing parent height
                marginLeft: margin.l,
            },
            "&__row": {
                $nest: {
                    "&--flex": {
                        display: "flex",
                        alignItems: "center",
                    },
                    "&--info": {
                        marginLeft: `calc(-${margin.xl} * 3)`,
                    },
                },
            },
            "&__price-wrapper": {
                marginLeft: "auto",
                textAlign: "right",
                $nest: {
                    "&--repair-time": {
                        marginBottom: margin.m,
                    },
                },
            },
            "&__price": {
                fontWeight: "bold",
                marginLeft: margin.xl,
            },
            "&__info": {
                display: "inline-block",
                verticalAlign: "top",
                maxWidth: "15em",
                marginLeft: `calc(${margin.xl} * 3)`,
                marginTop: margin.xl,
            },
            "&__label": {
                marginBottom: margin.m,
                textTransform: "uppercase",
            },
            "&__value": {
                $nest: {
                    "&--license-plate": {
                        $nest: {
                            ".input__inner": {
                                padding: `0 ${margin.m}`,
                            },
                        },
                    },
                },
            },
            "&__options": {
                alignSelf: "flex-end",
                display: "flex",
                alignItems: "center",
                marginTop: margin.xl,
                padding: "0.25em 0.5em",
                // border: `solid 1px ${colors.primary}`,
                // backgroundColor: colors.light,
                // borderRadius: box.radius,
                // boxShadow: "5px 5px 10px 5px rgba(0, 0, 0, 0.25)",
            },
            "&__button--basket": {
                width: "6em",
                marginLeft: "1em",
                $nest: {
                    ".icon": {
                        margin: "0 auto",
                    },
                },
            },
            "&__checkbox": {
                marginLeft: margin.m,
            },
            "&__collapsible": {
                $nest: {
                    ".collapsible__header": {
                        padding: `${margin.m} 0`,
                        $nest: {
                            ".icon": {
                                width: "1.5rem",
                                height: "1.5rem",
                            },
                            ".collapsible__caption .collapsible__label": {
                                fontWeight: "normal",
                                fontSize: font.textSize.l,
                            },
                        },
                    },
                },
            },
            "&__parts": {
                marginTop: margin.m,
            },
            "&__repair-times": {
                marginTop: margin.xl,
            },
        },
    }
}

type Props = {
    voucher: DMS.Voucher
    loading?: boolean
    className?: string
    onAddPartsOrRepairTimes?(voucher: DMS.Voucher, parts: Array<DMS.Part>, repairTimes: Array<DMS.RepairTime>): void
}

export function VoucherDetails(props: Props) {
    const { voucher } = props

    const className = style(getComponentStyles())
    const { translateText, date, currency, number, languageId } = useLocalization()
    const user = useUser()
    const [selectedParts, setSelectedParts] = useState<Array<DMS.Part>>([])
    const [selectedRepairTimes, setSelectedRepairTimes] = useState<Array<DMS.RepairTime>>([])

    const userRepairTimeDivision = user?.userSettings?.repairTimeOptions?.division || ERepairTimeDivision.Hours
    const firstAvailableDivision = useMemo((): number | undefined => {
        return voucher.repairTimes.find((timeObject) => !!timeObject.calculation.division)?.calculation.division
    }, [voucher.repairTimes])

    const { plateCode } = useCountryCodeToLicensePlate(voucher.vehicle?.countryCode)

    // Reset selection on voucher change
    useEffect(() => {
        setSelectedParts([])
        setSelectedRepairTimes([])
    }, [props.voucher])

    const selectAll = () => {
        setSelectedParts([...voucher.parts])
        setSelectedRepairTimes([...voucher.repairTimes])
    }

    const deselectAll = () => {
        setSelectedParts([])
        setSelectedRepairTimes([])
    }

    const addSelectedItemsToBasket = () => {
        props.onAddPartsOrRepairTimes?.(props.voucher, [...selectedParts], [...selectedRepairTimes])
    }

    const handleToggleSelectPart = (part: DMS.Part) => {
        setSelectedParts((prev) => {
            if (prev.includes(part)) {
                return prev.filter((x) => x !== part)
            }

            return [...prev, part]
        })
    }

    const handleToggleSelectRepairTime = (repairTime: DMS.RepairTime) => {
        setSelectedRepairTimes((prev) => {
            if (prev.includes(repairTime)) {
                return prev.filter((x) => x !== repairTime)
            }

            return [...prev, repairTime]
        })
    }

    const handleOnAddPartToBasket = (part: DMS.Part) => {
        props.onAddPartsOrRepairTimes?.(props.voucher, [part], [])
    }

    const handleOnAddRepairTimeToCostEstimation = (repairTime: DMS.RepairTime) => {
        props.onAddPartsOrRepairTimes?.(props.voucher, [], [repairTime])
    }

    const renderPrice = (label: string, value: string, extraClassName?: string) => {
        return (
            <div className={concat(" ", `${className}__price-wrapper`, extraClassName)}>
                <Text>{label}</Text>
                <Text className={`${className}__price`}>{value}</Text>
            </div>
        )
    }

    const renderInfo = (label: string, value: string | ReactNode | undefined) => {
        return (
            <div className={`${className}__info`}>
                <Text size="xs" modifiers={["block", "sub"]} className={`${className}__label`}>
                    {label}
                </Text>
                {!value || typeof value == "string" ? <Text className={`${className}__value`}>{value || "-"}</Text> : value}
            </div>
        )
    }

    const renderTotalSum = () => {
        return renderPrice(translateText(62), currency(voucher.totals?.priceVatIncluded ?? 0, voucher.totals?.currencySymbol ?? ""))
    }

    const renderHead = () => {
        const { customer, vehicle, mileage } = voucher

        return (
            <div className={`${className}__head`}>
                <div className={`${className}__row ${className}__row--flex`}>
                    <Text size="l">
                        {voucher.voucherType.description} {voucher.voucherId}
                    </Text>
                    <Loader visible={props.loading} className={`${className}__loader`} />
                    {renderTotalSum()}
                </div>
                <div className={`${className}__row ${className}__row--info`}>
                    {renderInfo(`${translateText(155)} / ${translateText(108)}`, customer && getCustomerNameAndCompany(customer))}
                    {renderInfo(translateText(111), customer?.generalAddress?.street)}
                    {renderInfo(
                        `${translateText(112)}, ${translateText(113)}`,
                        customer?.generalAddress && concat(" ", customer.generalAddress.zip, customer.generalAddress.city)
                    )}
                </div>
                <div className={`${className}__row ${className}__row--info`}>
                    {renderInfo(translateText(99), vehicle && getVehicleName(vehicle))}
                    {renderInfo(
                        translateText(21),
                        vehicle?.registrationInformation?.plateId && (
                            <LicensePlateField
                                size="s"
                                readonly
                                value={vehicle.registrationInformation.plateId}
                                shortCountryCode={plateCode}
                                className={`${className}__value ${className}__value--license-plate`}
                            />
                        )
                    )}
                    {renderInfo(
                        translateText(124),
                        vehicle?.registrationInformation?.registrationDate &&
                            date(vehicle.registrationInformation.registrationDate, getDateFormat(languageId))
                    )}
                    {renderInfo(translateText(125), mileage !== undefined && number(mileage, 0))}
                    {renderInfo(translateText(1931), vehicle?.vin)}
                    {renderInfo(translateText(105), vehicle?.engineCode)}
                </div>
            </div>
        )
    }

    const renderOptions = () => {
        // if (!selectedParts.length && !selectedRepairTimes.length) return

        const noneSelected = !selectedParts.length && !selectedRepairTimes.length
        const allSelected = selectedParts.length === voucher.parts.length && selectedRepairTimes.length === voucher.repairTimes.length

        return (
            <div className={`${className}__options`}>
                <Text>{translateText(1932).replace("{0}", `${selectedParts.length}`).replace("{1}", `${selectedRepairTimes.length}`)}</Text>
                {props.onAddPartsOrRepairTimes && (
                    <Tooltip content={!noneSelected ? translateText(1933) : translateText(1934)}>
                        <Button
                            icon="cart"
                            skin="highlight"
                            layout={["bordered"]}
                            className={`${className}__button--basket addToBasketButton`}
                            onClick={addSelectedItemsToBasket}
                            disabled={noneSelected}
                        />
                    </Tooltip>
                )}
                <Tooltip content={!allSelected ? translateText(1935) : translateText(1936)}>
                    <div className={`${className}__checkbox`}>
                        <Checkbox checked={allSelected} onToggle={!allSelected ? selectAll : deselectAll} />
                    </div>
                </Tooltip>
            </div>
        )
    }

    const renderPartsSum = () => {
        return renderPrice(translateText(1720), currency(voucher.totals?.partsCosts ?? 0, voucher.totals?.currencySymbol ?? ""))
    }

    const renderParts = () => {
        return (
            <Collapsible
                initiallyOpened
                name={translateText(1667)}
                renderHeaderAppendix={renderPartsSum}
                className={`${className}__collapsible ${className}__parts`}
            >
                <PartsTable
                    parts={voucher.parts}
                    selectedParts={selectedParts}
                    onToggleSelectPart={handleToggleSelectPart}
                    onAddPartToBasket={props.onAddPartsOrRepairTimes ? handleOnAddPartToBasket : undefined}
                />
            </Collapsible>
        )
    }

    const renderRepairTimesSum = () => {
        const calculatedWorkTime = calculateRawAwByDivision(
            {
                value: voucher.totals?.workTime ?? 0,
                division: firstAvailableDivision,
            },
            userRepairTimeDivision
        )

        return (
            <div>
                {renderPrice(
                    translateText(1668),
                    `${number(calculatedWorkTime, 2)} ${translateText(userRepairTimeDivision === ERepairTimeDivision.Hours ? 60 : 161)}`,
                    `${className}__price-wrapper--repair-time`
                )}
                {renderPrice(translateText(1721), currency(voucher.totals?.worksCosts ?? 0, voucher.totals?.currencySymbol ?? ""))}
            </div>
        )
    }

    const renderRepairTimes = () => {
        return (
            <Collapsible
                initiallyOpened
                name={translateText(1668)}
                renderHeaderAppendix={renderRepairTimesSum}
                className={`${className}__collapsible ${className}__repair-times`}
            >
                <RepairTimesTable
                    repairTimes={voucher.repairTimes}
                    selectedRepairTimes={selectedRepairTimes}
                    onToggleSelectRepairTime={handleToggleSelectRepairTime}
                    onAddRepairTimeToCostEstimation={props.onAddPartsOrRepairTimes ? handleOnAddRepairTimeToCostEstimation : undefined}
                />
            </Collapsible>
        )
    }

    return (
        <div className={concat(" ", props.className, className)}>
            {renderHead()}
            {renderOptions()}
            {renderParts()}
            {renderRepairTimes()}
        </div>
    )
}
