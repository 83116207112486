import {
    Version,
    OrderValidationMessage,
    UpdateWorkflow,
    SelectedCustomerGroupMode,
    SelectedPriceTypeMode,
    SelectedPriceVatMode,
    UserInteractionLog,
    ArticleInformation,
    VatRate,
    LinkedItemsRole,
    RepairTimeProvider,
    WorkType,
    WorkCategory,
    FittingPosition,
} from "@tm/models"

export type OrderInformation = {
    orderRecipient: OrderRecipient
    orderNumber: string
    orderDate: Date
}

type OrderRecipient = {
    warehouseId: string
    warehouseName: string
    distributorId: number
    distributorName: string
}

export type SendCostEstimationRequest = {
    workTaskId: string
    distributorId: number
    telesalesCustomerNo?: string
}

export type SendCostEstimationResponse = {
    isBasketEmpty: boolean
    hasSucceeded: boolean
    message: OrderValidationMessage
}

export enum ESendCostEstimationMode {
    basket = 1,
    costEstitmation = 2,
}

type ChangeRequest = {
    workTaskId: string
    version?: Version
}

export type ChangeRepairTimeCalculationRequest = ChangeRequest & {
    useRepairTimeCalculation: boolean
}

export type ChangeCustomerGroupModeRequest = ChangeRequest & {
    customerGroupMode?: SelectedCustomerGroupMode
}

export type ChangePriceTypeModeRequest = ChangeRequest & {
    priceTypeMode?: SelectedPriceTypeMode
}

export type ChangePriceVatModeRequest = ChangeRequest & {
    priceVatMode?: SelectedPriceVatMode
}

export type ChangeUseNoteTextRequest = ChangeRequest & {
    useNoteText: boolean
}

export type ChangeCostEstimationResponse = {
    version: Version
    basketUpdateWorkflow: UpdateWorkflow
}

/*
 * cost estimation voucher
 */
export type ImportVoucherRequest = {
    workTaskId: string
    vehicleId?: string
    customerId?: string
    voucherParts?: VoucherPart[]
    voucherWorks?: VoucherWork[]
    currencyCode?: string
    currencySymbol?: string
    vehicleImage?: string // Base64
}

export type VoucherPart = {
    articleInformation: ArticleInformation
    quantityValue: number
    rebate?: number
    surcharge?: number
    vatRate?: VatRate
    purchaseNetPrice?: number
    retailNetPrice?: number
    itemRole?: LinkedItemsRole
    linkedItems?: VoucherPart[]
}

export type VoucherWork = {
    provider: RepairTimeProvider
    providerWorkId: string
    displayNumber: string
    type: WorkType
    description: string
    category: WorkCategory
    includedWorks: VoucherIncludedWork[]
    timeInMinutes?: number
    providerTimeInMinutes?: number
    hourlyRateNet?: number
    fixedPriceNetValue?: number
    isFixedPrice?: boolean
    rebate?: number
    surcharge?: number
    vatRate?: VatRate
    fittingSide?: FittingPosition
    productGroupIds?: number[]
    note?: string
    sortNo?: number
}

export type VoucherIncludedWork = {
    providerWorkId: string
    category: WorkCategory
    description: string
    isCalculable?: boolean
    sortNo?: number
    timeInMinutes?: number
    isInternal?: boolean
}
