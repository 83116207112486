import { useUser, useWorkTask } from "@tm/context-distribution"
import { Button, QuestionPrompt, Text, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, DMS, RegisteredModels, WorkTaskStatus } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Loader, Stack, Typography } from "@tm/components"
import { ErrorBoundary } from "../_shared/error-boundary"
import { VerticalRule } from "../_shared/vertical-rule"
import { getBundleParams } from "../../utils"
import { mapDmsToWorkTaskCustomer, mapDmsToWorkTaskVehicle } from "../../business"
import { DmsContainer } from "../../business/import"
import { connectionChannel, messageChannel } from "../../business/messaging"
import { useDmsInfo, useDmsPermissions } from "../hooks"
import {
    INITIAL_STATE,
    reduce,
    registerGatewayResponseListener,
    searchCustomer,
    searchCustomerVehicles,
    SearchType,
    searchVehicles,
    searchVouchers,
    selectCustomer,
    selectVehicle,
    selectVoucher,
    setMessage,
    setSearchType,
    setVoucherType,
    showVoucher,
} from "./business"
import { OverlayMessage } from "./components/overlay-message"
import { Search } from "./components/search"
import { SelectedCustomer } from "./components/selected-customer"
import { SelectedVehicle } from "./components/selected-vehicle"
import { CustomerSelection } from "./components/selections/customer"
import { VehicleSelection } from "./components/selections/vehicle"
import { VoucherSelection } from "./components/selections/voucher"
import { VoucherTypeFilter } from "./components/toolbar/voucher-type-filter"
import { VoucherDetails } from "./components/voucher-details"
import { getComponentStyles } from "./styles"
import { useInvalidateWorkTaskBasketQueries } from "../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

type RouteParams = {
    voucherReferenceId?: string
}

type Question = {
    text: string
    onConfirm?(): void
    onCancel?(): void
    onClose?(): void
}

export default function Overview() {
    const className = useMemo(() => getComponentStyles(), [])
    const { permittedOperations } = useDmsPermissions()
    const { name: dmsName, icon: dmsIcon } = useDmsInfo()
    const localization = useLocalization()
    const history = useHistory()
    const matchParams = useParams<RouteParams>()
    const match = useRouteMatch()

    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const { translate, translateText } = localization
    const user = useUser()
    const { workTask, reloadWorkTask, triggerWorkTaskConflict } = useWorkTask() ?? {}
    const { id: workTaskId, vehicle: workTaskVehicle, customer: workTaskCustomer } = workTask ?? {}

    const [state, dispatch] = useReducer(reduce, INITIAL_STATE)
    const { message, searchType, filters, customers, vehicles, vouchers } = state

    const [isConnected, setIsConnected] = useState(() => connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content?.isConnected ?? false)
    const [refreshingConnection, setRefreshingConnection] = useState(false)
    const refreshingConnectionTimeout = useRef<number>()

    const retryFunction = useRef<() => void>()

    const questionPromptRef = useRef<QuestionPrompt>(null)
    const [question, setQuestion] = useState<Question>()

    const voucherDetailsErrorBoundaryRef = useRef<ErrorBoundary>(null)

    useEffect(() => {
        if (question) {
            questionPromptRef.current?.show()
        }
    }, [question])

    const getSettingsUrl = () => {
        return renderRoute(getBundleParams().settingsModuleUrl, matchParams)
    }

    useEffect(() => {
        const unsubHealthCheck = connectionChannel().subscribe(
            "CONNECTION_STATUS_RECEIVED",
            (response) => {
                setIsConnected(!!response.isConnected)
                setRefreshingConnection(false)
            },
            true
        )

        const unsub = registerGatewayResponseListener(dispatch, localization)

        return () => {
            unsubHealthCheck()
            unsub()
            window.clearTimeout(refreshingConnectionTimeout.current)
        }
    }, [])

    const handleShowVoucher = useCallback(
        (referenceId: string | undefined) => {
            // If an error occured during rendering the voucher details
            // reset the error boundary on voucher change
            voucherDetailsErrorBoundaryRef.current?.reset()

            if (referenceId) {
                const voucher = vouchers.response?.find((x) => x.referenceId === referenceId)

                if (voucher) {
                    selectVoucher(dispatch, voucher)
                }
            } else {
                selectVoucher(dispatch, undefined)
            }

            const url = renderRoute(match.path, {
                ...matchParams,
                voucherReferenceId: referenceId,
            })

            history.push(url)
        },
        [vouchers.response, history, match.path, matchParams]
    )

    useEffect(() => {
        const { voucherReferenceId } = matchParams
        if (!isConnected || !voucherReferenceId) {
            return
        }
        showVoucher(dispatch, voucherReferenceId)
    }, [isConnected, matchParams])

    useEffect(() => {
        // Initially load recent vouchers (when searchType is not set)
        if (!isConnected || searchType || !permittedOperations.includes("findVouchers")) {
            return
        }

        searchVouchers(dispatch, {
            vehicleReferenceId: workTaskVehicle?.refId,
            customerReferenceId: workTaskVehicle?.refId ? undefined : workTaskCustomer?.refId,
            orderBy: "modifiedDate",
            orderByDescending: true,
            voucherTypeReferenceId: filters.voucherType?.referenceId,
        })
    }, [isConnected, searchType, workTaskVehicle?.refId, workTaskCustomer?.refId, filters.voucherType?.referenceId, permittedOperations])

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        channel("WORKTASK", workTaskId).publish("MODULE/OPENED", {
            key: "dms",
            title: dmsName,
            icon: dmsIcon,
            url: "",
            isSelected: true,
        })
    }, [workTaskId, dmsName, dmsIcon])

    const refreshConnection = async () => {
        setRefreshingConnection(true)
        refreshingConnectionTimeout.current = window.setTimeout(() => setRefreshingConnection(false), 5000)

        if (user) {
            // Reload user settings because the apiUrl could have changed
            try {
                await user.reloadUserSettings()
            } catch {}
        }

        connectionChannel().publish("REQUEST_CONNECTION_STATUS", { forceReload: true })
    }

    const handleSearch = useCallback(
        (query: string, searchType: SearchType) => {
            if (!query) {
                return
            }

            const request: DMS.FindCustomersRequest | DMS.FindVehiclesRequest | DMS.FindVouchersRequest = {
                orderBy: "modifiedDate",
                orderByDescending: true,
            }

            switch (searchType) {
                case "customer": {
                    if (customers.processId === query) {
                        return
                    }
                    setSearchType(dispatch, searchType)
                    searchCustomer(dispatch, { ...request, query })
                    break
                }
                case "vehicle": {
                    if (vehicles.processId === query) {
                        return
                    }
                    setSearchType(dispatch, searchType)
                    searchVehicles(dispatch, { ...request, query })
                    break
                }
                case "voucher": {
                    if (vouchers.processId === query) {
                        return
                    }
                    setSearchType(dispatch, searchType)
                    searchVouchers(dispatch, { ...request, query, voucherTypeReferenceId: filters.voucherType?.referenceId })
                    break
                }
                default:
                    break
            }
        },
        [customers.processId, vehicles.processId, vouchers.processId, filters.voucherType?.referenceId]
    )

    const handleResetSearch = useCallback(() => {
        if (!searchType) {
            return
        }
        setSearchType(dispatch, undefined)
    }, [searchType])

    const handleLoadNextCustomers = useCallback(() => {
        if (customers.loading || customers.endOfList || !customers.request?.query) {
            return
        }
        searchCustomer(dispatch, { ...customers.request, pageIndex: (customers.request.pageIndex ?? 1) + 1 })
    }, [customers.loading, customers.endOfList, customers.request])

    const handleSelectCustomer = useCallback(
        (customer: DMS.Customer) => {
            selectCustomer(dispatch, customer, filters.voucherType, permittedOperations)
        },
        [filters.voucherType, permittedOperations]
    )

    const handleDeselectCustomer = useCallback(() => {
        selectCustomer(dispatch, undefined)
    }, [])

    const attachCustomer = async (customer: DMS.Customer) => {
        if (!workTaskId) {
            return
        }

        const vehicle = customer.vehicles?.[0]

        const attach = async (newWorkTaskId: string) => {
            setMessage(dispatch, translateText(1952))

            const attachInternal = async (vehicle: DMS.Vehicle | undefined) => {
                try {
                    const container: DmsContainer = Container.getInstance(RegisteredModels.ImportExport)
                    await container.action("importCustomer")({
                        workTaskId: newWorkTaskId,
                        customer,
                        vehicle,
                    })

                    if (newWorkTaskId === workTaskId) {
                        await reloadWorkTask?.()
                    }

                    // setMessage(dispatch, undefined)
                    history.push(`/${encodeUniqueId(newWorkTaskId)}`)
                } catch (ex) {
                    setMessage(dispatch, `${ex}`, "error")
                    retryFunction.current = () => attach(newWorkTaskId)
                }
            }

            if (!vehicle) {
                attachInternal(undefined)
                return
            }

            const processId = uniqueId()

            const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                if (e.type === "showVehicle" && e.processId === processId) {
                    unsub()

                    if (e.response?.vehicle && e.response.vehicle.referenceId === vehicle.referenceId) {
                        attachInternal(e.response.vehicle)
                    }
                }
            })

            messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "showVehicle", request: { referenceId: vehicle.referenceId } })
        }

        if (!workTaskVehicle || !vehicle || workTaskVehicle.tecDocTypeId === vehicle.vehicleType.id) {
            // No vehicle (or same tecdoc type) attached to work task or customer has no vehicle to attach
            attach(workTaskId)
        } else {
            // Show conflict dialog to ask user how to proceed
            triggerWorkTaskConflict?.({
                currentWorkTaskId: workTaskId,
                data: {
                    customer: mapDmsToWorkTaskCustomer(customer),
                    vehicle: mapDmsToWorkTaskVehicle(vehicle, customer.customerId),
                },
                options: {
                    showNewWorkTask: true,
                    showNewCustomerVehicle: false,
                },
                onConfirm: (newWorkTask) => {
                    if (newWorkTask) {
                        // Create new work task with unique id
                        attach(uniqueId())
                    } else {
                        // Overwrite current work task
                        attach(workTaskId)
                    }
                },
            })
        }
    }

    const handleLoadNextVehicles = useCallback(() => {
        if (vehicles.loading || vehicles.endOfList || !vehicles.request) {
            return
        }

        const pageIndex = (vehicles.request.pageIndex ?? 1) + 1

        if (searchType === "vehicle") {
            if (!vehicles.request.query) {
                return
            }
            searchVehicles(dispatch, { ...vehicles.request, pageIndex })
        } else {
            if (!vehicles.request.customerReferenceId) {
                return
            }
            searchCustomerVehicles(dispatch, { ...vehicles.request, pageIndex })
        }
    }, [searchType, vehicles.loading, vehicles.endOfList, vehicles.request])

    const handleSelectVehicle = useCallback(
        (vehicle: DMS.Vehicle) => {
            selectVehicle(dispatch, vehicle, filters.voucherType)
        },
        [filters.voucherType]
    )

    const handleDeselectVehicle = useCallback(() => {
        selectVehicle(dispatch, undefined)
    }, [])

    const attachVehicle = (vehicle: DMS.Vehicle) => {
        if (!workTaskId) {
            return
        }

        const attach = async (newWorkTaskId: string) => {
            setMessage(dispatch, translateText(1953))

            const attachInternal = async (customer: DMS.Customer | undefined) => {
                try {
                    if (!customer) {
                        return
                    }

                    const container: DmsContainer = Container.getInstance(RegisteredModels.ImportExport)
                    await container.action("importCustomer")({
                        workTaskId: newWorkTaskId,
                        customer,
                        vehicle,
                    })

                    if (newWorkTaskId === workTaskId) {
                        await reloadWorkTask?.()
                    }

                    // setMessage(dispatch, undefined)
                    history.push(`/${encodeUniqueId(newWorkTaskId)}`)
                } catch (ex) {
                    setMessage(dispatch, `${ex}`, "error")
                    retryFunction.current = () => attach(newWorkTaskId)
                }
            }

            if (customers.selected) {
                attachInternal(customers.selected)
                return
            }

            if (!vehicle.customerReferenceId) {
                attachInternal(undefined)
                return
            }

            const processId = uniqueId()

            const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
                if (e.type === "showCustomer" && e.processId === processId) {
                    unsub()

                    if (e.response?.customer && e.response.customer.referenceId === vehicle.customerReferenceId) {
                        attachInternal(e.response.customer)
                    }
                }
            })

            messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "showCustomer", request: { referenceId: vehicle.customerReferenceId } })
        }

        if (!workTaskVehicle || workTaskVehicle.tecDocTypeId === vehicle.vehicleType.id) {
            // No vehicle (or same tecdoc type) attached to work task
            attach(workTaskId)
        } else {
            // Show conflict dialog to ask user how to proceed
            triggerWorkTaskConflict?.({
                currentWorkTaskId: workTaskId,
                data: {
                    // customer: mapDmsToWorkTaskCustomer(customer),
                    vehicle: mapDmsToWorkTaskVehicle(vehicle),
                },
                options: {
                    showNewWorkTask: true,
                    showNewCustomerVehicle: false,
                },
                onConfirm: (newWorkTask) => {
                    if (newWorkTask) {
                        // Create new work task with unique id
                        attach(uniqueId())
                    } else {
                        // Overwrite current work task
                        attach(workTaskId)
                    }
                },
            })
        }
    }

    const handleLoadNextVouchers = useCallback(() => {
        if (vouchers.loading || vouchers.endOfList || !vouchers.request) {
            return
        }

        if (
            (searchType === "voucher" && !vouchers.request.query) ||
            (searchType && searchType !== "voucher" && !vouchers.request.vehicleReferenceId)
        ) {
            return
        }

        searchVouchers(dispatch, { ...vouchers.request, pageIndex: (vouchers.request.pageIndex ?? 1) + 1 })
    }, [searchType, vouchers.loading, vouchers.endOfList, vouchers.request])

    const handleSelectVoucher = useCallback(
        (voucher: DMS.Voucher) => {
            if (vouchers.selected?.referenceId !== voucher.referenceId) {
                handleShowVoucher(voucher.referenceId)
            }
        },
        [vouchers.selected?.referenceId, handleShowVoucher]
    )

    const handleDeselectVoucher = useCallback(() => {
        handleShowVoucher(undefined)
    }, [handleShowVoucher])

    const attachVoucher = async (voucher: DMS.Voucher) => {
        setMessage(dispatch, translateText(1954))

        const attach = async () => {
            try {
                const createNewWorkTask = workTask?.statusValue !== WorkTaskStatus.Undefined
                const newWorkTaskId = (!createNewWorkTask ? workTaskId : undefined) ?? uniqueId()

                const container: DmsContainer = Container.getInstance(RegisteredModels.ImportExport)
                await container.action("importVoucher")({
                    ...voucher,
                    workTaskId: newWorkTaskId,
                })

                if (!createNewWorkTask) {
                    await reloadWorkTask?.()
                    invalidateAllWorkTaskBasketRequests(newWorkTaskId)
                }

                // setMessage(dispatch, undefined)
                history.push(`/${encodeUniqueId(newWorkTaskId)}`)
            } catch (ex) {
                setMessage(dispatch, `${ex}`, "error")
                retryFunction.current = () => attachVoucher(voucher)
            }
        }

        const processId = uniqueId()

        const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
            if (e.type === "showVoucher" && e.processId === processId) {
                unsub()

                if (e.response?.voucher && e.response.voucher.referenceId === voucher.referenceId) {
                    voucher = e.response.voucher
                    attach()
                }
            }
        })

        messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "showVoucher", request: { referenceId: voucher.referenceId } })
    }

    const clearAttachingError = useCallback(() => {
        setMessage(dispatch, undefined)
        retryFunction.current = undefined
    }, [])

    const retryAttaching = useCallback(() => {
        setMessage(dispatch, undefined)
        retryFunction.current?.()
        retryFunction.current = undefined
    }, [])

    const extendVoucher = (voucher: DMS.Voucher, parts: Array<DMS.Part>, repairTimes: Array<DMS.RepairTime>) => {
        if (!workTaskId) {
            return
        }

        const extend = (withCustomerAndVehicle: boolean) => {
            setQuestion(undefined)

            const extendInternal = async (newWorkTaskId: string) => {
                setMessage(dispatch, translateText(1955))

                try {
                    const container: DmsContainer = Container.getInstance(RegisteredModels.ImportExport)
                    await container.action("extendVoucher")({
                        workTaskId: newWorkTaskId,
                        customer: withCustomerAndVehicle ? voucher.customer : undefined,
                        vehicle: withCustomerAndVehicle ? voucher.vehicle : undefined,
                        parts,
                        repairTimes,
                    })

                    if (newWorkTaskId === workTaskId) {
                        await reloadWorkTask?.()
                        invalidateAllWorkTaskBasketRequests(workTaskId)
                    } else {
                        const url = renderRoute(match.path, { ...matchParams, workTaskId: encodeUniqueId(newWorkTaskId) })
                        history.push(url)
                    }

                    setMessage(dispatch, undefined)
                } catch (ex) {
                    setMessage(dispatch, `${ex}`, "error")
                    retryFunction.current = () => extendInternal(newWorkTaskId)
                }
            }

            if (!withCustomerAndVehicle || !workTaskVehicle || !voucher.vehicle || workTaskVehicle.tecDocTypeId === voucher.vehicle.vehicleType.id) {
                // No vehicle (or same tecdoc type) attached to work task or voucher has no vehicle to attach
                extendInternal(workTaskId)
            } else {
                // Show conflict dialog to ask user how to proceed
                triggerWorkTaskConflict?.({
                    currentWorkTaskId: workTaskId,
                    data: {
                        customer: voucher.customer ? mapDmsToWorkTaskCustomer(voucher.customer) : undefined,
                        vehicle: mapDmsToWorkTaskVehicle(voucher.vehicle, voucher.customer?.customerId),
                    },
                    options: {
                        showNewWorkTask: true,
                        showNewCustomerVehicle: false,
                    },
                    onConfirm: (newWorkTask) => {
                        if (newWorkTask) {
                            // Create new work task with unique id
                            extendInternal(uniqueId())
                        } else {
                            // Overwrite current work task
                            extendInternal(workTaskId)
                        }
                    },
                })
            }
        }

        // Vehicle and customer attached to worktask are the same as the ones from the current voucher
        if (
            workTaskVehicle?.refId &&
            workTaskVehicle.refId === voucher.vehicle?.referenceId &&
            workTaskCustomer?.refId &&
            workTaskCustomer.refId === voucher.customer?.referenceId
        ) {
            extend(false)
        } else {
            setQuestion({
                text: translateText(1956),
                onConfirm: () => extend(true),
                onCancel: () => extend(false),
                onClose: () => setQuestion(undefined),
            })
        }
    }

    // const showArticleHistory = useCallback(async (vehicle: Vehicles.Vehicle) => {

    // }, [])

    const changeVoucherType = useCallback(
        (voucherType: DMS.VoucherType | undefined) => {
            setVoucherType(dispatch, voucherType)
            handleShowVoucher(undefined)

            if (vehicles.selected && (searchType === "customer" || searchType === "vehicle")) {
                searchVouchers(dispatch, {
                    vehicleReferenceId: vehicles.selected.referenceId,
                    orderBy: "modifiedDate",
                    orderByDescending: true,
                    voucherTypeReferenceId: voucherType?.referenceId,
                })
            }
        },
        [searchType, vehicles.selected, handleShowVoucher]
    )

    const renderSearch = () => {
        if (workTaskVehicle?.refId || workTaskCustomer?.refId) {
            return
        }

        // No search is allowed
        if (!permittedOperations.includes("findCustomers") && !permittedOperations.includes("findVouchers")) {
            return
        }

        return <Search onStartSearch={handleSearch} onResetSearch={handleResetSearch} />
    }

    const renderCustomerSelection = () => {
        if (searchType !== "customer") {
            return
        }

        if (customers.selected) {
            return <SelectedCustomer customer={customers.selected} onDeselect={handleDeselectCustomer} />
        }

        if (!customers.response && !customers.loading) {
            return
        }

        return (
            <CustomerSelection
                customers={customers.response ?? []}
                onCustomerSelect={permittedOperations.includes("findVehicles") ? handleSelectCustomer : undefined}
                loading={customers.loading}
                onCustomerAttach={permittedOperations.includes("showVehicle") ? attachCustomer : undefined}
                label={!customers.loading ? translateText(1957) : translateText(107)}
                noItemsMessage={translateText(1959)}
                onLoadNextCustomers={handleLoadNextCustomers}
            />
        )
    }

    const renderVehicleSelection = () => {
        if (searchType !== "vehicle" && searchType !== "customer") {
            return
        }

        if (vehicles.selected) {
            return <SelectedVehicle vehicle={vehicles.selected} onDeselect={handleDeselectVehicle} />
        }

        if (!vehicles.response && !vehicles.loading) {
            return
        }

        let label = translateText(1962)
        if (!vehicles.loading) {
            label = translateText(1960)
        } else if (searchType === "vehicle") {
            label = translateText(1961)
        }

        return (
            <VehicleSelection
                vehicles={vehicles.response ?? []}
                onVehicleSelect={permittedOperations.includes("findVouchers") ? handleSelectVehicle : undefined}
                withCustomerInfo={searchType === "vehicle"}
                loading={vehicles.loading}
                onVehicleAttach={permittedOperations.includes("showCustomer") ? attachVehicle : undefined}
                label={label}
                noItemsMessage={searchType === "vehicle" ? translateText(1963) : translateText(1964)}
                onLoadNextVehicles={handleLoadNextVehicles}
                // onShowArticleHistory={showArticleHistory}
            />
        )
    }

    const renderVoucherDetailsError = useCallback(() => {
        return (
            <div className={`${className}__voucher-details-error`}>
                <Text size="l">{translate(2009)}</Text>
            </div>
        )
    }, [translate])

    const renderVouchers = () => {
        if (!vouchers.response && !vouchers.loading && !vouchers.selected) {
            return
        }

        const getLabel = () => {
            if (workTaskVehicle?.refId) {
                if (vouchers.loading) {
                    return translateText(1965)
                }

                return translateText(1966)
            }
            if (workTaskCustomer?.refId) {
                if (vouchers.loading) {
                    return translateText(2010)
                }

                return translateText(2011)
            }
            if (searchType) {
                if (vouchers.loading) {
                    if (searchType === "voucher") {
                        return translateText(1967)
                    }

                    return translateText(1968)
                }

                return translateText(1969)
            }

            if (vouchers.loading) {
                return translateText(1970)
            }

            return translateText(1971)
        }

        const getNoItemsMessage = () => {
            if (workTaskVehicle?.refId || vouchers.request?.vehicleReferenceId) {
                return translateText(1972)
            }
            if (workTaskCustomer?.refId) {
                return translateText(2012)
            }
            if (searchType) {
                return translateText(1973)
            }

            return translateText(1974)
        }

        let content = (
            <div className={`${className}__voucher-list`}>
                <VoucherSelection
                    vouchers={vouchers.response ?? []}
                    onVoucherSelect={permittedOperations.includes("showVoucher") ? handleSelectVoucher : undefined}
                    onVoucherDeselect={handleDeselectVoucher}
                    loading={vouchers.loading}
                    compact={!!vouchers.selected}
                    selectedVoucher={vouchers.selected}
                    onVoucherAttach={!workTask?.workTaskReadModel?.refId && permittedOperations.includes("showVoucher") ? attachVoucher : undefined}
                    label={getLabel()}
                    noItemsMessage={getNoItemsMessage()}
                    onLoadNextVouchers={handleLoadNextVouchers}
                    withVehicleInfo={!workTaskVehicle?.refId}
                />
            </div>
        )

        if (vouchers.selected) {
            content = (
                <>
                    {content}
                    <VerticalRule />
                    <div className={`${className}__voucher-details-wrapper`}>
                        <ErrorBoundary ref={voucherDetailsErrorBoundaryRef} content={renderVoucherDetailsError}>
                            <VoucherDetails
                                voucher={vouchers.selected}
                                loading={vouchers.detailsLoading}
                                className={`${className}__voucher-details`}
                                onAddPartsOrRepairTimes={extendVoucher}
                            />
                        </ErrorBoundary>
                    </div>
                </>
            )
        }

        return <div className={`${className}__voucher-wrapper`}>{content}</div>
    }

    const renderOverlayMessage = () => {
        if (!user?.userSettings) {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{translate(1975)}</Text>
                    <Loader className={`${className}__loader`} />
                </OverlayMessage>
            )
        }

        if (!user.userSettings.dmsSettings.apiUrl) {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{translate(1976)}</Text>
                    <Button icon="user-settings" linkTo={getSettingsUrl()} className={`${className}__action`}>
                        {translate(1977)}
                    </Button>
                </OverlayMessage>
            )
        }

        if (!isConnected) {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{translateText(1978).replace("{0}", dmsName)}</Text>
                    {user.userSettings.dmsSettings.apiUrl.toLowerCase().startsWith("https") && (
                        <Text className={`${className}__hint`}>{translateText(1982).replace("{0}", dmsName)}</Text>
                    )}
                    {refreshingConnection && <Loader className={`${className}__loader`} />}
                    <Button icon="synchronize" disabled={refreshingConnection} onClick={refreshConnection} className={`${className}__action`}>
                        {translate(1979)}
                    </Button>
                    <Button icon="user-settings" linkTo={getSettingsUrl()} className={`${className}__action`}>
                        {translate(1977)}
                    </Button>
                </OverlayMessage>
            )
        }

        if (message?.type === "auth-error") {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{message.text.replace("{0}", dmsName)}</Text>
                    <Button icon="user-settings" linkTo={getSettingsUrl()} className={`${className}__action`}>
                        {translate(1977)}
                    </Button>
                </OverlayMessage>
            )
        }

        if (message?.type === "error") {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{message.text}</Text>
                    <Button icon="close" onClick={clearAttachingError} className={`${className}__action`}>
                        {translate(317)}
                    </Button>
                    {retryFunction.current && (
                        <Button icon="synchronize" onClick={retryAttaching} className={`${className}__action`}>
                            {translate(1979)}
                        </Button>
                    )}
                    {message.exceptionMessage && (
                        <Text className={`${className}__exception`} size="s">
                            {message.exceptionMessage}
                        </Text>
                    )}
                </OverlayMessage>
            )
        }

        if (message?.type === "status") {
            return (
                <OverlayMessage>
                    <Text className={`${className}__hint`}>{message.text}</Text>
                    <Loader className={`${className}__loader`} />
                </OverlayMessage>
            )
        }

        if (question) {
            return (
                <QuestionPrompt
                    ref={questionPromptRef}
                    text={question.text}
                    confirmationButtonText={translateText(585)}
                    cancelButtonText={translateText(584)}
                    onConfirm={question.onConfirm}
                    onCancel={question.onCancel}
                    onClose={question.onClose}
                />
            )
        }
    }

    return (
        <div className={className}>
            <Stack direction="row" spacing={2}>
                <Typography variant="h1" fontWeight="bold">
                    {dmsName}
                </Typography>
                {permittedOperations.includes("findVoucherTypes") && (
                    <Toolbar title={translateText(209)}>
                        <VoucherTypeFilter selected={filters.voucherType} onChange={changeVoucherType} />
                    </Toolbar>
                )}
            </Stack>

            {renderSearch()}

            {renderCustomerSelection()}
            {renderVehicleSelection()}

            {renderVouchers()}

            {renderOverlayMessage()}
        </div>
    )
}
