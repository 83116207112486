import { useMemo } from "react"
import { ArticleErpInfo, ArticleErpInfos, ErpInformation, OE } from "@tm/models"
import { useArticlesErpInfos } from "./useArticlesErpInfos"
import { mapOePartToArticle } from "../../../../data/mapper/article"

export function useOePartErpInfos(oePart: OE.OePart | undefined, quantity: number = 1): ArticleErpInfos | undefined {
    const articles = useMemo(() => {
        if (!oePart) {
            return []
        }
        const article = mapOePartToArticle(oePart, quantity)
        return [article]
    }, [oePart, quantity])

    return useArticlesErpInfos("oe", articles, quantity).first() ?? { default: undefined, alternative: undefined }
}

export function useDefaultOePartErpInformation(oePart: OE.OePart | undefined, quantity: number): ErpInformation | undefined {
    const articles = useMemo(() => (oePart ? [mapOePartToArticle(oePart, quantity)] : []), [oePart, quantity])
    const defaultErpInfo = useArticlesErpInfos("oe", articles, quantity).first()?.default
    return defaultErpInfo?.state === "success" ? defaultErpInfo.response : undefined
}
