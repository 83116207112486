import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { QueryType, RegistrationNoType, UserContext, VehicleLookupConfig, VehicleSearchType, VehicleType } from "@tm/models"
import { getRegistrationNumberTypeTextIds, useAvailableVehicleSearchOptions } from "@tm/utils"

import { getLookupType } from "../../../../helpers"
import DefaultLicensePlate from "./components/default"
import VrmAAALicensePlate from "./components/vrmaaa"

/** @todo reduce amount of properties somehow? */
export type ConfigProps = {
    registrationNoType?: number
    placeholder?: string
    searchType?: VehicleSearchType
    size?: "xs" | "s" | "m" | "l" | "xl"
    className?: string
    vehicleType: VehicleType
    onlyNumbersAndDigits?: boolean
    showSpecialLicensePlateMicro?: boolean
}

type Props = ConfigProps & {
    /** Is also called when the conflict dialog is opened. Keep that in mind when using this function. */
    onVehicleAttached?(): void
}

/** @todo Rename (to `VehicleSearchField`) */
export default function SpecialLicensePlatesComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const {
        availableVehicleSearches: { configuredVehicleLookups },
    } = useAvailableVehicleSearchOptions()

    const specialLookupConfig =
        !props.registrationNoType && configuredVehicleLookups?.filter((lookup) => lookup.isSelectable).length <= 2
            ? configuredVehicleLookups.find(
                  (lookup) =>
                      (lookup.queryTypeId === QueryType.PlateId || lookup.queryTypeId === QueryType.PlateIdOrVin) && !lookup.isUsedInDefaultSearch
              )
            : undefined

    const containsPlateSearch = (queryType: QueryType) => {
        return queryType === QueryType.PlateId || queryType === QueryType.PlateIdOrVin
    }

    const selectedLookupConfig =
        configuredVehicleLookups
            .filter((lookup) => lookup.isSelectable && !lookup.isUsedInDefaultSearch && containsPlateSearch(lookup.queryTypeId))
            .find((lookup) => lookup.lookupTypeId === props.registrationNoType) || specialLookupConfig

    if (shouldBeHidden(selectedLookupConfig, userContext, props.vehicleType)) {
        return null
    }

    const { countryCode, lookupTypeId } = selectedLookupConfig ?? {}
    const spreadedProps = { ...props, countryCode, selectedLookupType: lookupTypeId, registrationNoType: lookupTypeId, autoFocus: true, subText: "" }

    if (spreadedProps.placeholder) {
        // Replace placeholder with translations, e.g. {{47}} -> Kennzeichen
        spreadedProps.placeholder = spreadedProps.placeholder.replace(/{{(\d.*?)}}/g, (_x, y) => translateText(y))
    }

    // the LookupType returned by "getLookupType" should not be passed to the "DefaultLicensePlate"
    // component as "registrationNoType" or it would call the wrong service endpoint
    const lookupType = lookupTypeId || getLookupType(userContext)

    switch (lookupType) {
        case RegistrationNoType.VrmAAA: {
            // MLE: 16.10.20: Passing the props down is important as otherwise an error would occur,
            // because the VrmAAALicensePlate component uses "connectComponent" and the "store" prop would be missing
            return <VrmAAALicensePlate {...spreadedProps} />
        }

        case RegistrationNoType.KennzeichenPortugal:
        case RegistrationNoType.VLPortugalCreateBusiness: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="P" />
        }

        case RegistrationNoType.KennzeichenNorwegenNbk:
        case RegistrationNoType.KennzeichenNiederlande:
        case RegistrationNoType.KennzeichenSchwedenKgk:
        case RegistrationNoType.TopmotiveVin:
        case RegistrationNoType.LicensePlateFranceTruckInfoAuto: {
            return <DefaultLicensePlate {...spreadedProps} />
        }
        case RegistrationNoType.CartellIrlandKennzeichenWsvc: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="IRL" />
        }

        case RegistrationNoType.VrmKennzeichenNorwegenAIS: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="no" />
        }

        case RegistrationNoType.NationalcodeFrankreich:
        case RegistrationNoType.VINFranceInforauto: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="F" />
        }

        case RegistrationNoType.CarwebVrmWsvc: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="UK" />
        }

        case RegistrationNoType.KennzeichenFinnlandHlGroup: {
            // NEXT-22251: Special hacky behavior for HL Group. Should be a temporary solution 😉
            const placeHolderTexts = getRegistrationNumberTypeTextIds(lookupType, VehicleType.PassengerCar)
            const placeholder = translateText(placeHolderTexts.placeholder || "")
            spreadedProps.placeholder = placeholder.replace("{0}", placeHolderTexts.exampleQuery || "")
            spreadedProps.subText = translateText(1401)
            return <DefaultLicensePlate {...spreadedProps} registrationNoType={undefined} countryCode="FIN" size="m" /> // unset registrationNoType, can be removed if service doesn't set usedInDefaultSearch=false for this lookup
        }
        case RegistrationNoType.VrmKennzeichenFinnlandAISVeCloud:
        case RegistrationNoType.LicensePlateVRMAutoDAP:
        case RegistrationNoType.KennzeichenFinnlandAd: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="FIN" />
        }
        case RegistrationNoType.LicensePlateIcelandAutomatic: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="IS" />
        }
        case RegistrationNoType.VLSpain_CreateBusiness:
        case RegistrationNoType.LicensePlateSpainDriveRight:
        case RegistrationNoType.VrmKennzeichenSpanienDGT:
        case RegistrationNoType.VrmVINSpanienInforauto: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="E" />
        }
        case RegistrationNoType.LicensePlateSwedenDriveRight: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="S" />
        }

        case RegistrationNoType.KennzeichenEstland: {
            spreadedProps.placeholder = translateText(21)
            return <DefaultLicensePlate {...spreadedProps} countryCode="EST" />
        }

        case RegistrationNoType.LicensePlateItalyDriveRight:
        case RegistrationNoType.ItalienEdidomusWsvc:
        case RegistrationNoType.LicensePlateItalyRhiag:
        case RegistrationNoType.VrmLicensePlateHaynesProIt: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="it" />
        }
        case RegistrationNoType.LicensePlateDkVrm_WMKAT:
        case RegistrationNoType.LicensePlateDenmarkDriveRight: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="DK" />
        }
        case RegistrationNoType.KoivunenCatDataWsvc: {
            return <DefaultLicensePlate hidePlateFx {...spreadedProps} subText="" />
        }
        case RegistrationNoType.LicensePlateCyprusKapodistrias: {
            return <DefaultLicensePlate {...spreadedProps} countryCode="CY" />
        }
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Hostettler:
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Esa:
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Rhiag:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Hostettler:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_ESA:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Eurotax: {
            if (spreadedProps.vehicleType === VehicleType.CommercialVehicle) {
                return (
                    <DefaultLicensePlate
                        subTextIcon="search"
                        subTextSize="s"
                        {...spreadedProps}
                        showCountryCode={false}
                        registrationNoType={getSwitzerlandRegistrationNoType(lookupType)}
                        placeholder={translateText(1730)}
                        subText={translateText(1732)}
                    />
                )
            }
            if (
                lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Hostettler ||
                lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Esa ||
                lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Rhiag
            ) {
                const registrationNumberTextIds = getRegistrationNumberTypeTextIds(lookupType, VehicleType.PassengerCar)
                if (registrationNumberTextIds.placeholder) {
                    spreadedProps.placeholder = translateText(registrationNumberTextIds.placeholder)
                }
            }

            return <DefaultLicensePlate subTextIcon="search" showCountryCode={false} subTextSize="s" {...spreadedProps} />
        }

        default:
            break
    }

    return null
}

function getSwitzerlandRegistrationNoType(lookupType: RegistrationNoType): RegistrationNoType {
    if (lookupType === RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Hostettler) {
        return RegistrationNoType.TypenscheinSchweizHoststettler
    }
    if (lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Esa) {
        return RegistrationNoType.TypenscheinSchweizEsa
    }
    if (lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Rhiag) {
        return RegistrationNoType.TypeApprovalNumberSwitzerlandRhiag
    }
    if (lookupType === RegistrationNoType.LicensePlateSwitzerlandAutoIDat_ESA) {
        return RegistrationNoType.TypenscheinSchweizEsa
    }
    if (lookupType === RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Hostettler) {
        return RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Hostettler
    }
    return RegistrationNoType.TypenscheinSchweizEurotax
}

const tradersWithFakeSearchField = [
    746, // krautlipt
    105, // hlgroup
    404, // kpro-online-platform
    1591, // Automatic ehf
]

function shouldBeHidden(selectedLookup: VehicleLookupConfig | undefined, userContext: UserContext, vehicleType: VehicleType) {
    // Always render if the catalog was configured to have a separate search field
    const { traderId } = userContext.principal ?? {}
    if (traderId && tradersWithFakeSearchField.some((id) => id === traderId) && vehicleType === VehicleType.PassengerCar) {
        return false
    }

    // Don't render if the lookup is not configured or the lookup is already used in default search field
    if (!selectedLookup || selectedLookup.isUsedInDefaultSearch) {
        return true
    }

    // Don't render if the lookup is not available for current vehicle type
    if (!selectedLookup.supportedVehicleTypes.includes(vehicleType)) {
        return true
    }

    return false
}
