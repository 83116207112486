import { Article, ArticleListType, ErpSystemConfig } from "@tm/models"
import { useCallback } from "react"
import { useSetRecoilState } from "recoil"
import { ErpInfosState } from "../../states"
import { ArticleErpInfoFactory } from "./factories"
import { useDefaultErpSystem } from "../useDefaultErpSystem"

export type LoadErpInfoItem = {
    article: Article
    quantity?: number
    erpSystem?: ErpSystemConfig
}

export function useLoadErpInfos(listType: ArticleListType) {
    const setErpInfo = useSetRecoilState(ErpInfosState(listType))
    const defaultErpSystem = useDefaultErpSystem()

    return useCallback(
        (data: LoadErpInfoItem | LoadErpInfoItem[]) => {
            if (!defaultErpSystem) {
                return
            }
            const requestItems = Array.isArray(data) ? data : [data]
            if (!requestItems.length) {
                return
            }
            setErpInfo((prev) => {
                let mapped = prev
                requestItems.forEach(({ erpSystem, quantity, article }) => {
                    const erpSystemOrDefault = erpSystem ?? defaultErpSystem
                    const articleWithQuantity: Article = { ...article, quantity: quantity ?? article.quantity ?? 1 }
                    const queuedErpInfo = ArticleErpInfoFactory.createQueued(articleWithQuantity, erpSystemOrDefault)
                    const { key } = queuedErpInfo
                    const index = prev.findIndex((prevItem) => prevItem.key.id === key.id)

                    if (index === -1) {
                        mapped = [...mapped, queuedErpInfo]
                        return
                    }

                    if (prev[index].state === "loading") {
                        return prev
                    }

                    mapped = mapped.with(index, queuedErpInfo)
                })

                return mapped
            })
        },
        [setErpInfo, defaultErpSystem]
    )
}
