export function shouldUseNewModal(source?: string) {
    switch (source) {
        case "TM_MVC_HP":
        case "TM_MVC_TECRMI":
        case "TM_MVC_AD":
        case "TM_MVC_HP_INSP":
        case "TM_MVC_HP_SERVICE_INTERVALS":
        case "TM_MVC_DAT":
            return true

        default:
            return false
    }
}
