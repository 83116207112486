import { PropsWithChildren, useCallback, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { ArticleListType } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useErpInfos } from "../hooks/useErpInfos"
import { useListOptions } from "../hooks/useListOptions"
import { useProductGroupRepairTimes } from "../hooks/useProductGroupRepairTimes"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useVehicle } from "../hooks/useVehicle"
import { useLoadVehicleRecords } from "../hooks/useVehicleRecords"
import { useWatchListData } from "../hooks/useWatchListData"
import { ArticleListActions, VehiclePartsModuleState } from "../models"
import { useArticles } from "./hooks/useArticles"
import { useFilters } from "./hooks/useFilters"
import { useListParams } from "./hooks/useListParams"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useProductGroupTopicIds } from "../hooks/useProductGroupTopicIds"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { usePreviouslyOrderedArticles } from "../hooks/usePreviouslyOrderedArticles"

export function VehiclePartsModuleStateProvider(props: PropsWithChildren<unknown>) {
    const type: ArticleListType = "vehicle"
    const [isEnabled, setIsEnabled] = useState(false)

    const { workTaskId } = useWorkTask() ?? {}
    const vehicle = useVehicle()
    const options = useListOptions("vehicle")
    const params = useListParams()
    const filtersData = useFilters(vehicle, params, isEnabled)
    const articlesData = useArticles(vehicle, params, isEnabled)
    const { productGroupTopicIds } = useProductGroupTopicIds(articlesData.productGroupIds, isEnabled)
    const tradeReferences = useTradeReferences(articlesData.articles, isEnabled)
    const erpIsReady = useErpInfos(type, articlesData.articles, isEnabled, params.startParams, tradeReferences)
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, options.hasWatchList && isEnabled && erpIsReady)
    const productGroupRepairTimesData = useProductGroupRepairTimes(articlesData.productGroupIds, isEnabled && erpIsReady)
    const vehicleRecords = useLoadVehicleRecords(
        vehicle,
        articlesData.productGroupIds,
        articlesData.articles,
        isEnabled && erpIsReady
    ) /** @todo Only load when vehicle records are activated for user and configured to be shown */
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpIsReady)
    const notes = useNotes(articlesData.articles, true, isEnabled && erpIsReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(articlesData.articles)

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogosData.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimesData.isLoading,
        activeVehicleDataProvidersData.isLoading
    )

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: VehiclePartsModuleState = {
        type,
        start: useCallback(() => setIsEnabled(true), []),
        options,
        params,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimesData : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProvidersData.activeProviders : undefined,
        vehicleRecords,
        basketQuantities,
        productGroupTopicIds,
        notes,
        previouslyOrderedArticles,
        tradeReferences,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{props.children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useVehiclePartsModuleState(): VehiclePartsModuleState
export function useVehiclePartsModuleState<TReturnType>(selector: (value: VehiclePartsModuleState) => TReturnType): TReturnType
export function useVehiclePartsModuleState<TReturnType>(
    selector?: (value: VehiclePartsModuleState) => TReturnType
): VehiclePartsModuleState | TReturnType {
    return usePartsModuleState<VehiclePartsModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
