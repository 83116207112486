import { Box, Button, Icon } from "@tm/components"
import { VeFiles } from "@tm/data"
import { useFileDownload } from "../../hooks/useFileDownload"

type FileItemAtionsProps = {
    file: VeFiles.FileMeta
}

export function FileItemActions({ file }: FileItemAtionsProps) {
    const { downloadFile } = useFileDownload()

    function onShareClick(file: VeFiles.FileMeta) {}

    function onDeleteClick(file: VeFiles.FileMeta) {}

    return (
        <Box display="flex" gap="1em">
            <Button startIcon={<Icon name="download" />} onClick={() => downloadFile(file)} />
            <Button startIcon={<Icon name="share" />} onClick={() => onShareClick(file)} />
            <Button startIcon={<Icon name="delete" />} onClick={() => onDeleteClick(file)} />
        </Box>
    )
}
