import { useEffect, memo, useMemo } from "react"
import { connectComponent } from "@tm/morpheus"
import { StylingFromConfig, channel, getWorkTaskId } from "@tm/models"
import { withRouter, RouteComponentProps, useWorkTaskTruckData, uniqueId } from "@tm/utils"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { getItemStyles, getMoreItemStyles } from "./styles"
import { OverflowMenu } from "./components/overflowMenu"
import { Item } from "./components/item"
import { NavigationState, NavigationItem, Actions, IActions, DataType } from "./business"
import { getBundleParams } from "../../utils"
import { ItemWithCar } from "./components/itemWithCar"

type ConfigProps = {
    maxTabs?: number
    layout?: "thumbnail" | "oneItemWithThumbnail" | undefined
    resetWorkTask?: boolean
}

type Props = RouteComponentProps<any> &
    StylingFromConfig &
    ConfigProps & {
        state: NavigationState
        actions: IActions
        dataType?: DataType
    }

const Navigation = memo((props: Props) => {
    const { nextLight, interceptWorkTaskDeletionRequest } = getBundleParams()
    const { workTaskId } = useWorkTask() || {}
    const { workTaskTruckData } = useWorkTaskTruckData(workTaskId)

    const {
        state,
        state: { itemsToRemove, items, loading, initialized, afterLoad },
        actions: { init, subscribe, loadNavigation, setSplitPosition, setAfterLoadHook, closeTabs },
        maxTabs,
        history,
        dataType,
        resetWorkTask,
    } = props

    // Will mount
    useEffect(() => {
        init({ maxTabs })
        subscribe(history)
        loadNavigation(history)
    }, [])

    useEffect(() => {
        if (workTaskId) {
            return channel("WORKTASK", workTaskId).subscribe("WORKTASK/CLOSE", () => {
                closeTabs([workTaskId])
            })
        }
    }, [workTaskId])

    useEffect(() => {
        if (!initialized || loading) {
            return
        }
        if (afterLoad) {
            if (afterLoad.once) {
                setAfterLoadHook(undefined)
            }
            afterLoad.hook(state)
        }
    }, [items])

    useEffect(() => {
        if (itemsToRemove.length) {
            closeTabs(itemsToRemove)
        }
    }, [itemsToRemove])

    useEffect(() => {
        if (workTaskId && workTaskTruckData) {
            props.actions.setWorkTaskTruckData({ workTaskId, workTaskTruckData })
        }
    }, [workTaskId, workTaskTruckData])

    const { translate } = useLocalization()

    function handleArrange(split: number) {
        setSplitPosition(split)
    }

    function closeTab(id: string) {
        const {
            history,
            actions: { closeTabs },
            state: { activeItem },
        } = props
        closeTabs([id])
        if (activeItem == id) {
            history.push("/")
        }
    }

    function handleClose(id: string, e?: Event) {
        e?.stopPropagation()
        e?.preventDefault()

        if (interceptWorkTaskDeletionRequest) {
            channel("GLOBAL").publish("INTERCEPTABLE/WORKTASK_DELETE", {
                workTaskId: id,
                callback: () => {
                    closeTab(id)
                },
            })
        } else {
            closeTab(id)
        }
    }

    function renderItem(item: NavigationItem, className: string, onResize: (entry: ResizeObserverEntry) => void, isSelected?: boolean) {
        if ((props.layout === "thumbnail" || props.layout === "oneItemWithThumbnail") && isSelected) {
            return (
                <ItemWithCar
                    navItem={item}
                    key={`workTaskKey-${item.id}` || "empty"}
                    dataType={dataType}
                    isSelected
                    resetWorkTask={!!item.vehicle && resetWorkTask}
                />
            )
        }

        return (
            <Item
                {...item}
                key={item.id}
                dataType={dataType}
                isSelected={!!isSelected}
                style={props.style}
                onClose={handleClose}
                className={className}
                onResize={onResize}
            />
        )
    }

    function getItemKey(item: NavigationItem) {
        return item.id
    }

    function renderCloseAll() {
        const hasActiveItem = props.state.activeItem ?? false
        return (
            <Button className="close-all" onClick={handleCloseAllItems}>
                {hasActiveItem ? translate(13514) : translate(13513)}
            </Button>
        )
    }

    function handleCloseAllItems() {
        const {
            actions: { closeTabs },
        } = props
        closeTabs(props.state.items.filter((x) => x.id != props.state.activeItem).map((x) => x.id))
    }

    const selected = items.find((x) => x.id == props.state.activeItem)

    const itemsToRender = useMemo((): NavigationItem[] => {
        if (Boolean(nextLight) || props.layout === "oneItemWithThumbnail") {
            return selected ? [selected] : items.slice(0, 1)
        }

        return items
    }, [items, nextLight, selected, props.layout])

    return (
        <OverflowMenu
            onArrange={handleArrange}
            items={itemsToRender}
            renderItem={renderItem}
            getItemKey={getItemKey}
            selected={selected}
            renderOverflowAppendix={renderCloseAll}
            mainItemStyles={getItemStyles()}
            moreItemStyles={getMoreItemStyles()}
        />
    )
})

export default connectComponent(Actions, withRouter(Navigation))
