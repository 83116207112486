import { FC, memo, useEffect, useState } from "react"
import { withRouter } from "react-router"
import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { AmountField, Icon, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleAttribute, EFilterNames, IMicros, RepairTimeProvider, SystemType, TyreFilter, Vehicle } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { bem, createQueryString, encodeUniqueId, getRepairTimeProviders, getRepairTimesProvider, getRepairTimesProviderStringByEnum, renderRoute, RouteComponentProps, uniqueId } from "@tm/utils"
import { em, rem } from "csx"
import { TyreArticle, TyreArticleAttribute } from "../../data/models"
import TiresIcons from "./tiresIcons"
import { batch, useDispatch, useSelector } from 'react-redux'
import { MainState } from '../main'
import { getTitleByGroup, getIconByGroup } from "../../data/helpers"
import { MainActions } from "../main/business"

type Props = RouteComponentProps & {
    item: TyreArticle
    repairTimesRoute: string
    vehicleId?: string
    tecDocTypeId?: number
    vehicle?: Vehicle
    hidden?: boolean
    selectedArticleAttributes?: string[]
    onArticleDetailsClick: (item: TyreArticle, path?: string) => void
    onArticleAttributeSelect(article: TyreArticle, attribute: ArticleAttribute): void
    onChangeStandaloneQuantity: (item: TyreArticle, value: number) => void
}

const ArticleItem: FC<Props> = ({ repairTimesRoute, hidden, onArticleAttributeSelect, item, match, onArticleDetailsClick, onChangeStandaloneQuantity,
    selectedArticleAttributes, vehicle, vehicleId }) => {
    const user = useUser()
    const { renderMicro } = useMicro<IMicros>()
    // const params = useParams() TODO ?? FIND WHY functions is undefined
    const systemType = user?.userContext?.system.systemType
    const { translateText } = useLocalization()
    const providers = useSelector((s: MainState) => s.list.repairTimeAvailabilities?.[item.productGroup.id])

    const showArticleImages = user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages
    const isCompact = user?.userSettings?.articleListSettings?.viewOptions?.compactView
    const [opened, setOpened] = useState(isCompact)
    const isWM = Morpheus.getParams("parts")?.templates?.articleItem?.bundle == "wm"
    const showAdditionalPrices = Morpheus.getParams("parts")?.showAdditionalPrices

    const { repairTimeProviders } = getRepairTimeProviders()

    const dispatch = useDispatch()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({ filters: s.list.filters, selectedFilters: s.list.selectedFilters }))

    useEffect(() => {
        if (opened != isCompact)
            setOpened(isCompact)
    }, [isCompact])

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && repairTimeProviders.length && user?.userSettings) {
            let provider
            if (Array.isArray(rtProviders)) {
                let activeRTProvider = user?.userSettings.activeVehicleDataProviders.repairTimes
                provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
            }
            else
                provider = getRepairTimesProviderStringByEnum(rtProviders)

            if (!provider)
                return

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide
                })
            )
        }
    }

    const handleToggleOpened = () => setOpened(!opened)

    const isSelected = (attribute: ArticleAttribute | undefined) => {
        return selectedArticleAttributes?.some(selAtr => {
            const [id, key] = selAtr.split("|")
            return attribute?.id.toString() == id && attribute.value == key
        })
    }

    const renderIcons = () => {
        const allAttr = [...item.attributes?.[0]?.topAttributes, ...item.attributes?.[0]?.articleAttributes]
        const fuelEfficiency = allAttr.find(x => x.group == EFilterNames.fuelEfficiency)
        const wetGripClass = allAttr.find(x => x.group == EFilterNames.wetGripClass)
        const externalRolling = allAttr.find(x => x.group == EFilterNames.externalRolling)

        let items: (TyreArticleAttribute & { selected?: boolean })[] = []
        if (fuelEfficiency)
            items.push({ ...fuelEfficiency, selected: isSelected(fuelEfficiency) })
        if (wetGripClass)
            items.push({ ...wetGripClass, selected: isSelected(wetGripClass) })
        if (externalRolling)
            items.push({ ...externalRolling, selected: isSelected(externalRolling) })

        return (
            <>
                <TiresIcons
                    onSelect={(attr) => onArticleAttributeSelect(item, attr)}
                    items={items}
                />
            </>
        )
    }

    const renderSeasonIcon = (className: string) => {
        return (
            <Tooltip content={translateText(getTitleByGroup(item.productGroup.season))}>
                <Icon name={getIconByGroup(item.productGroup.season)} size="l" className={className} />
            </Tooltip>
        )
    }

    const renderSupplierWithSeason = () => {
        if (isWM)
            return (
                <div className={style.sup_wrapper}>
                    {renderIcons()}
                    {renderSeasonIcon(style.iconPadding)}
                </div>
            )

        return (
            <>
                {renderSeasonIcon(style.iconDefaultMargin)}
            </>
        )
    }

    const renderStandAloneButtons = (item: TyreArticle, showButtonText?: boolean) => {
        return (
            <div className="standalone-basket-elements">
                <AmountField
                    className="add-to-basket__quantity"
                    value={item.quantity}
                    onChange={amount => onChangeStandaloneQuantity(item, amount.value)}
                />

                {systemType == SystemType.Redesign && renderMicro!("standalone", "rd-add-articles-to-basket", {
                    items: [item],
                    buttonText: showButtonText ? translateText(133) : undefined,
                    sourceId: "TM_TYRES"
                })}

                {systemType == SystemType.Smartclient && renderMicro!("standalone", "sc-add-articles-to-basket", {
                    items: [item],
                    buttonText: showButtonText ? translateText(133) : undefined,
                    sourceId: "TM_TYRES"
                })}

                {vehicle && renderMicro!("standalone", "open-rt-modal", {
                    repairTimesRoute,
                    items: [item],
                    repairTimeProviders,
                    sourceId: "TM_TYRES"
                })}
            </div>
        )
    }

    const handleShowArticleFeedback = (article: TyreArticle) => {
        const articleFeedbackPath = Morpheus.getParams("parts")?.articleFeedbackPath
        if (articleFeedbackPath) {
            const url = renderRoute(articleFeedbackPath, {
                ...match.params,
                productGroupId: article.productGroup.id,
                supplierId: article.supplier.id,
                supplierName: article.supplier.name,
                supplierArticleNo: article.supplierArticleNo,
                itemId: article.id,
                quantityValue: article.quantity || 1,
                productGroupName: article.productGroup.name,
                traderArticleNo: article.traderArticleNo || undefined,
            }) + createQueryString({ articleDescription: article.description })
            Morpheus.showView("1", url)
        }
    }
    const handleDetailsButtonClick = () => {
        onArticleDetailsClick(item,"stocks")
    }

    const renderCustomErp = () => {
        return (
            <>
                {showAdditionalPrices &&   renderMicro!("erp", "erp-additional-prices", { data: item }) }

                {renderMicro("erp", "erp-info", {
                    data: item,
                    onClick: handleDetailsButtonClick,
                })}
            </>
        )
    }

    const manufacturerIndex = filters.manufacturer.findIndex((x) => x.value === item.supplier.name)

    const handleManufacturerClick = (value: TyreFilter) => {
        batch(() => {
			dispatch(MainActions.updateAttributeFilter(EFilterNames.manufacturer, value))
			dispatch(MainActions.loadTiresList(undefined, true))
		})
    }

    const workTaskId = useWorkTask()?.workTaskId
    return <>
        {renderMicro("parts", "part-item", {
            className: bem(style.articleItem, hidden && "hidden", isWM && "wm"),
            part: item,
            vehicleId: vehicleId,
            isCompact: opened,
            renderCustomIcons: renderIcons,
            renderCustomSeason: renderSupplierWithSeason,
            renderCustomErpInformation: renderCustomErp,
            onToggleCollapse: handleToggleOpened,
            rtProviders: vehicle && providers,
            showActions: true,
            showArticleImage: showArticleImages,
            canFilterArticleAttributes: true,
            selectedArticleAttributes,
            onRequestArticleDetails: (r) => onArticleDetailsClick(item,r?.subPage),
            onArticleAttributeSelect: onArticleAttributeSelect,
            renderBuyActions: systemType != SystemType.Next && renderStandAloneButtons || undefined,
            getRepairTimesUrl,
            workTaskId,
            onShowArticleFeedback: handleShowArticleFeedback,
            ignoreAttributeKey: true,
            isDataSupplierFilterActive: !!selectedFilters?.manufacturer?.length,
            onDataSupplierClick: () => handleManufacturerClick(filters.manufacturer[manufacturerIndex]),
        })}
    </>
}

const style = useStyle({
    articleItem: {
        $nest: {
            ".article__cell--supplier .supplier__name ": {
                width: em(6)
            },
            ".article__cell--supplier .article__supplier-logo": {
                maxHeight: rem(2),
                maxWidth: rem(5)
            },
            "&--wm .article__cell--supplier ": {
                flexDirection: "column-reverse"
            },
            ".article__cell--numbers": {
                width: em(10)
            },
            "&--wm .article__cell--numbers .article__numbers": {
                width: "auto"
            },
            "&--hidden": {
                display: "none"
            }
        }
    },
    sup_wrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: em(1)
    },
    iconPadding: {
        paddingLeft: em(.2)
    },
    iconDefaultMargin: {
        marginRight: em(1)
    }
})(ArticleItem)

export default memo(withRouter(ArticleItem))
