import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ErpPriceDictionary, PriceType } from "@tm/models"
import { Typography } from "@tm/components"
import { LoaderSmall, StackRow } from "../../StyledComponents"
import ErrorButton from "../../_shared/ErrorButton"

type Props = {
    loading: boolean
    numberOfPositionsInOrder?: number
    numberOfItemsInOrder?: number
    grossTotalsInOrder?: ErpPriceDictionary
    workTaskIds: string[]
    hasErrors: boolean
}

export default function Totals({ loading, numberOfItemsInOrder, numberOfPositionsInOrder, grossTotalsInOrder, workTaskIds, hasErrors }: Props) {
    const { translate, currency } = useLocalization()
    const { userSettings } = useUser() || {}

    const totalPrice = useMemo(() => {
        const calculatedPrices = grossTotalsInOrder ? Object.values(grossTotalsInOrder) : []
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice = calculatedPrices.find((price) => price?.type === PriceType.Retail)
        let price = purchasePrice ? currency(purchasePrice.value, purchasePrice.currencyCode_Iso_4217 || purchasePrice.currencySymbol) : "-"
        if (userSettings && !userSettings.showPurchasePrice) {
            price = retailPrice ? currency(retailPrice.value, retailPrice.currencyCode_Iso_4217 || retailPrice.currencySymbol) : "-"
        }
        return price
    }, [currency, grossTotalsInOrder, userSettings])

    return (
        <StackRow spacing={4}>
            <Typography variant="body2">
                {numberOfPositionsInOrder ?? ""} {translate(479)}, {numberOfItemsInOrder ?? ""} {translate(90)}
            </Typography>
            <StackRow gap={1}>
                {(loading || loading) && <LoaderSmall />}
                {!loading && (
                    <>
                        <Typography color="highlight">{totalPrice}</Typography>
                        <Typography variant="label">{` *${translate(694)}`}</Typography>
                        {hasErrors && <ErrorButton basketWithErrors workTaskIds={workTaskIds} additionalErrorTextId={13663} />}
                    </>
                )}
            </StackRow>
        </StackRow>
    )
}
