import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { getStyleTheme, useUser } from "@tm/context-distribution"
import { concat } from "@tm/utils"
import { RealTable, Text, Tooltip, Button, Checkbox } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { ArticleNumber, ArticleNumberType, Stack } from "@tm/components"
import { DMS } from "@tm/models"

export function getComponentStyles(): NestedCSSProperties {
    const { margin, font, opacity } = getStyleTheme()

    return {
        $nest: {
            "&__table": {
                borderSpacing: "0 2px" /** @todo margin.xxs */,
                $nest: {
                    ".real-table__head": {
                        fontSize: font.textSize.m,
                    },
                    ".real-table__cell": {
                        padding: `${margin.s} ${margin.m}`,
                        $nest: {
                            "&:first-child": {
                                paddingLeft: margin.m,
                                borderLeftWidth: "1px",
                            },
                            "&:last-child": {
                                paddingRight: margin.m,
                            },
                        },
                    },
                    ".real-table__row:not(:hover):not(.is-selected) .real-table__cell:first-child": {
                        borderLeftColor: "transparent",
                    },
                },
            },
            "&__item": {},
            "&__label": {
                marginBottom: margin.s,
                textTransform: "uppercase",
            },
            "&__column": {
                wordBreak: "break-word",
                $nest: {
                    "&--align-right": {
                        textAlign: "right",
                    },
                    "&--nowrap": {
                        whiteSpace: "nowrap",
                    },
                },
            },
            "&__cell": {
                position: "relative",
                $nest: {
                    "&:not(:last-child)::after": {
                        content: '""',
                        position: "absolute",
                        top: "-1px",
                        right: "0",
                        width: "2px",
                        height: "15px",
                        opacity: opacity.disabled,
                        backgroundColor: "#979797" /** @todo color? */,
                    },
                },
            },
            "&__actions": {
                display: "inline-flex",
                alignItems: "center",
            },
            "&__button--basket": {
                width: "6em",
                $nest: {
                    ".icon": {
                        margin: "0 auto",
                    },
                },
            },
            "&__checkbox": {
                marginLeft: margin.m,
            },
        },
    }
}

type Props = {
    parts: Array<DMS.Part>
    selectedParts?: Array<DMS.Part>
    onToggleSelectPart?(part: DMS.Part): void
    onAddPartToBasket?(part: DMS.Part): void
}

export function PartsTable(props: Props) {
    const className = style(getComponentStyles())
    const { translate, translateText, currency } = useLocalization()
    const userContext = useUser()?.userContext

    const getPrice = (part: DMS.Part, type: DMS.PriceType) => {
        return part.prices.find((x) => x.type === type)
    }

    const renderSupplier = (part: DMS.Part) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{part.dataSupplier?.description}</Text>
            </RealTable.Cell>
        )
    }

    const renderNumbers = (part: DMS.Part) => {
        let { wholesalerArticleId } = part
        let articleNumberType: ArticleNumberType = "wholesaler"
        if (!wholesalerArticleId) {
            wholesalerArticleId = part.additionalArticleIds?.find((x) => x?.type === DMS.AdditionalArticleIdType.DMS)?.id
            articleNumberType = "linked"
        }

        // Remove placeholder/fallback value
        if (wholesalerArticleId === "0") {
            wholesalerArticleId = undefined
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Stack
                    alignItems="flex-start"
                    direction={userContext?.parameter.positionChangeEArtNrHArtNr && articleNumberType === "wholesaler" ? "column-reverse" : "column"}
                >
                    {(!userContext?.parameter.hideDealerPartNumber || articleNumberType === "wholesaler") && wholesalerArticleId && (
                        <ArticleNumber
                            articleNumber={wholesalerArticleId}
                            articleNumberType={articleNumberType}
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                        />
                    )}
                    <ArticleNumber
                        articleNumber={part.dataSupplierArticleId}
                        articleNumberType="supplier"
                        omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    />
                </Stack>
            </RealTable.Cell>
        )
    }

    const renderDescription = (part: DMS.Part) => {
        const productGroupDescriptions = part.productGroups?.map((x) => x.description) || []
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{concat(" - ", concat(" / ", ...productGroupDescriptions), part.description)}</Text>
            </RealTable.Cell>
        )
    }

    const renderPrice = (part: DMS.Part) => {
        const retailPrice = getPrice(part, DMS.PriceType.Retail)

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="xs" modifiers={["sub", "block"]} className={`${className}__label`}>
                    {retailPrice?.description || translateText(1620)}
                </Text>
                <Text modifiers="highlight">{retailPrice ? currency(retailPrice.value ?? 0, retailPrice.currencySymbol) : "-"}</Text>
            </RealTable.Cell>
        )
    }

    const renderQuantity = (part: DMS.Part) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="xs" modifiers={["sub", "block"]} className={`${className}__label`}>
                    {translate(89)}
                </Text>
                <Text>{part.quantity}</Text>
            </RealTable.Cell>
        )
    }

    const renderTotalSum = (part: DMS.Part) => {
        const retailPrice = getPrice(part, DMS.PriceType.Retail)

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="xs" modifiers={["sub", "block"]} className={`${className}__label`}>
                    {translate(57)}
                </Text>
                <Text>{retailPrice ? currency((retailPrice.value ?? 0) * (part.quantity ?? 0), retailPrice.currencySymbol) : "-"}</Text>
            </RealTable.Cell>
        )
    }

    const renderActions = (part: DMS.Part) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {props.onAddPartToBasket && (
                        <Tooltip content={translateText(937)}>
                            <Button
                                icon="cart"
                                skin="highlight"
                                layout={["bordered"]}
                                className={`${className}__button--basket addToBasketButton`}
                                onClick={() => props.onAddPartToBasket?.(part)}
                            />
                        </Tooltip>
                    )}
                    {props.onToggleSelectPart && (
                        <Tooltip content={!(props.selectedParts ?? []).includes(part) ? translateText(1937) : translateText(1938)}>
                            <div className={`${className}__checkbox`}>
                                <Checkbox checked={(props.selectedParts ?? []).includes(part)} onToggle={() => props.onToggleSelectPart?.(part)} />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        return [
            <RealTable.Column key="supplier" renderItemContent={renderSupplier} className={`${className}__column`} />,
            <RealTable.Column key="numbers" renderItemContent={renderNumbers} className={`${className}__column`} />,
            <RealTable.Column key="description" renderItemContent={renderDescription} className={`${className}__column`} />,
            <RealTable.Column
                key="price"
                renderItemContent={renderPrice}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
            <RealTable.Column
                key="quantity"
                renderItemContent={renderQuantity}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
            <RealTable.Column
                key="totalSum"
                renderItemContent={renderTotalSum}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    const getRowClassName = (part: DMS.Part, rowIndex: number) => {
        return concat(
            " ",
            `${className}__item`,
            rowIndex % 2 === 0 ? "real-table__row--light" : undefined,
            (props.selectedParts ?? []).includes(part) ? "is-selected" : undefined
        )
    }

    return <RealTable data={props.parts} columns={getTableColumns()} className={`${className}__table`} getRowClassName={getRowClassName} />
}
