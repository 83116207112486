import { VeFiles } from "@tm/data"

export function getIcon(fileKind: VeFiles.FileKind) {
    switch (fileKind) {
        case VeFiles.FileKind.Document:
            return "document"
        case VeFiles.FileKind.Image:
            return "pictures"
        default:
            return "document"
    }
}
