import { useState, useCallback, useEffect } from "react"
import { useUser } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Badge, Box, Loader, Switch } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"

const PurchasePriceSwitchSettingsWrapper = () => {
    const user = useUser()
    const [isChecked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(user.userSettings?.showPurchasePrice ?? false)
    }, [user.userSettings?.showPurchasePrice])

    const canShowSwitch = useCallback((): boolean => {
        const externalModules = user?.userContext?.externalModules
        let isInfoEnabled = false
        if (externalModules) {
            externalModules.forEach((externalModule) => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                }
            })
        }

        const shouldRender = !(
            user.userSettings === undefined ||
            user?.userContext?.parameter.purchasePricesDisabled ||
            user.userSettings.showPurchasePrice === undefined
        )

        return shouldRender && isInfoEnabled
    }, [user?.userContext?.externalModules, user?.userSettings?.showPurchasePrice, user?.userContext?.parameter.purchasePricesDisabled])

    if (!canShowSwitch()) {
        return null
    }

    const handleToggle = (value: boolean) => {
        user.setUserSetting?.("SHOW_PURCHASE_PRICE", value)
    }

    return <PurchasePriceSwitch isChecked={isChecked} onToggle={handleToggle} />
}

type Props = {
    isChecked: boolean
    onToggle: (value: boolean) => void
}

const PurchasePriceSwitch = ({ isChecked, onToggle }: Props) => {
    const { translateText } = useLocalization()

    const [loading, setLoading] = useState(false)

    const handleToggle = (value: boolean) => {
        setLoading(true)
        onToggle(value)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowInvoicePrice, !value)
    }

    const listener = useCallback(
        (e: KeyboardEvent) => {
            if (e.altKey && e.key === "0") {
                e.preventDefault()
                setLoading(true)
                onToggle(!isChecked)
            }
        },
        [isChecked]
    )

    useEffect(() => {
        document.addEventListener("keydown", listener)
        return () => document.removeEventListener("keydown", listener)
    }, [listener])

    useEffect(() => {
        setLoading(false)
    }, [isChecked])

    return (
        <Box sx={{ position: "relative" }}>
            <Toolbar className="purchase-price-switch" title={translateText(1132)}>
                {!loading && <Switch checked={isChecked} onChange={(e) => handleToggle(e.target.checked)} />}
                {loading && <Badge badgeContent={<Loader size="extrasmall" />} sx={{ top: "0em", left: "1.5em" }} />}
            </Toolbar>
        </Box>
    )
}

export default PurchasePriceSwitchSettingsWrapper
