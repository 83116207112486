import { useCallback, useState } from "react"
import { GlobalNavigationItem, Menu, MenuItem, NavigationElementProps, NavigationIconProps, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useHistory, useRouteMatch } from "react-router"
import { useLocalization } from "@tm/localization"
import { Container } from "@tm/nexus"
import { PortalContainer } from "@tm/models"
import { MenuItems } from "./components/MenuItems"
import { AdditionalMenuItem, AdditionalMenuItems } from "./components/AdditionalMenuItems"
import { AdditionalMdmMenuItems } from "./components/AdditionalMdmMenuItems"

type Props = {
    url: string
    hideDefaultMenu: boolean
    additionalItems: AdditionalMenuItem[]
    showUserName?: boolean
}

export function UserSettingsMenuButton(props: Props) {
    const { url, additionalItems, hideDefaultMenu } = props
    const { userContext } = useUser()
    const { translateText } = useLocalization()
    const history = useHistory()
    const match = useRouteMatch<{ url: string; workTaskId?: string }>()

    const [anchorEl, setAnchorEl] = useState<HTMLElement>()

    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (match.url === url) {
            history.goBack()
        } else {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = useCallback(() => {
        setAnchorEl(undefined)
    }, [])

    const handleLogout = useCallback(() => {
        const container = Container.getInstance("Portal") as PortalContainer
        container.action("logout")(true, true, false)

        handleClose()
    }, [handleClose])
    const elementProps: NavigationElementProps | NavigationIconProps = props.showUserName
        ? {
            componentType: "element",
            children: userContext.account?.username ?? "-",
        }
        : {
            componentType: "icon",
            name: "user-settings",
        }
    return (
        <>
            <GlobalNavigationItem
                tooltip={translateText(141)}
                onIconClick={handleButtonClick}
                className="user-settings"
                active={match.url === props.url}
                {...elementProps}
            />

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {!hideDefaultMenu && <MenuItems onClick={handleClose} />}
                {/* entrys over uiconfig file */}
                {additionalItems?.length && <AdditionalMenuItems items={additionalItems} onClick={handleClose} />}
                {/* entrys over the displaysettings at externalModules =>  https://jira.dvse.de/browse/NEXT-25423 and https://jira.dvse.de/browse/MDM-5124 */}
                <AdditionalMdmMenuItems onClick={handleClose} />
                <MenuItem onClick={handleLogout}>{translateText(367)}</MenuItem>
            </Menu>
        </>
    )
}
