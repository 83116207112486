import { useCallback, useMemo } from "react"
import { ArticleListSortingMode } from "@tm/models"
import { Box, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { ArticleSelection } from "../components/ArticleSelection"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useVehiclePartsModuleState } from "./VehiclePartsModuleState"
import { getBundleParams } from "../../../utils"

export function VehiclePartsList() {
    const { translateText } = useLocalization()
    const noResult = useVehiclePartsModuleState((x) => x.params.noResult)
    const isFiltersLoading = useVehiclePartsModuleState((x) => x.params.isFiltersLoading)
    const attributes = useVehiclePartsModuleState((x) => x.params.attributes)
    const toggleAttribute = useVehiclePartsModuleState((x) => x.params.toggleAttribute)
    const setAttributes = useVehiclePartsModuleState((x) => x.params.setAttributes)
    const sortingProductGroups = useVehiclePartsModuleState((x) => x.params.sorting.productGroups)
    const activateSortingForProductGroups = useVehiclePartsModuleState((x) => x.params.sorting.setForProductGroups)
    const { articleGroups, isLoading, isLoaded, isFetchingNextPage, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useVehiclePartsModuleState((x) => x.articles)
    const productGroupTopicIds = useVehiclePartsModuleState((x) => x.productGroupTopicIds)

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const calculatorRoute = getBundleParams().fastCalculatorModalRoute
    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const showPageLoader = ((hasNextPage || isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || isFetchingNextPage

    if (noResult) {
        return <NoSearchResult searchType="vehicle" />
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection maxArticleNumberToCompareReached showArticleComparision listType="vehicle" />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={sortingProductGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                        calculatorRoute={calculatorRoute}
                        showFilterOptions={showVehicleRecordsFilters}
                        usedAttributeFilters={usedAttributeFilters}
                        onToggleAttributeFilter={toggleAttribute}
                        onResetAttributeFilters={handleResetAttributeFilters}
                        productGroupTopicIds={productGroupTopicIds}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
