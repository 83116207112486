import { Suspense } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { BonusPoints, Box, Icon, Stack, Typography } from "@tm/components"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { LoaderSmall, StackRow } from "../StyledComponents"
import ErrorButton from "../_shared/ErrorButton"

type Props = {
    /** @default "default" */
    displayMode?: "default" | "default-with-bonus" | "flat"
    hideExcludingTaxesText?: boolean // TODO: This is just a temporal fix for the live update, Ivan Pentiev will create an MDM parameter after the PMs agree. <-- Haha sure. 4 month after still existing ...
}

export function TotalsComponent(props: Props & { workTaskId: string }) {
    const { displayMode, hideExcludingTaxesText, workTaskId } = props
    const { translate, currency, translateText } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const { workTaskBasketCalculationLoading, workTaskBasketCalculation, basket } = useWorkTaskBasketState(workTaskId)
    const { bonusPointsCalculation, bonusPointsLoading, basketWithErrors, basketErpInfosIndicator } = basket.state
    const showPurchasePrice = userSettings?.showPurchasePrice ?? true

    function renderHeader() {
        return (
            <Typography variant="label">
                {translate(57)} {translate(!showPurchasePrice ? 1620 : 55)}*{!hideExcludingTaxesText && `(${translate(694)})`}
            </Typography>
        )
    }

    function renderTotalPrice() {
        if (!workTaskBasketCalculation?.calculatedOrder?.netTotalsInOrder) {
            return null
        }

        const calculatedPrices = Object.values(workTaskBasketCalculation.calculatedOrder.netTotalsInOrder)
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice = calculatedPrices.find((price) => price?.type === PriceType.Retail)
        let totalPrice = purchasePrice ? currency(purchasePrice.value, purchasePrice.currencyCode_Iso_4217 || purchasePrice.currencySymbol) : "-"

        if (!showPurchasePrice) {
            totalPrice = retailPrice ? currency(retailPrice.value, retailPrice.currencyCode_Iso_4217 || retailPrice.currencySymbol) : "-"
        }

        return (
            <StackRow gap={1}>
                <Typography color={showPurchasePrice ? "primary" : undefined}>{totalPrice}</Typography>
                {basketWithErrors && (
                    <ErrorButton
                        basketWithErrors
                        workTaskIds={[workTaskId]}
                        additionalErrorTextId={basketErpInfosIndicator.hasErrors ? 13663 : undefined}
                    />
                )}
            </StackRow>
        )
    }

    function renderAdditionalInfo() {
        return <Typography variant="label">*{translate(802)}</Typography>
    }

    function renderNumbers() {
        const numberOfPositions = workTaskBasketCalculation?.calculatedOrder?.numberOfPositionsInOrder || 0
        const numberOfParts = workTaskBasketCalculation?.calculatedOrder?.numberOfItemsInOrder || 0

        return (
            <Typography variant="label">
                {numberOfPositions} {translate(479)}, {numberOfParts} {translate(90)}
            </Typography>
        )
    }

    // Used by promofficina
    function renderBonusPoints() {
        const bonusPoints = bonusPointsCalculation?.bonusPointTotalsInOrder
        if (!bonusPoints) {
            return
        }

        return Object.keys(bonusPoints).map((key, idx) => {
            return (
                <Box alignSelf="flex-start" key={idx}>
                    <BonusPoints
                        bonusSystem={{
                            id: key,
                            imageUrl: "",
                            value: bonusPoints[key] || 0,
                            name: translateText(13114),
                        }}
                        label={translateText(1826)}
                        variant="single-line-label"
                    />
                </Box>
            )
        })
    }

    // Used bei STG, Neimcke, PV, Rhiagch
    function renderFlatContent() {
        return (
            <Stack direction="row" alignItems="center">
                {renderTotalPrice()}
                {renderAdditionalInfo()}
            </Stack>
        )
    }

    function renderDefaultContent() {
        return (
            <>
                {renderTotalPrice()}
                {displayMode === "default-with-bonus" && renderBonusPoints()}
                {renderNumbers()}
                {renderAdditionalInfo()}
            </>
        )
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Icon name="cart" />
            <Stack>
                {renderHeader()}
                {(workTaskBasketCalculationLoading || bonusPointsLoading) && <LoaderSmall />}
                {displayMode === "flat" ? renderFlatContent() : renderDefaultContent()}
            </Stack>
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    if (!workTaskId) {
        return null
    }
    return (
        <Suspense fallback={<LoaderSmall />}>
            <TotalsComponent {...props} workTaskId={workTaskId} />
        </Suspense>
    )
}
