import { CellContentPosition, Icon, LoadingContainer, Table, TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VeFiles } from "@tm/data"
import { formatDate } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { useFileList } from "../../hooks/useFileList"
import { useVefilesSearch } from "../../hooks/useVefilesSearch"
import { getFileCategory, getIcon } from "../../helpers"
import { FileItemActions } from "./FileItemActions"

export function FileList() {
    const { searchQuery } = useVefilesSearch()
    const { vehicle } = useWorkTask()?.workTask || {}
    const { fileList, isLoading, fetchNextPage } = useFileList(vehicle?.id, searchQuery)
    const { translateText } = useLocalization()

    function onRowClick() {}

    const tableColumns: TableColumnData[] = [
        {
            alignContent: CellContentPosition.left,
            gridSize: "auto",
        },
        {
            header: translateText(155).toLocaleUpperCase(),
            gridSize: "2fr",
        },
        {
            header: translateText(158).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            header: translateText(98).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            header: translateText(712).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            alignContent: CellContentPosition.right,
            gridSize: "3fr",
        },
    ]

    const getCells = (file: VeFiles.FileMeta) => {
        const cellData: TableCellData[] = [
            { displayValue: <Icon name={getIcon(file.kind)} />, id: "icon" },
            { displayValue: file.displayName, id: "name" },
            { displayValue: getFileCategory(file), id: "category" },
            { displayValue: formatDate(new Date(file.createdAt), "dd.MM.yyyy, hh:mm"), id: "date_created" },
            { displayValue: `${Math.floor(file.fileSize / 1024)} KB`, id: "fileSize" },
            {
                displayValue: <FileItemActions file={file} />,
                id: "details",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] =
        fileList?.map((file: VeFiles.FileMeta) => ({
            cells: getCells(file),
            id: `${file.id}`,
            customRow: false,
            active: false,
            leftRowIndicatorColor: "#2196f3",
            rowIndicatorWidth: 7,
            borderOnlyOnTheLeft: true,
        })) ?? []

    return (
        <LoadingContainer isLoading={isLoading} display="flex" flexDirection="column" gap="0.5em" flex="1" overflow="auto">
            <Table
                columns={tableColumns}
                rows={displayData}
                headerBackground="#efefef"
                fontSize={14}
                headerStyle="default"
                rowCap={1}
                overflowY="auto"
                rowStyle="uniform"
                onRowClick={onRowClick}
                onScrollBottom={fetchNextPage}
            />
        </LoadingContainer>
    )
}
