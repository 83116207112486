import { Article, ArticleListType, ErpInfoData, TraderErpInfosData } from "@tm/models"
import { notUndefinedOrNull, useErpConfig } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilValue } from "recoil"
import { ErpInfosState, tradeReferenceNumbersState } from "../../states"
import { ArticleErpInfoFactory, LoadErpInfoItem, useLoadErpInfos } from "../useErpInfos"
import { TradeReferenceNumber } from "../useTradeReferences"

export function useTraderErpInfos(article: Article, listType: ArticleListType, tradeReferences?: TradeReferenceNumber[]): TraderErpInfosData {
    const { erpSystemConfigs } = useErpConfig()
    const erpInfo = useRecoilValue(ErpInfosState(listType))
    const [enabled, setEnabled] = useState(false)
    const loadErpInfos = useLoadErpInfos(listType)
    const tradeReferenceNumbers = useRecoilValue(tradeReferenceNumbersState)
    const loadTraderErpInfos = useCallback(() => setEnabled(true), [])

    useEffect(() => {
        if (!enabled || !tradeReferences?.length || !erpSystemConfigs) {
            return
        }

        const loadErpInfoItems = tradeReferences
            .map<LoadErpInfoItem | undefined>((tradeReference) => {
                const [_, articleTraderReferenceNumbers] =
                    tradeReferenceNumbers.find(
                        ([key]) => key.supplierArticleNo === article.supplierArticleNo && key.supplierId === article.supplier.id
                    ) ?? []
                const { traderArticleNumber } =
                    articleTraderReferenceNumbers?.find((num) => num.tradeReferenceId === tradeReference.tradeReferenceId) ?? {}

                const erpSystem = erpSystemConfigs.find((erpSystemConfig) => erpSystemConfig.tradeReferenceId === tradeReference.tradeReferenceId)
                if (!erpSystem || !traderArticleNumber) {
                    return
                }

                return { article: { ...article, traderArticleNo: traderArticleNumber }, erpSystem }
            })
            .filter(notUndefinedOrNull)

        loadErpInfos(loadErpInfoItems)
    }, [tradeReferences, erpSystemConfigs, article, enabled, loadErpInfos, tradeReferenceNumbers])

    const traderErpInfos = useMemo(() => {
        if (!tradeReferences?.length || !erpSystemConfigs?.length) {
            return []
        }

        const result = erpSystemConfigs
            .map<ErpInfoData | undefined>((erpSystem) => {
                const key = ArticleErpInfoFactory.createKeyFromArticle(article, erpSystem.id)
                const traderArticleNumber = tradeReferences.find((x) => x.tradeReferenceId === erpSystem.tradeReferenceId)?.traderArticleNumber
                const articleErpInfo = erpInfo.find((x) => x.key.id === key.id)

                if (!articleErpInfo || !traderArticleNumber) {
                    return
                }

                return {
                    erpSystem,
                    traderArticleNumber,
                    erpInformation: articleErpInfo.state === "success" ? articleErpInfo.response : undefined,
                    isLoading: articleErpInfo.state === "loading",
                }
            })
            .filter(notUndefinedOrNull)

        return result
    }, [tradeReferences, erpSystemConfigs, article, erpInfo])

    return useMemo(
        () => ({
            loadTraderErpInfos,
            traderErpInfos,
            isLoading: traderErpInfos.some((x) => x.isLoading),
        }),
        [loadTraderErpInfos, traderErpInfos]
    )
}
