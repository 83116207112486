import { VehicleType } from "@tm/models"
import { useAvailableVehicleSearchOptionsRegNoTypes } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useCallback, useMemo } from "react"
import { useSelectedVehicleLookup } from "../../../data/hooks"
import {
    getBikeSearchPlaceholder,
    getBikeSearchTooltip,
    getCommercialSearchPlaceholder,
    getCommercialSearchTooltip,
    getVehicleSearchPlaceholder,
    getVehicleSearchTooltip,
    searchType,
} from "../../../helpers"
import { useSelectedSimpleLookupConfig } from "../../../data/hooks/useSelectedSimpleLookupConfig"
import { getBundleParams } from "../../../utils"

export function useVehicleSearch(vehicleType: VehicleType, placeholderPassengerCar?: string) {
    const availableRegNoTypes = useAvailableVehicleSearchOptionsRegNoTypes()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)
    const { translateText } = useLocalization()
    const { simpleVehicleSearch } = getBundleParams()

    const { selectedConfig: selectedSimpleLookupConfig } = useSelectedSimpleLookupConfig()

    const regNoTypes = useMemo(() => {
        const selectedRegNoType = simpleVehicleSearch ? selectedSimpleLookupConfig?.lookupId : selectedVehicleLookup?.lookupTypeId
        return selectedRegNoType !== undefined ? [selectedRegNoType] : availableRegNoTypes
    }, [selectedVehicleLookup, availableRegNoTypes, simpleVehicleSearch, selectedSimpleLookupConfig])

    const searchPlaceholder = useMemo(() => {
        switch (vehicleType) {
            case VehicleType.PassengerCar:
                const translationRegex = /\{\{(.*?)\}\}/
                const placeholder = placeholderPassengerCar?.replace(translationRegex, (s, num) => translateText(num))
                return placeholderPassengerCar ? placeholder : getVehicleSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.Motorcycle:
                return getBikeSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchPlaceholder(regNoTypes, translateText)
            default:
                return undefined
        }
    }, [vehicleType, placeholderPassengerCar, regNoTypes, translateText])

    const tooltipMessage = useMemo(() => {
        switch (vehicleType) {
            case VehicleType.PassengerCar:
                return getVehicleSearchTooltip(regNoTypes, translateText, searchType.VEHICLE_TYPE)
            case VehicleType.Motorcycle:
                return getBikeSearchTooltip(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchTooltip(regNoTypes, translateText)
            default:
                return undefined
        }
    }, [regNoTypes, vehicleType, translateText])

    const getGroupHeaderName = useCallback(
        (headerKey: string) => {
            switch (headerKey) {
                case "CUSTOMER":
                    return translateText(12504)
                case "VEHICLE":
                    return translateText(13733)
                case "REGNO":
                    return translateText(13734)
                default:
                    return undefined
            }
        },
        [translateText]
    )

    return { getGroupHeaderName, tooltipMessage, searchPlaceholder }
}
