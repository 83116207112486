import { PropsWithChildren, useCallback, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { ArticleListType } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { ArticleListActions, ProductGroupsModuleState } from "../models"
import { useListOptions } from "../hooks/useListOptions"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { useErpInfos } from "../hooks/useErpInfos"
import { useWatchListData } from "../hooks/useWatchListData"
import { useProductGroupRepairTimes } from "../hooks/useProductGroupRepairTimes"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useProductGroupTopicIds } from "../hooks/useProductGroupTopicIds"
import { useVehicle } from "../hooks/useVehicle"
import { useListParams } from "./hooks/useListParams"
import { useFilters } from "./hooks/useFilters"
import { useArticles } from "./hooks/useArticles"
import { usePreviouslyOrderedArticles } from "../hooks/usePreviouslyOrderedArticles"

export function ProductGroupsModuleStateProvider(props: PropsWithChildren<unknown>) {
    const type: ArticleListType = "default"
    const [isEnabled, setIsEnabled] = useState(false)

    const { workTaskId } = useWorkTask() ?? {}
    const vehicle = useVehicle()
    const options = useListOptions("direct")
    const params = useListParams()
    const filtersData = useFilters(vehicle, params, isEnabled, true)
    const articlesData = useArticles(vehicle, params, isEnabled)
    const { productGroupTopicIds } = useProductGroupTopicIds(articlesData.productGroupIds, isEnabled)
    const tradeReferences = useTradeReferences(articlesData.articles, isEnabled)
    const erpIsReady = useErpInfos(type, articlesData.articles, isEnabled, params.startParams, tradeReferences)
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, options.hasWatchList && isEnabled && erpIsReady)
    const productGroupRepairTimesData = useProductGroupRepairTimes(articlesData.productGroupIds, isEnabled && erpIsReady)
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpIsReady)
    const notes = useNotes(articlesData.articles, false, isEnabled && erpIsReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(articlesData.articles)

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogosData.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimesData.isLoading,
        activeVehicleDataProvidersData.isLoading
    )

    const state: ProductGroupsModuleState = {
        type,
        params,
        start: useCallback(() => setIsEnabled(true), []),
        options,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimesData : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProvidersData.activeProviders : undefined,
        basketQuantities,
        productGroupTopicIds,
        notes,
        previouslyOrderedArticles,
        tradeReferences,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{props.children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useProductGroupsModuleState(): ProductGroupsModuleState
export function useProductGroupsModuleState<TReturnType>(selector: (value: ProductGroupsModuleState) => TReturnType): TReturnType
export function useProductGroupsModuleState<TReturnType>(
    selector?: (value: ProductGroupsModuleState) => TReturnType
): ProductGroupsModuleState | TReturnType {
    return usePartsModuleState<ProductGroupsModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
