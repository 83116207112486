import { useState, useEffect } from "react"
import { Popover, Toolbar, DateField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, uniqueId, useKeyValue } from "@tm/utils"
import { CisVoucherType } from "@tm/models"
import { Typography, Button, Switch, Icon, Stack, Box, SearchButton } from "@tm/components"
import LastOrders from "./components/LastOrders"
import { useCentralOrderWorkTasks } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { bundleChannel } from "../../business"
import { useGlobalOrderOptionsState } from "../../hooks/useGlobalOrderOptionsState"
import { BoxWithAlignContent } from "../StyledComponents"

type Props = {
    lastOrdersTextId?: number
    ordersUrl?: string // Could be cis or vouchers-orders.
    voucherTypeId?: CisVoucherType
    userWorkTasksEnabled?: boolean
}

const generatedWorktaskId = encodeUniqueId(uniqueId())
const today = new Date()
const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

export default function CentralOrderNavigationComponent(props: Props) {
    const { translateText } = useLocalization()
    const { ordersUrl, lastOrdersTextId, voucherTypeId, userWorkTasksEnabled } = props

    const [dateTo, setDateTo] = useState<Date | undefined>(today)
    const [dateFrom, setDateFrom] = useState<Date | undefined>(oneWeekAgo)
    const [viewOptionsExpanded, setViewOptionsExpanded] = useState(false)
    const [ordersBeingSent, setOrdersBeingSent] = useState(false)
    const { globalOrderOptionsEnabled, setGlobalOrderOptionsEnabled, centralOrderParameters } = useGlobalOrderOptionsState()

    const {
        state: { loading },
        search,
        reset,
    } = useCentralOrderWorkTasks()
    const [centralOrderingShowOnlyUserWorkTasks, setCentralOrderingShowOnlyUserWorkTasks] = useKeyValue({
        key: "CENTRAL_ORDERING_SHOW_ONLY_USER_WORKTASKS",
        ownedByRepairShop: false,
    })
    const showOnlyUserWorktasks = centralOrderingShowOnlyUserWorkTasks ? centralOrderingShowOnlyUserWorkTasks === "true" : userWorkTasksEnabled

    useEffect(() => {
        search(dateTo, dateFrom, showOnlyUserWorktasks)
        return reset
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyUserWorktasks]) // when dateTo and/or dateFrom change, no automatic search must be performed.

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            bundleChannel().subscribe("ANY_ORDERS_SENDING", () => {
                setOrdersBeingSent(true)
            })
        )

        unsubscriptions.push(
            bundleChannel().subscribe("ALL_ORDERS_SENT", () => {
                setOrdersBeingSent(false)
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    if (!ordersUrl) {
        return null
    }

    function handleSearchButtonClick() {
        search(dateTo, dateFrom, showOnlyUserWorktasks)
    }

    function handleResetButtonClick() {
        setDateFrom(undefined)
        setDateTo(undefined)
    }

    function handleViewOptionsClick() {
        setViewOptionsExpanded(!viewOptionsExpanded)
    }

    function handleShowOnlyUserWorktaskChange() {
        setCentralOrderingShowOnlyUserWorkTasks?.((!showOnlyUserWorktasks).toString())
    }

    function handleUseGlobalOrderOptionsChange() {
        setGlobalOrderOptionsEnabled(!globalOrderOptionsEnabled)
    }

    return (
        <BoxWithAlignContent>
            <Typography variant="h1" mr={2} alignSelf="center">
                {translateText(1991)}
            </Typography>
            <Toolbar title={translateText(98)}>
                <DateField floatingLabel label={translateText(344)} maxDate={dateTo || today} useUtc value={dateFrom} onChange={setDateFrom} />
                <DateField floatingLabel label={translateText(95)} minDate={dateFrom} maxDate={today} useUtc value={dateTo} onChange={setDateTo} />
                <SearchButton disabled={loading} onClick={handleSearchButtonClick} />
                <Button startIcon={<Icon name="refresh" />} title={translateText(3133)} onClick={handleResetButtonClick} />
            </Toolbar>
            <Toolbar title={translateText(177)}>
                <LastOrders
                    textId={lastOrdersTextId!}
                    generatedWorktaskId={generatedWorktaskId}
                    ordersUrl={ordersUrl}
                    voucherTypeId={voucherTypeId}
                />
            </Toolbar>
            <Toolbar title={translateText(222)}>
                <div style={{ position: "relative" }}>
                    <Button onClick={handleViewOptionsClick}>{translateText(177)}</Button>
                    <Popover active={viewOptionsExpanded} className="view-options" onOutsideInteraction={() => setViewOptionsExpanded(false)}>
                        <Stack alignItems="flex-end">
                            {centralOrderParameters.hasGlobalOrderOptions && (
                                <Switch
                                    label={translateText(13129)}
                                    checked={globalOrderOptionsEnabled}
                                    onChange={handleUseGlobalOrderOptionsChange}
                                    disabled={loading || ordersBeingSent}
                                    labelPlacement="start"
                                    size="small"
                                />
                            )}
                            <Box>
                                <Switch
                                    label={translateText(13125)}
                                    checked={showOnlyUserWorktasks}
                                    onChange={handleShowOnlyUserWorktaskChange}
                                    disabled={loading}
                                    labelPlacement="start"
                                    size="small"
                                />
                            </Box>
                        </Stack>
                    </Popover>
                </div>
            </Toolbar>
        </BoxWithAlignContent>
    )
}
