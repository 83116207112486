import { CategoryType, Vehicle } from "@tm/models"
import { getBundleParams } from "../../utils"
import type { WidgetComponentConfigProps } from "./CatalogWidget"

export function getAvailableCategories(trees: WidgetComponentConfigProps["trees"]) {
    const categories: Array<CategoryType> = []

    // prevent crash if trees object is not there
    if (!trees) {
        return categories
    }

    // Unavailable if it doesn't exist in the config or if hideWidget is true
    if (!trees.vehicleParts?.hideWidget) {
        categories.push("vehicleParts")
    }

    // Only unavailable if hideWidget is true
    if (trees.directSearch?.hideWidget !== true) {
        categories.push("directSearch")
    }

    // Unavailable if it doesn't exist in the config or if hideWidget is true
    if (trees.universalParts && !trees.universalParts.hideWidget) {
        categories.push("universalParts")
    }

    // Unavailable if it doesn't exist in the config or if hideWidget is true
    if (trees.tiresSearch && !trees.tiresSearch.hideWidget) {
        categories.push("tiresSearch")
    }

    if (trees.eurotaxSearch && !trees.eurotaxSearch.hideWidget) {
        categories.push("eurotaxSearch")
    }
    // Unavailable if it isn't activated in the bundle params or if hideWidget is true
    if (getBundleParams().enablePartsIndicator && !trees.predictive?.hideWidget) {
        categories.push("predictive")
    }

    if (trees.fastDTC && !trees.fastDTC.hideWidget) {
        categories.push("dtcSearch")
    }

    return categories
}

export function getDisabledCategories(vehicle?: Vehicle, catalogLight = false) {
    const disabledCategories: Array<CategoryType> = []

    if (!vehicle || catalogLight) {
        disabledCategories.push("vehicleParts", "predictive", "dtcSearch")
    }

    if (!vehicle?.dataAvailabilities?.gpi.eurotaxV3) {
        disabledCategories.push("eurotaxSearch")
    }

    return disabledCategories
}
