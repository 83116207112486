import { Box, Button, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { HeaderItem } from "./HeaderItem"
import { Search } from "../search/Search"

export function Header() {
    const { translateText } = useLocalization()

    return (
        <Box display="flex" flexDirection="row" gap="1em">
            <HeaderItem hideDivider marginRight="1em">
                <Typography variant="h2">Vefiles</Typography>
            </HeaderItem>
            <HeaderItem title={translateText(135)}>
                <Search />
            </HeaderItem>
            <HeaderItem title={translateText(177)}>
                <Button sx={{ height: "32px", width: "32px" }}>
                    <Icon name="upload" />
                </Button>
            </HeaderItem>
        </Box>
    )
}
