import { useQueries, useMutation, UseMutationOptions, useQueryClient } from "react-query"
import {
    OrderOptions,
    OrderOptionsContainer,
    OrderOptionsInternalRequest,
    RegisteredModels,
    SelectOrderOptionRequest,
    SelectOrderOptionResponse,
    OptionSelection,
    PaymentOptionSelection,
    OptionSelectionItem,
    AddressSelection,
    ShipmentModeSelection,
    DeliveryDistributionSelection,
    BackorderOptionSelection,
    BillingModeSelection,
    OrderProcessingOptionSelection,
    TourSelection,
    OrderOptionsError,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { useDefaultErpSystem } from "@tm/utils"

import { useErpInfoEnabled } from "@tm/context-distribution"
import { useFavoriteOrderOptions } from "../../hooks/useFavoriteOrderOptions"
import { useWorkTaskBasketStore } from "./workTaskBasket/workflow/useWorkTaskBasketStore"

export const ORDER_OPTIONS_KEY = "Toolkits.Basket_useOrderOptionsMulti"

const getContainer = (): OrderOptionsContainer => Container.getInstance(RegisteredModels.ERP_OrderOptions)

export function useOrderOptionsMulti(requests: Array<Omit<OrderOptionsInternalRequest, "favoriteOrderOptions">>) {
    const { favoriteOrderOptions } = useFavoriteOrderOptions()
    const { erpSystemConfig } = useDefaultErpSystem()
    const isErpInfoEnabled = useErpInfoEnabled()

    const results = useQueries(
        requests.map((request) => {
            const mappedRequest: OrderOptionsInternalRequest = {
                ...request,
                favoriteOrderOptions: !erpSystemConfig || request.distributorId === erpSystemConfig.id ? favoriteOrderOptions : undefined,
            }

            return {
                queryKey: [ORDER_OPTIONS_KEY, request.workTaskId, request.telesalesCustomerNo, request.distributorId, request.warehouseId],
                queryFn: () => getContainer().subscribe(mappedRequest).load(),
                staleTime: 10000, // 10 Seconds
                enabled: isErpInfoEnabled,
            }
        })
    )

    return results.map((result, index) => ({
        orderOptions: result.data instanceof OrderOptionsError ? undefined : result.data,
        loading: result.isLoading,
        fetching: result.isFetching,
        request: requests[index],
        orderOptionsHasError: result.data instanceof OrderOptionsError ? result.data.hasErrors : false,
        errorText: result.data instanceof OrderOptionsError ? result.data.errorText : undefined,
        disableOrder: result.data instanceof OrderOptionsError ? result.data.disableOrder : undefined,
        refetchOrderOptions: result.refetch,
        loaded: result.isFetched,
        isError: result.isError,
    }))
}

function updateOrderOption(
    prev: OrderOptions,
    optionId: string | undefined,
    propName: keyof OrderOptions,
    // Since the ...Selection types have different property names, it is not possible to make it more dynamic
    // it would be optimal if the properties would have a general nme like: option
    subPropName:
        | keyof AddressSelection
        | keyof PaymentOptionSelection
        | keyof ShipmentModeSelection
        | keyof DeliveryDistributionSelection
        | keyof BillingModeSelection
        | keyof TourSelection
        | keyof OrderProcessingOptionSelection
        | keyof BackorderOptionSelection
): OrderOptions {
    const orderOption = prev[propName]

    if (!orderOption || typeof orderOption !== "object" || orderOption instanceof Date || "version" in orderOption) {
        return prev
    }

    const options = orderOption[subPropName as keyof OptionSelection] as unknown

    if (!Array.isArray(options)) {
        return prev
    }

    return {
        ...prev,
        [propName]: {
            ...orderOption,
            [subPropName]:
                options.map((option: OptionSelectionItem) => ({
                    ...option,
                    isSelected: option.id === optionId,
                })) ?? [],
        },
    }
}

export function useChangeOrderOptions(telesalesCustomerNo: string | undefined) {
    const queryClient = useQueryClient()
    const [invalidateBasketErpInfo, invalidateUpdateOrder] = useWorkTaskBasketStore((store) => [
        store.invalidateBasketErpInfo,
        store.invalidateUpdateOrder,
    ])

    const getMutationOptions = <TRequest extends SelectOrderOptionRequest>(
        orderOptionsUpdater: (prev: OrderOptions, request: TRequest) => OrderOptions
    ): Omit<UseMutationOptions<SelectOrderOptionResponse | void, unknown, TRequest, unknown>, "mutationFn"> => ({
        onSuccess: (response, request) => {
            if (response?.selectedOptionsItem) {
                const { selectedOptionsItem } = response
                if (!request.itemId) {
                    queryClient.setQueryData<OrderOptions | undefined>(
                        [ORDER_OPTIONS_KEY, request.workTaskId, telesalesCustomerNo, request.distributorId, request.warehouseId],
                        (prev) => prev && { ...orderOptionsUpdater(prev, request), selectedOptionsItem }
                    )
                }
            }

            if (request.updateOrderOptions) {
                queryClient.invalidateQueries([
                    ORDER_OPTIONS_KEY,
                    request.workTaskId,
                    telesalesCustomerNo,
                    request.distributorId,
                    request.warehouseId,
                ])
            }

            if (request.updateErpInformation) {
                invalidateBasketErpInfo()
            }
            if (request.updateOrderOnChange) {
                invalidateUpdateOrder()
            }
        },
    })

    const { mutateAsync: setCustomOrderInformation, isLoading: setCustomOrderInformationLoading } = useMutation(
        getContainer().action("setCustomOrderInformation"),
        getMutationOptions((prev, request) => ({ ...prev, customerOrderNumber: request.customOrderNumber, orderComment: request.comment }))
    )

    const { mutateAsync: selectPayment } = useMutation(
        getContainer().action("selectPayment"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "paymentOptions", "paymentOptions"))
    )

    const { mutateAsync: selectBillingMode } = useMutation(
        getContainer().action("selectBillingMode"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "billingModes", "billingModes"))
    )

    const { mutateAsync: selectShipment } = useMutation(
        getContainer().action("selectShipment"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "shipmentModes", "shipmentModes"))
    )

    const { mutateAsync: setDeliveryAddress } = useMutation(
        getContainer().action("selectDeliveryAddress"),
        getMutationOptions((prev, request) => {
            if (!request.selectedAddress) {
                return prev
            }
            if (prev.deliveryAddresses?.addresses.find((address) => address.isSelected && address.id !== request.selectedAddress?.id)) {
                return updateOrderOption(prev, request.selectedAddress?.id, "deliveryAddresses", "addresses")
            }

            return {
                ...prev,
                deliveryAddresses: {
                    ...prev.deliveryAddresses,
                    addresses: [
                        ...(prev.deliveryAddresses?.addresses.filter((x) => x.id !== request.selectedAddress?.id) || []),
                        request.selectedAddress,
                    ],
                },
            }
        })
    )

    const { mutateAsync: selectDeliveryDistribution } = useMutation(
        getContainer().action("selectDeliveryDistribution"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "deliveryDistribution", "deliveryDistributionModes"))
    )

    const { mutateAsync: setDeliveryDate } = useMutation(
        getContainer().action("setDeliveryDate"),
        getMutationOptions((prev, request) => ({ ...prev, deliveryDate: request.deliveryDate }))
    )

    const { mutateAsync: selectTour } = useMutation(
        getContainer().action("selectTour"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.tour.id, "tours", "tours"))
    )

    const { mutateAsync: selectOrderProcessing } = useMutation(
        getContainer().action("selectOrderProcessing"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "orderProcessingOptions", "orderProcessingOptions"))
    )

    const { mutateAsync: selectBackorder } = useMutation(
        getContainer().action("selectBackorder"),
        getMutationOptions((prev, request) => updateOrderOption(prev, request.optionId, "backorderOptions", "backorderOptions"))
    )

    return {
        setCustomOrderInformation,
        setCustomOrderInformationLoading,
        selectPayment,
        selectBillingMode,
        selectShipment,
        selectDeliveryDistribution,
        setDeliveryDate,
        selectTour,
        selectOrderProcessing,
        selectBackorder,
        setDeliveryAddress,
    }
}
