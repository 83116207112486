import { VeFiles } from "@tm/data"
import { useCallback, useState } from "react"

export function useFileDownload() {
    const [isError, setIsError] = useState(false)

    const downloadFile = useCallback(async (file: VeFiles.FileMeta) => {
        try {
            const response = await fetch(file.url)
            if (response.ok) {
                const blob = await response.blob()
                const completeFileName = file.displayName
                const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: file.mimeType }))

                const a = document.createElement("a")
                a.href = blobUrl
                a.download = completeFileName
                a.click()

                window.URL.revokeObjectURL(blobUrl)
            } else {
                setIsError(true)
            }
        } catch (error) {
            setIsError(true)
        }
    }, [])

    return { downloadFile, isError }
}
