import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { ArticleListType, WholesalerArticleNumbersItemState, WholesalerPart } from "@tm/models"
import { ArticleItemStateProvider } from "@tm/components"
import { useHandleAddWholesalerArticleNumberToBasket } from "../../hooks/useArticleItem/useHandleAddWholesalerArticleNumberToBasket"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { isSameWholesalerArticle, mapWholesaleDtoFromWholesalerPart, mapWholesalerPartToArticle } from "../../helpers"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useWholesalerArticleNumbersModuleState } from "../../WholesalerArticleNumbers/WholesalerArticleNumberModuleState"
import { useArticleListActions } from "../../ArticleListActions"
import { useArticleErpInfos } from "../../hooks/useArticleItem/useArticlesErpInfos"
import { useArticleExtendedDeliveryInfo } from "../../hooks/useArticleItem/useArticleExtendedDeliveryInfo"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { useLoadErpInfos } from "../../hooks/useErpInfos"

type OeArticleItemStateProviderProps = PropsWithChildren<{
    article: WholesalerPart
}>

const listType: ArticleListType = "default"

export function WholesalerArticleNumbersItemStateProvider({ article, children }: OeArticleItemStateProviderProps) {
    const options = useWholesalerArticleNumbersModuleState((x) => x.options)
    const loadErpInfos = useLoadErpInfos(listType)
    const [quantity, setQuantity] = useState<number>(article?.quantityValue || 1)
    const wholesalerBasketQuantities = useWholesalerArticleNumbersModuleState((x) => x.wholesalerBasketQuantities)
    const { updateWholesalerBasketQuantities } = useArticleListActions()

    const handleAddToBasketInternal = useHandleAddWholesalerArticleNumberToBasket(article)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(() => {
        const wholesalerArticleDto = mapWholesaleDtoFromWholesalerPart(article)
        return wholesalerBasketQuantities?.find((q) => isSameWholesalerArticle(q.article, wholesalerArticleDto))
    }, [wholesalerBasketQuantities, article])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            updateWholesalerBasketQuantities?.([article])
        },
        [handleAddToBasketInternal, quantity, article, updateWholesalerBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateWholesalerBasketQuantities?.([article])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateWholesalerBasketQuantities, article])

    const wholesalerToArticle = useMemo(() => mapWholesalerPartToArticle(article), [article])
    const articleErpInfos = useArticleErpInfos(listType, wholesalerToArticle, quantity)

    const { hideExtendedDeliveryComponent } = useArticleListConfiguration()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(listType, !hideExtendedDeliveryComponent, articleErpInfos.default)

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setQuantity(value)

            if (loadErpInfo) {
                loadErpInfos({ article: wholesalerToArticle, quantity: value, erpSystem: articleErpInfos.default?.erpSystem })
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article: wholesalerToArticle, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [wholesalerToArticle, loadErpInfos, articleErpInfos]
    )

    const state: WholesalerArticleNumbersItemState = {
        type: "wholesalerArticleNos",
        handleAddToBasket,
        article: wholesalerToArticle,
        basketQuantity,
        handleChangeQuantity,
        handleRemoveFromBasket,
        quantity: article.quantityValue,
        isVehicleDependent: false,
        originalQuantity: article.quantityValue,
        AvailabilityComponent,
        thumbnailUrl: "",
        wholesalerArticleNumber: article.wholesalerArticleNumber,
        options,
        articleErpInfos,
        extendedDeliveryInfo,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}
