import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Headline, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECalcOrigin, EReplaceButtonBundle, OE, RequestArticleListPayload, channel } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { RouteComponentProps, renderRoute, withRouter } from "@tm/utils"
import { Box, LoadingContainer } from "@tm/components"
import { ECalcButtonState, ECalcState } from "../../data/enums"
import {
    getProductGroupIds,
    getTopProductGroups,
    isVehicleChanged,
    mapCalcArticleToArticleIdentifier,
    mapCalcArticleToOePart,
} from "../../data/helpers"
import { AlternativeCalcArticlesSelector, StatusSelector, VehicleSelector, calcStates } from "../../data/helpers/selectors"
import { getCurrencyFromUserContext, getLabourRate } from "../../data/helpers/userSettingsContextMethods"
import { AlternativeCalcStateContext, CalcArticle, CalcGenArt, CalcState, DialogContext } from "../../data/model"
import { getPoolingInterval } from "../../helpers"
import { CalcStateButton } from "../_shared"
import Calculation from "../calculation/component"
import Navigation from "../navigation/component"
import QuestionComponent from "../questionPrompt/component"
import QueuedStatusComponent from "../queueStatus/component"
import Summary from "../summary/component"
import { MainActions, widgetSelector } from "./business"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { TopProductGroup } from "../../data/repositories/fastcalculator-calcProductGroups/model"

type Routes = {
    costEstimation: string
}

type ConfigProps = {
    inModal?: boolean
    routes: Routes
    urls: Routes // urls will be automatically created (based on "routes") by Morpheus with route params being already set (e.g. workTaskId)
}

export type MainProps = ConfigProps & RouteComponentProps<RouteParams> & {}

type RouteParams = {
    productGroupIds?: string
    origin?: string
}

function MainComponent(props: MainProps) {
    let scrollTimeoutId: number | undefined

    const { userSettings, userContext } = useUser() ?? {}
    const { translateText, languageId } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const worktaskVehicle = workTask?.vehicle
    const { vehicle } = useSelector(VehicleSelector)
    const { importFastCalculation } = useBasketImports()

    const [vehicleId, setvehicleId] = useState<string | undefined>()

    const queryParams = new URLSearchParams(props.location.search)
    const initialCalcId = queryParams.get("initialCalcId")
    const isWorkshop = queryParams.get("isWorkshop") === "true"
    const forceReset = queryParams.get("forceReset") === "true"
    const isReplaceArticle = queryParams.get("isReplaceArticle") === "true"

    const [showPricesInHeader, setShowPricesInHeader] = useState(false)
    const actions = useActions(
        MainActions,
        "initFastCalculator",
        "setConfigProps",
        "startCalculationWithProductGroups",
        "initialData",
        "handleInputSubmit",
        "handleAddPartFromArticleList",
        "handleButtonClick",
        "setVehicle",
        "skipReload",
        "checkQueuedCalculation",
        "cancelQueue",
        "setCalcOrigin",
        "replaceArticleStart"
    )

    // probably selectedDataCalcState is not needed here
    const { selectedCalcState, selectedDialogCalcState } = useSelector(calcStates)
    const { loading, error, isQueued } = useSelector(StatusSelector)
    const { skipReload } = useSelector(widgetSelector)
    const { alternativeCalcArticles } = useSelector(AlternativeCalcArticlesSelector)
    const alternativesContext = alternativeCalcArticles?.context as AlternativeCalcStateContext

    useEffect(() => {
        if (worktaskVehicle && isVehicleChanged(worktaskVehicle, vehicle)) {
            actions.setVehicle(worktaskVehicle)
            actions.initialData(languageId, getLabourRate(userSettings))
        }
    }, [userContext?.system.systemType, worktaskVehicle])

    const timerRef = useRef<number | null>(null)

    useEffect(() => {
        if (timerRef.current) {
            window.clearInterval(timerRef.current)
            timerRef.current = null
        }

        if (isQueued && !timerRef.current) {
            timerRef.current = window.setInterval(() => actions.checkQueuedCalculation(), getPoolingInterval())
        }

        return () => {
            if (timerRef.current) {
                actions.cancelQueue()
                window.clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }, [isQueued])

    useEffect(() => {
        const {
            urls: { costEstimation },
            inModal,
        } = props

        actions.setConfigProps({ costEstimationUrl: costEstimation, inModal: inModal ?? false })

        if (isReplaceArticle) {
            return
        }

        if (skipReload) {
            actions.skipReload(false)
            return
        }

        if (vehicle?.id != vehicleId) {
            setvehicleId(vehicle?.id)
            const productGroupIds = getProductGroupIds(props.match?.params?.productGroupIds)

            if (productGroupIds) {
                const topProductGroups: TopProductGroup[] | undefined = getTopProductGroups(props.match?.params?.productGroupIds)
                actions.setCalcOrigin(ECalcOrigin.Next | ECalcOrigin.Searchtree)

                if (topProductGroups && topProductGroups?.length > 0) {
                    actions.startCalculationWithProductGroups(
                        productGroupIds,
                        languageId,
                        getLabourRate(userSettings),
                        props.match?.params?.origin,
                        topProductGroups
                    )
                } else {
                    actions.startCalculationWithProductGroups(productGroupIds, languageId, getLabourRate(userSettings), props.match?.params?.origin)
                }
            } else {
                actions.setCalcOrigin(ECalcOrigin.Next)
                actions.initFastCalculator(false, initialCalcId || undefined, isWorkshop)
            }
        }
    }, [vehicle])

    useEffect(() => {
        const { match, history } = props

        if (forceReset && selectedCalcState?.type == ECalcState.FastCockpitNext) {
            const resetButton = selectedCalcState?.buttonGroups.find((x) => x.index == 1)?.buttons.find((x) => x.type == ECalcButtonState.Reset)

            if (!resetButton) {
                return
            }

            handleGeneralReset(selectedCalcState)
            history.replace(renderRoute(match.path, { ...match.params }))

            return
        }

        if (error && error != error) {
            handleGeneralReset(selectedCalcState)
        } // try to reset FC in case of an error from WS

        if (selectedCalcState?.type == ECalcState.InitialData) {
            const productGroupIds = getProductGroupIds(props.match?.params?.productGroupIds)

            if (productGroupIds) {
                const topProductGroups: TopProductGroup[] | undefined = getTopProductGroups(props.match?.params?.productGroupIds)
                actions.setCalcOrigin(ECalcOrigin.Next | ECalcOrigin.Searchtree)

                if (topProductGroups && topProductGroups?.length > 0) {
                    actions.startCalculationWithProductGroups(
                        productGroupIds,
                        languageId,
                        getLabourRate(userSettings),
                        props.match?.params?.origin,
                        topProductGroups
                    )
                } else {
                    actions.startCalculationWithProductGroups(productGroupIds, languageId, getLabourRate(userSettings), props.match?.params?.origin)
                }
            } else {
                actions.setCalcOrigin(ECalcOrigin.Next)
                actions.initialData(languageId, getLabourRate(userSettings))
            }
        }
    }, [selectedCalcState?.type])

    const handleScroll = (e: any) => {
        window.clearTimeout(scrollTimeoutId)

        scrollTimeoutId = window.setTimeout(() => {
            setShowPricesInHeader(e.target.scrollTop >= 125)
        }, 100)
    }

    const requestAlternatives = (request: RequestArticleListPayload, genArt: CalcGenArt, part?: CalcArticle) => {
        const { match, inModal, location } = props

        actions.replaceArticleStart(match, location.search, inModal, genArt, part)

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            ...request,
            fastCalculator: request.fastCalculator ?? {},
            replaceButtonMicro: EReplaceButtonBundle.FastCalculatorNext,
            forceReload: true,
            inModal: true,
        })
    }

    const requestProductGroupAlternatives = (genArt: CalcGenArt) => {
        requestAlternatives(
            {
                productGroups: {
                    ids: [genArt.genArtNr],
                },
                fittingPosition: genArt.binKrit100,
            },
            genArt
        )
    }

    const requestPartAlternatives = (genArt: CalcGenArt, part: CalcArticle) => {
        const { showoePrice } = Morpheus.getParams<{ [key: string]: string }>("fast-calculator-next")

        requestAlternatives(
            {
                productGroups: {
                    ids: [genArt.genArtNr],
                },
                fastCalculator: {
                    alternatives: alternativesContext?.articles?.map((x) =>
                        mapCalcArticleToArticleIdentifier(x, genArt.genArtNr, vehicle?.tecDocTypeId)
                    ),
                    ...(Boolean(+showoePrice) && { oeNrs: [mapPartToOe(part, genArt)] }),
                },
                fittingPosition: part.binKrit100,
            },
            genArt,
            part
        )
    }

    const mapPartToOe = (part: CalcArticle, genArt: CalcGenArt): OE.OePosition => {
        const oePart = mapCalcArticleToOePart(part)

        return {
            number: part.hArtNr,
            description: part.supplierName,
            isSelected: true,
            replacements: genArt.oeNrs
                ?.filter((x) => part.binKrit100! & x.binKrit100!)
                ?.map((x) => {
                    return {
                        number: x.oeNr,
                        parts: [
                            {
                                description: x.attributeLabel,
                                id: x.id,
                                manufacturerName: x.herBez,
                                number: x.oeNr,
                                quantity: x.customQuantity ?? 1,
                                prices: [{ value: x.price ?? 0, currency: getCurrencyFromUserContext(userContext) }],
                                disabled: true,
                                // eArtNr: part.supplierArtNr,
                                // iArtNr: part.iArtNr
                            },
                        ],
                    }
                })
                ?.filter((x, idx, arr) => x.number != oePart.number && arr?.findIndex((y) => y.number == x.number) == idx),
        }
    }

    const requestOePartAlternatives = (genArt: CalcGenArt, part: CalcArticle) => {
        const oePart = mapCalcArticleToOePart(part)

        requestAlternatives(
            {
                oePositions: [
                    {
                        number: oePart.number,
                        description: part.oeGroups.findFirst((x) => x.isDisabled)?.description ?? oePart.description,
                        replacements: alternativesContext?.articles
                            ?.map((x) => {
                                const alternativeOePart = mapCalcArticleToOePart(x)

                                return {
                                    number: alternativeOePart.number,
                                    parts: [alternativeOePart],
                                }
                            })
                            ?.filter((x, idx, arr) => x.number != oePart.number && arr?.findIndex((y) => y.number == x.number) == idx), // Filter out duplicate oe numbers
                    },
                ],
                fittingPosition: part.binKrit100,
            },
            genArt,
            part
        )
    }

    const handleGeneralReset = (selectedCalcState?: CalcState) => {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == ECalcButtonState.Reset)
        resetBtn && actions.handleButtonClick(resetBtn, languageId, importFastCalculation, getLabourRate(userSettings))
        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: `Fast Calculator ${translateText(1825)}`,
            skin: "warning",
            icon: "warning",
        })
    }

    if (!vehicle && !loading) {
        return (
            <LoadingContainer isLoading={loading}>
                <Headline>No Vehicle</Headline>
            </LoadingContainer>
        )
    }

    if (isReplaceArticle) {
        return <LoadingContainer isLoading />
    }

    if (!selectedCalcState || selectedCalcState.type === ECalcState.InitialData) {
        if (isQueued) {
            return (
                <LoadingContainer isLoading={loading}>
                    <QueuedStatusComponent workTaskId={workTask?.id || ""} isQueued={isQueued} isError={false} />
                </LoadingContainer>
            )
        }
        return <LoadingContainer isLoading={loading} />
    }

    if (error) {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == ECalcButtonState.Reset)
        return (
            <LoadingContainer isLoading={loading}>
                <div className="article-list__panel article-list__no-result">
                    <div className="headline">{translateText(787)}</div>
                </div>
                {(resetBtn && <CalcStateButton item={resetBtn} />) || "Error"}
            </LoadingContainer>
        )
    }

    if (isQueued) {
        const resetBtn = selectedCalcState?.buttonGroups?.[0]?.buttons?.find((x) => x.type == ECalcButtonState.Reset)
        return (
            <LoadingContainer isLoading={loading}>
                <Navigation inModal={props.inModal} showPrices={showPricesInHeader} selectedCalcState={selectedCalcState} disableTabs />
                <QueuedStatusComponent workTaskId={workTask?.id || ""} isQueued={isQueued} isError={false} resetBtn={resetBtn} />
            </LoadingContainer>
        )
    }

    if (selectedCalcState.type === ECalcState.LastCalcPreviewNext) {
        return <LoadingContainer isLoading={loading} />
    }

    return (
        <LoadingContainer isLoading={loading}>
            <Navigation inModal={props.inModal} showPrices={showPricesInHeader} selectedCalcState={selectedCalcState} />
            <Box margin="1em 0.5em" display="flex" width="100%" height="100%">
                <Scrollbar onScroll={handleScroll}>
                    {selectedCalcState.type === ECalcState.FastCockpitNext && <Summary />}
                    {selectedCalcState.type === ECalcState.CalculationNext && (
                        <Calculation
                            showProductGroupAlternatives={requestProductGroupAlternatives}
                            showOePartAlternatives={requestOePartAlternatives}
                            showPartAlternatives={requestPartAlternatives}
                            hideAlternatives={props.inModal}
                        />
                    )}
                </Scrollbar>
            </Box>
            {selectedDialogCalcState && <QuestionComponent selectedDialogContext={selectedDialogCalcState?.context as DialogContext | undefined} />}
        </LoadingContainer>
    )
}

export default withRouter(MainComponent)
