import { VeFiles } from "@tm/data"

export function getFileCategory(file: VeFiles.FileMeta) {
    if (!file.labels.length) {
        return ""
    }

    const category = file.labels.filter((f) => f.startsWith("category:"))?.first()
    return category?.split("category:")?.[1] ?? ""
}
