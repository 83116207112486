import { useLocalization } from "@tm/localization"
import { useDefaultErpSystem } from "@tm/utils"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { ArticleErpInfo, ArticleListType, DefaultArticleItemState } from "@tm/models"
import { mergeTeccomPrices, mergeWarehouses } from "../../../../../../erp/src/micros/_helpers/useErpInfo"
import { useTeccom } from "../../../../../../erp/src/micros/_helpers/useTeccom"
import { ErpInfosState } from "../../states"

export function useArticleExtendedDeliveryInfo(
    listType: ArticleListType,
    isEnabled: boolean,
    articleErpInfo?: ArticleErpInfo
): DefaultArticleItemState["extendedDeliveryInfo"] {
    const { translateText } = useLocalization()

    const setErpInfo = useSetRecoilState(ErpInfosState(listType))

    const { erpSystemConfig } = useDefaultErpSystem()
    const erpInfo = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined
    const {
        teccom: info,
        loadTeccom: load,
        teccomLoading: isLoading,
    } = useTeccom(erpInfo?.isTeccomRequestAvailable, articleErpInfo?.request, erpSystemConfig?.id)

    useEffect(() => {
        if (!info) {
            return
        }

        setErpInfo((prev) => {
            return prev.map<ArticleErpInfo>((item) => {
                // If the item is not the one we are looking for, we return it as is
                if (item.request.itemId !== info.itemId) {
                    return item
                }

                // If the item has no ERP data loaded (yet), we return it as is
                const articleErpInfoResponse = item.state === "success" ? item.response : undefined
                if (!articleErpInfoResponse) {
                    return item
                }

                // Finally we merge the ERP data with the teccom data
                return {
                    ...item,
                    response: {
                        ...articleErpInfoResponse,
                        availability: info.specialProcurementErpInformation?.availability ?? articleErpInfoResponse.availability,
                        orderOptions: info.specialProcurementErpInformation?.orderOptions ?? articleErpInfoResponse.orderOptions,
                        prices: mergeTeccomPrices(articleErpInfoResponse.prices, info.prices),
                        warehouses: mergeWarehouses(translateText, articleErpInfoResponse.warehouses, info),
                    },
                }
            })
        })
    }, [info, setErpInfo, translateText])

    return { showExtendedDeliveryInfo: isEnabled, info, load, isLoading }
}
