import { Suspense } from "react"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { Loader } from "@tm/controls"
import { style } from "typestyle"
import { ConfigParams, IMicros } from "@tm/models"
import PricesComponent from "../../../_shared/prices"
import AvailabilityComponent from "../../../_shared/availability"
import SpecialIcons from "../../../_shared/special-icons"
import Warehouses from "./components/warehouses"
import { useErpInfoDetails } from "../../_helpers/details-business/useErpInfoDetails"
import { ErpInfoDetailsActions, IErpInfoDetailsActions } from "../../_helpers/details-business"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-details"] & {
    actions: IErpInfoDetailsActions
}

const specialIconsClassName = style({
    borderLeft: "1px solid gray",
    marginLeft: "1em",
    height: "100%",
})

function ErpInfoDetailsComponent(props: Props) {
    const { loading, erpInfo, error } = useErpInfoDetails(props)

    if (loading) {
        return <Loader />
    }

    const { prices } = erpInfo ?? {}

    return (
        <>
            <div className="erp-info-details__left">
                {!!prices?.length && (
                    <PricesComponent
                        classPrefix="erp-info-details"
                        prices={prices}
                        erpInfo={erpInfo}
                        hasGraduatedPrices={erpInfo?.hasGraduatedPurchasePrice || false}
                        graduatedPrices={erpInfo?.graduatedPrices}
                        onGraduatedPricesClick={props.onGraduatedPricesClick}
                        article={props.data}
                        quantity={erpInfo?.quantity}
                        isReplacementPart={erpInfo ? erpInfo.isReplacementPart : false}
                        showAdditionalPrices={props.showAdditionalPrices}
                        foundBySearchTerm={props.foundBySearchTerm}
                    />
                )}
                <AvailabilityComponent
                    classPrefix="erp-info-details"
                    hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                    availability={erpInfo?.availability}
                    tour={erpInfo?.tour}
                    warehouses={erpInfo?.warehouses}
                    error={error}
                    hasAlternatives={Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp || erpInfo?.hasAlternatives || false}
                    hasReplacementArticles={erpInfo?.hasReplacementArticles || false}
                    onAlternativesClick={props.onAlternativesClick}
                    onReplacementArticlesClick={props.onReplacementArticlesClick}
                    customComponents={<SpecialIcons className={specialIconsClassName} icons={erpInfo?.specialIcons} />}
                    requestedQuantity={erpInfo?.quantity?.requestedValue}
                    specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                    useSpecialProcurement={erpInfo?.useSpecialProcurement}
                />
            </div>
            {props.hideWarehouseTable !== true && (
                <Warehouses warehouses={erpInfo?.warehouses} requestedQuantity={erpInfo?.quantity?.requestedValue} />
            )}
        </>
    )
}

function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-details">
                    <Suspense fallback={<Loader />}>
                        <ErpInfoDetailsComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}

export default connectComponent(ErpInfoDetailsActions, Wrapper)
