import { useRef, useState, useEffect, useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { Tooltip } from "@tm/controls"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { TmaHelper, convertBase64ImageToByteArray, encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { ECounterType, UserModuleType, WorkTaskDetailsReadModel } from "@tm/models"
import { matchPath, useHistory } from "react-router"
import { GlobalNavigationItem, Icon } from "@tm/components"
import { sendFeedback } from "../../data"
import { getBundleParams } from "../../utils"
import { Thanks } from "../../components/thanks"
import { FeedbackFormular } from "../../components/feedbackFormular"
import { SimpleFeedbackFormular } from "../../components/simpleFeedbackFormular"
import { UserSnapFeedbackFormular } from "../../components/userSnapFeedbackFormular"
import { Rating } from "../../components/rating"

export type Formular = {
    file?: {
        fileName: string
        fileData: string
        fileMimeType: string
    }
    rating?: Rating
    name?: string
    email?: string
    phone?: string
    text?: string
    feedbackEmailAdress?: any
    catalogueId?: number
    workTaskReadModel?: WorkTaskDetailsReadModel
}
const MVC_FEEDBACK_URL = "/:workTaskId/mvc-feedback"
export function ToolbarFormComponent() {
    const tooltipRef = useRef<Tooltip>(null)
    const [showThanks, setShowThanks] = useState<boolean>(false)
    const [preset, setPreset] = useState<Formular>({})
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser() || {}
    const { layout, iconName, enableMVCFeedback } = getBundleParams()
    const workTask = useWorkTask()?.workTask
    const feedbackModule = userContext.modules?.find((x) => x.type === UserModuleType.Feedback)
    const { workTaskId } = useWorkTask() ?? {}
    const history = useHistory()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [rootClassName, setRootClassName] = useState<string>()
    const [isActive, setIsActive] = useState<boolean>(false)
    useEffect(() => {
        const repairShop = userSettings?.repairShop
        if (repairShop) {
            const lastname = repairShop.contactPerson?.lastName
            const firstname = repairShop.contactPerson?.firstName
            const company = repairShop.companyName
            setPreset({
                name: `${company ? `${company} ` : ""}${firstname ? `${firstname} ` : ""}${lastname || ""}`,
                email: repairShop.contactInfo?.email,
                phone: repairShop.contactInfo?.phone,
                feedbackEmailAdress: userContext?.parameter?.eMailFeedback ?? "",
                catalogueId: userContext?.modules?.[0]?.id,
                workTaskReadModel: workTask?.workTaskReadModel,
            })
        }
    }, [userSettings?.repairShop, userContext?.parameter, userContext?.modules, workTask?.workTaskReadModel])

    useEffect(() => setRootClassName(`tk-feedback toolbar-form${showThanks ? " showThanks" : ""}`), [showThanks])

    const hideToolTip = useCallback(() => {
        if (tooltipRef.current) {
            tooltipRef.current.hide()
        }
    }, [tooltipRef])

    const handleChangeVisibility = useCallback(
        (open: boolean) => {
            setIsOpen(open)
            if (open) {
                TmaHelper.GeneralCountEvent.Call(ECounterType.Feedback)
            }
            if (!open && showThanks) {
                setShowThanks(false)
            }
        },
        [showThanks]
    )

    const openMvcFeedback = useCallback(() => {
        const newWorkTaskId = workTaskId || uniqueId()
        if (newWorkTaskId) {
            const url = renderRoute(MVC_FEEDBACK_URL, { workTaskId: encodeUniqueId(newWorkTaskId) })
            history.push(url)
        }
    }, [workTaskId, history])

    useEffect(() => {
        const { pathname, search } = history.location
        setIsActive(!!matchPath(pathname + search, MVC_FEEDBACK_URL))
    }, [history.location])

    const getContent = useCallback(() => {
        const onSubmit = (formular: Formular) => {
            if (formular.email && formular.name) {
                sendFeedback({
                    id: uniqueId(),
                    email: formular.email,
                    personname: formular.name,
                    phone: formular.phone || "",
                    description: formular.text || "",
                    rating: formular.rating?.value || 0,
                    image: formular.file ? convertBase64ImageToByteArray(formular.file?.fileData) : undefined,
                })
                setShowThanks(true)
            }
        }

        if (showThanks) {
            return <Thanks onClick={hideToolTip} />
        }
        if (layout === "extended") {
            return <FeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
        }
        if (layout === "usersnap") {
            return <UserSnapFeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
        }
        return <SimpleFeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
    }, [showThanks, preset, layout, hideToolTip])

    if (feedbackModule && enableMVCFeedback) {
        return (
            <GlobalNavigationItem
                active={isActive || isOpen}
                noOffsetOnActive
                tooltip={translateText(41)}
                componentType="icon"
                name={iconName || "feedbacksmiley"}
                onIconClick={openMvcFeedback}
            />
        )
    }

    return (
        <Tooltip
            content={getContent()}
            ref={tooltipRef}
            event="click"
            // eslint-disable-next-line react/style-prop-object
            style="highlight"
            className={rootClassName}
            position="bottom"
            onChangeVisibility={handleChangeVisibility}
            preventCloseOnScroll
            forcePosition
        >
            <GlobalNavigationItem
                active={isOpen}
                noOffsetOnActive
                tooltip={!isOpen ? translateText(41) : undefined}
                componentType="icon"
                name={iconName || "feedbacksmiley"}
            />
        </Tooltip>
    )
}

export default ToolbarFormComponent
