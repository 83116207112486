import { Article, ArticleListType, ErpBundleParams, RequestArticleListPayload, UserModuleType, Vehicle, WholesalerArticle } from "@tm/models"
import { useQuery } from "react-query"
import { usePureArticleList, useUser, useWorkTaskId } from "@tm/context-distribution"
import { useMemo } from "react"
import { uniq } from "lodash"
import Morpheus from "@tm/morpheus"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { useVehicle } from "../../ListV2/hooks/useVehicle"
import { useSupplierLogos } from "../../ListV2/hooks/useSupplierLogos"
import { useNotes } from "../../ListV2/hooks/useNotes"
import { useProductGroupRepairTimes } from "../../ListV2/hooks/useProductGroupRepairTimes"
import { useArticleFeedbackConfiguration } from "../../ListV2/ArticleListConfiguration/useArticleFeedbackConfiguration"
import { useErpInfos } from "../../ListV2/hooks/useErpInfos"
import { useListOptions } from "../../ListV2/hooks/useListOptions"
import { useBasketQuantities } from "../../ListV2/hooks/useBasketQuantities"
import { useHasLoadingEnded } from "../../ListV2/hooks/useHasLoadingEnded"
import { useWatchListData } from "../../ListV2/hooks/useWatchListData"
import { useTradeReferences } from "../../ListV2/hooks/useTradeReferences"
import { loadAlternatives } from "./loadAlternatives"
import { loadPartsList } from "./loadPartsList"
import { PartsListImage, PartsListImageCoordinate } from "../../../data/model"
import { getAccessoryList } from "../../../data/repositories/parts"
import { usePreviouslyOrderedArticles } from "../../ListV2/hooks/usePreviouslyOrderedArticles"

export function usePureList(listId: string, pureListType: string) {
    const listType: ArticleListType = "pure"
    const workTaskId = useWorkTaskId()
    const vehicle = useVehicle()
    const { userContext } = useUser()

    const { hideExtendedDeliveryComponent } = Morpheus.getParams<ErpBundleParams>("erp")
    const { showDataSupplierLogos = false } = userContext.parameter
    const feedbackConfiguration = useArticleFeedbackConfiguration()
    const options = useListOptions("direct")
    const activeVehicleDataProviders = useActiveVehicleDataProviders(workTaskId)

    const { state } = usePureArticleList(listId)
    const isInitialized = !!state?.request

    const { data, isLoading } = useQuery({
        queryKey: ["pureList", workTaskId, state?.request],
        queryFn: () => state.request && loadArticles(pureListType, state.request, vehicle),
        enabled: !!state?.request,
    })

    const hasArticlesLoaded = !!data?.articles
    const articles = useMemo(() => data?.articles ?? [], [data?.articles])
    const productGroupIds = useMemo(() => uniq(articles.map((x) => x.productGroup.id) ?? []), [articles])
    const supplierIds = useMemo(() => uniq(articles.map((x) => x.supplier.id) ?? []), [articles])

    const erpIsReady = useErpInfos(listType, data?.articles ?? [], hasArticlesLoaded)

    const supplierLogos = useSupplierLogos(supplierIds, showDataSupplierLogos && erpIsReady)
    const watchListData = useWatchListData(articles, options.hasWatchList && erpIsReady)

    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(data?.articles ?? [], erpIsReady)
    const notesParams = useNotes(data?.articles ?? [], false, erpIsReady)
    const productGroupRepairTimes = useProductGroupRepairTimes(productGroupIds, erpIsReady)
    const tradeReferences = useTradeReferences(articles, erpIsReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(data?.articles)
    const showNotes = useMemo<boolean>(() => userContext.modules?.some((x) => x.type === UserModuleType.ArticleNotes) ?? false, [userContext.modules])

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogos.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimes.isLoading,
        activeVehicleDataProviders.isLoading
    )
    return {
        data,
        options,
        isLoading,
        supplierLogosData: hasLoadingEnded ? supplierLogos : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimesData: hasLoadingEnded ? productGroupRepairTimes : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProviders.activeProviders : undefined,
        feedbackConfiguration,
        notesParams,
        showNotes,
        previouslyOrderedArticles,
        showExtendedDelivery: !hideExtendedDeliveryComponent,
        listType,
        isInitialized,
        basketQuantities,
        tradeReferences,
        updateBasketQuantities,
    }
}

type LoadArticlesResponse = {
    articles: Article[]
} & (
        | { type: null }
        | {
            type: "alternative"
            wholesalerParts?: WholesalerArticle[]
        }
        | {
            type: "partslist"
            imageCoordinates?: PartsListImageCoordinate[]
            partsListImage?: PartsListImage
        }
        | {
            type: "accessoryList"
        }
    )

async function loadArticles(type: string, request: RequestArticleListPayload, vehicle?: Vehicle): Promise<LoadArticlesResponse> {
    const { traderArticleNos, partsList, accessoryList } = request
    if (type === "alternative" && traderArticleNos) {
        return {
            type,
            ...(await loadAlternatives(request, vehicle)),
        }
    }
    if (type === "partslist" && partsList) {
        return {
            type,
            ...(await loadPartsList(request)),
        }
    }

    if (type === "accessoryList" && accessoryList) {
        return {
            type,
            articles: await getAccessoryList(accessoryList),
        }
    }

    return { type: null, articles: [] }
}
