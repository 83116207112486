import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, LicensePlateField, RealTable, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { plateCountryCodeMapper } from "@tm/utils"
import { style } from "typestyle"
import { DMS } from "@tm/models"
import { FC } from "react"
import { getDateFormat } from "../../../../business"
import { getCustomerNameAndCompany, getVehicleName } from "../../helpers"

import { Selection, getComponentStyles } from "."

type Props = {
    vouchers: Array<DMS.Voucher>
    onVoucherSelect?(voucher: DMS.Voucher): void
    onVoucherDeselect(): void
    loading?: boolean
    label?: string
    noItemsMessage?: string
    compact?: boolean
    selectedVoucher?: DMS.Voucher
    withVehicleInfo?: boolean
    onVoucherAttach?(voucher: DMS.Voucher): void
    onLoadNextVouchers?(): void
}

export const VoucherSelection: FC<Props> = (props) => {
    const className = style(getComponentStyles(props.compact))
    const { translate, translateText, date, currency, languageId } = useLocalization()
    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()

    const renderDeselectButton = () => {
        if (props.selectedVoucher) {
            return (
                <Tooltip content={translateText(1929)}>
                    <Button
                        icon="arrow-right"
                        size="xs"
                        skin="highlight"
                        onClick={props.onVoucherDeselect}
                        className={`${className}__deselect-button dms-voucher-deselect-button`}
                    />
                </Tooltip>
            )
        }
    }

    const renderType = (voucher: DMS.Voucher) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{voucher.voucherType.description}</Text>
            </RealTable.Cell>
        )
    }

    const renderNumber = (voucher: DMS.Voucher) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{voucher.voucherId}</Text>
            </RealTable.Cell>
        )
    }

    const renderDate = (voucher: DMS.Voucher) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{date(voucher.creationDate, getDateFormat(languageId))}</Text>
            </RealTable.Cell>
        )
    }

    const renderCustomerNameAndCompany = (voucher: DMS.Voucher) => {
        const { customer } = voucher

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{customer && getCustomerNameAndCompany(customer)}</Text>
            </RealTable.Cell>
        )
    }

    const renderVehicleName = (voucher: DMS.Voucher) => {
        const { vehicle } = voucher

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{vehicle && getVehicleName(vehicle)}</Text>
            </RealTable.Cell>
        )
    }

    const renderLicensePlate = (voucher: DMS.Voucher) => {
        const { vehicle } = voucher
        const licensePlateCountryCode = plateCountryCodeMapper(vehicle?.countryCode || defaultPlateCode)
        return (
            <RealTable.Cell className={`${className}__cell`}>
                {vehicle?.registrationInformation?.plateId && (
                    <LicensePlateField readonly value={vehicle.registrationInformation.plateId} shortCountryCode={licensePlateCountryCode} />
                )}
            </RealTable.Cell>
        )
    }

    const renderTotalSum = (voucher: DMS.Voucher) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{voucher.totals && currency(voucher.totals.priceVatIncluded, voucher.totals.currencySymbol)}</Text>
            </RealTable.Cell>
        )
    }

    const renderActions = (voucher: DMS.Voucher) => {
        const { compact } = props

        const attach = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onVoucherAttach?.(voucher)
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {/* <Tooltip content={compact ? translateText(43) : undefined}>
                        <Button icon="details">{!compact && translate(43)}</Button>
                    </Tooltip> */}
                    {props.onVoucherAttach && (
                        <Tooltip content={compact ? translateText(1041) : undefined}>
                            <Button icon="paperclip" onClick={attach}>
                                {!compact && translate(1041)}
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        return [
            <RealTable.Column key="type" renderItemContent={renderType} className={`${className}__column ${className}__column--nowrap`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(939)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="number" renderItemContent={renderNumber} className={`${className}__column ${className}__column--nowrap`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(32)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="date" renderItemContent={renderDate} className={`${className}__column ${className}__column--nowrap`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(98)}
                </Text>
            </RealTable.Column>,

            ...(!props.compact
                ? [
                      <RealTable.Column key="name" renderItemContent={renderCustomerNameAndCompany} className={`${className}__column`}>
                          <Text size="xs" modifiers="sub" className={`${className}__header`}>
                              {translate(1930)} / {translate(108)}
                          </Text>
                      </RealTable.Column>,
                      ...(props.withVehicleInfo
                          ? [
                                <RealTable.Column key="vehicle" renderItemContent={renderVehicleName} className={`${className}__column`}>
                                    <Text size="xs" modifiers="sub" className={`${className}__header`}>
                                        {translate(99)}
                                    </Text>
                                </RealTable.Column>,
                                <RealTable.Column key="plate" renderItemContent={renderLicensePlate} className={`${className}__column`}>
                                    <Text size="xs" modifiers="sub" className={`${className}__header`}>
                                        {translate(21)}
                                    </Text>
                                </RealTable.Column>,
                            ]
                          : []),
                  ]
                : []),

            <RealTable.Column
                key="totalSum"
                renderItemContent={renderTotalSum}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            >
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(62)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    const getRowClassName = (voucher: DMS.Voucher) => {
        if (props.selectedVoucher && props.selectedVoucher.referenceId === voucher.referenceId) {
            return "is-selected"
        }
    }

    return (
        <Selection
            className={className}
            items={props.vouchers}
            columns={getTableColumns()}
            onSelect={props.onVoucherSelect}
            loading={props.loading}
            label={props.label}
            noItemsMessage={props.noItemsMessage}
            rightHorizontalRuleContent={renderDeselectButton()}
            getRowClassName={getRowClassName}
            onLoadNextItems={props.onLoadNextVouchers}
        />
    )
}
