import { ArticleItem, Box, styled } from "@tm/components"
import { ArticleListSortingMode, WholesalerPart } from "@tm/models"
import { useCallback, useMemo } from "react"
import { ArticleSelection } from "../components/ArticleSelection"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { useWholesalerArticleNumbersModuleState } from "./WholesalerArticleNumberModuleState"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleGroupParams } from "../models"
import { WholesalerArticleNumbersItemStateProvider } from "../components/WholesalerArticleNumbers/WholesalerArticleNumbersItemStateProvider"

const ModalArticleListWrapper = styled(ArticleListWrapper)({ height: "100%", paddingLeft: 0 })
const listType = "default"

export function WholesalerArticleNumberList() {
    const noResult = useWholesalerArticleNumbersModuleState((x) => x.params.noResult)

    const attributes = useWholesalerArticleNumbersModuleState((x) => x.params.attributes)
    const toggleAttribute = useWholesalerArticleNumbersModuleState((x) => x.params.toggleAttribute)
    const setAttributes = useWholesalerArticleNumbersModuleState((x) => x.params.setAttributes)
    const sortingProductGroups = useWholesalerArticleNumbersModuleState((x) => x.params.sorting.productGroups)
    const activateSortingForProductGroups = useWholesalerArticleNumbersModuleState((x) => x.params.sorting.setForProductGroups)
    const isFiltered = useWholesalerArticleNumbersModuleState((x) => x.isFiltered)
    const wholesalerNosArticles = useWholesalerArticleNumbersModuleState((x) => x.wholesalerNosArticles)
    const { articleGroups } = useWholesalerArticleNumbersModuleState((x) => x.articles)

    const productGroupTopicIds = useWholesalerArticleNumbersModuleState((x) => x.productGroupTopicIds)

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    return (
        <>
            <Box pr={1} margin={1} overflow="hidden">
                <ModalArticleListWrapper>
                    <ArticleSelection listType={listType} maxArticleNumberToCompareReached showArticleComparision />

                    {noResult && <NoSearchResult searchType="direct" />}

                    {articleGroups.map((group: ArticleGroupParams) => (
                        <ArticleGroup
                            key={group.productGroup.id}
                            group={group}
                            currentSorting={sortingProductGroups[group.productGroup.id]}
                            onChangeSorting={handleChangeSorting}
                            showFilterOptions={showVehicleRecordsFilters}
                            usedAttributeFilters={usedAttributeFilters}
                            onToggleAttributeFilter={toggleAttribute}
                            onResetAttributeFilters={handleResetAttributeFilters}
                            productGroupTopicIds={productGroupTopicIds}
                        />
                    ))}

                    {!!wholesalerNosArticles.length &&
                        !isFiltered &&
                        wholesalerNosArticles.map((article: WholesalerPart) => (
                            <WholesalerArticleNumbersItemStateProvider key={article.wholesalerArticleNumber} article={article}>
                                <ArticleItem variant="WholesalerArticleNumber" />
                            </WholesalerArticleNumbersItemStateProvider>
                        ))}
                </ModalArticleListWrapper>
            </Box>
        </>
    )
}
