import { useLocalization } from "@tm/localization"
import { Divider } from "@tm/components"
import { LinkMenuItem } from "./LinkMenuItem"
import { SubLinkMenuItem } from "./SubLinkMenuItem"

const BASE_ROUTE = "/usersettings"

type Props = {
    onClick?(): void
}

export function MenuItems({ onClick }: Props) {
    const { translateText } = useLocalization()

    return (
        <>
            <LinkMenuItem to={BASE_ROUTE} onClick={onClick}>
                {translateText(141)}
            </LinkMenuItem>
            <SubLinkMenuItem to={`${BASE_ROUTE}/language`} onClick={onClick}>
                {translateText(139)}
            </SubLinkMenuItem>
            <SubLinkMenuItem to={`${BASE_ROUTE}/location`} onClick={onClick}>
                {translateText(28)}
            </SubLinkMenuItem>
            <SubLinkMenuItem to={`${BASE_ROUTE}/prices`} onClick={onClick}>
                {translateText(140)}
            </SubLinkMenuItem>
            <SubLinkMenuItem to={`${BASE_ROUTE}/cost-estimation`} onClick={onClick}>
                {translateText(82)}
            </SubLinkMenuItem>
            <SubLinkMenuItem to={`${BASE_ROUTE}/hourly-rates`} onClick={onClick}>
                {translateText(911)}
            </SubLinkMenuItem>
            <Divider />
        </>
    )
}
