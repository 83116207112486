import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { ERepairTimeDivision, getStyleTheme, useUser } from "@tm/context-distribution"
import { concat } from "@tm/utils"
import { Button, Checkbox, RealTable, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { DMS } from "@tm/models"
import { calculateRawAwByDivision } from "../../../../utils/format"

export function getComponentStyles(): NestedCSSProperties {
    const { margin, font, opacity } = getStyleTheme()

    return {
        $nest: {
            "&__table": {
                borderSpacing: "0 2px" /** @todo margin.xxs */,
                $nest: {
                    ".real-table__head": {
                        fontSize: font.textSize.m,
                    },
                    ".real-table__cell": {
                        padding: `${margin.s} ${margin.m}`,
                        $nest: {
                            "&:first-child": {
                                paddingLeft: margin.m,
                                borderLeftWidth: "5px",
                            },
                            "&:last-child": {
                                paddingRight: margin.m,
                            },
                        },
                    },
                },
            },
            "&__header": {
                textTransform: "uppercase",
            },
            "&__item": {},
            "&__label": {
                textTransform: "uppercase",
            },
            "&__column": {
                wordBreak: "break-word",
                $nest: {
                    "&--align-right": {
                        textAlign: "right",
                    },
                    "&--nowrap": {
                        whiteSpace: "nowrap",
                    },
                },
            },
            "&__cell": {
                position: "relative",
                $nest: {
                    "&:not(:last-child)::after": {
                        content: '""',
                        position: "absolute",
                        top: "-1px",
                        right: "0",
                        width: "2px",
                        height: "15px",
                        opacity: opacity.disabled,
                        backgroundColor: "#979797" /** @todo color? */,
                    },
                },
            },
            "&__actions": {
                display: "inline-flex",
                alignItems: "center",
            },
            "&__checkbox": {
                marginLeft: margin.m,
            },
        },
    }
}

type Props = {
    repairTimes: Array<DMS.RepairTime>
    selectedRepairTimes?: Array<DMS.RepairTime>
    onToggleSelectRepairTime?(repairTime: DMS.RepairTime): void
    onAddRepairTimeToCostEstimation?(repairTime: DMS.RepairTime): void
}

export function RepairTimesTable(props: Props) {
    const className = style(getComponentStyles())
    const { translate, translateText, languageId, currency, number } = useLocalization()
    const user = useUser()

    const userRepairTimeDivision = user?.userSettings?.repairTimeOptions?.division || ERepairTimeDivision.Hours

    const getRepairTimeProviderName = (provider: DMS.Provider) => {
        switch (provider) {
            case DMS.Provider.AwDoc:
                return "AwDoc"
            case DMS.Provider.Haynes:
                return "HaynesPro"
            case DMS.Provider.Autodata:
                return "Autodata"
            case DMS.Provider.TecRMI:
                return "TecRMI"
            case DMS.Provider.Eurotax:
                return "Eurotax"
            case DMS.Provider.DAT:
                return "DAT"
            case DMS.Provider.Custom:
                return translateText(1939)
            default:
                break
        }
    }

    const renderNumber = (repairTime: DMS.RepairTime) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{repairTime.repairTimeId}</Text>
            </RealTable.Cell>
        )
    }

    const renderDescription = (repairTime: DMS.RepairTime) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{repairTime.description}</Text>
            </RealTable.Cell>
        )
    }

    const renderProvider = (repairTime: DMS.RepairTime) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{getRepairTimeProviderName(repairTime.provider)}</Text>
            </RealTable.Cell>
        )
    }

    const renderHourlyRate = (repairTime: DMS.RepairTime) => {
        const { calculation } = repairTime

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{!calculation.isFixedPrice ? currency(calculation.hourlyRate ?? 0, calculation.currencySymbol) : translate(246)}</Text>
            </RealTable.Cell>
        )
    }

    const renderTime = (repairTime: DMS.RepairTime) => {
        const { calculation } = repairTime

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>
                    {!calculation.isFixedPrice
                        ? number(
                              calculateRawAwByDivision(
                                  {
                                      value: calculation.timeValue,
                                      division: calculation.division,
                                  },
                                  userRepairTimeDivision
                              ),
                              2
                          )
                        : "-"}
                </Text>
            </RealTable.Cell>
        )
    }

    const renderCalculatedTime = (repairTime: DMS.RepairTime) => {
        const { calculation } = repairTime

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>
                    {!calculation.isFixedPrice
                        ? number(
                              calculateRawAwByDivision(
                                  {
                                      value: calculation.calculatedTimeValue || 0,
                                      division: calculation.division,
                                  },
                                  userRepairTimeDivision
                              ),
                              2
                          )
                        : "-"}
                </Text>
            </RealTable.Cell>
        )
    }

    const renderTotalSum = (repairTime: DMS.RepairTime) => {
        const { calculation } = repairTime
        const rawWorktimeUnits = calculateRawAwByDivision(
            {
                value: calculation.calculatedTimeValue || 0,
                division: calculation.division,
            },
            userRepairTimeDivision
        )

        let totalSum

        if (calculation.isFixedPrice) {
            totalSum = calculation.priceValue
        } else {
            totalSum = ((calculation.hourlyRate || 0) / userRepairTimeDivision) * rawWorktimeUnits
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{currency(totalSum, calculation.currencySymbol)}</Text>
            </RealTable.Cell>
        )
    }

    const renderActions = (repairTime: DMS.RepairTime) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {props.onAddRepairTimeToCostEstimation && (
                        <Tooltip content={translateText(1940)}>
                            <Button
                                icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
                                onClick={() => props.onAddRepairTimeToCostEstimation?.(repairTime)}
                            />
                        </Tooltip>
                    )}
                    {props.onToggleSelectRepairTime && (
                        <Tooltip content={!(props.selectedRepairTimes ?? []).includes(repairTime) ? translateText(1941) : translateText(1942)}>
                            <div className={`${className}__checkbox`}>
                                <Checkbox
                                    checked={(props.selectedRepairTimes ?? []).includes(repairTime)}
                                    onToggle={() => props.onToggleSelectRepairTime?.(repairTime)}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        const userChangedDivision = userRepairTimeDivision !== ERepairTimeDivision.Hours

        return [
            <RealTable.Column key="number" renderItemContent={renderNumber} className={`${className}__column ${className}__column--nowrap`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(130)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="description" renderItemContent={renderDescription} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(131)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="provider" renderItemContent={renderProvider} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(278)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="hourlyRate"
                renderItemContent={renderHourlyRate}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            >
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(63)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="time"
                renderItemContent={renderTime}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            >
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {userChangedDivision ? translate(1550) : translate(84)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="calculatedTime"
                renderItemContent={renderCalculatedTime}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            >
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {userChangedDivision ? translate(1550) : translate(84)} {translate(85)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="totalSum"
                renderItemContent={renderTotalSum}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            >
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(57)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    const getRowClassName = (repairTime: DMS.RepairTime, rowIndex: number) => {
        return concat(
            " ",
            `${className}__item`,
            rowIndex % 2 === 0 ? "real-table__row--light" : undefined,
            (props.selectedRepairTimes ?? []).includes(repairTime) ? "is-selected" : undefined
        )
    }

    return <RealTable data={props.repairTimes} columns={getTableColumns()} className={`${className}__table`} getRowClassName={getRowClassName} />
}
