import { Box, Loader } from "@tm/components"
import { Suspense, useEffect } from "react"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"
import { WholesalerArticleNumberModuleStateProvider, useWholesalerArticleNumbersModuleState } from "./WholesalerArticleNumberModuleState"
import { WholesalerArticleNumberList } from "./WholesalerArticleNumberList"

function WholesalerArticleNumberComponent() {
    const start = useWholesalerArticleNumbersModuleState((x) => x.start)

    useEffect(start, [start])

    return <WholesalerArticleNumberList />
}

export default function WholesalerArticleNumbers() {
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ArticleListConfigurationProvider>
                <WholesalerArticleNumberModuleStateProvider>
                    <WholesalerArticleNumberComponent />
                </WholesalerArticleNumberModuleStateProvider>
            </ArticleListConfigurationProvider>
        </Suspense>
    )
}
